/**
 * Created by Kais CHAIBI on 16/02/2017.
 */
angular.module("app").factory('Excel',function($window){
  var uri='data:application/vnd.ms-excel;base64,',
    template='<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--> <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" /></head><body><table>{table}</table></body></html>',
    base64=function(s){return $window.btoa(unescape(encodeURIComponent(s)));},
    format=function(s,c){return s.replace(/{(\w+)}/g,function(m,p){return c[p];})};
  return {
    tableToExcel:function(tableSelector,worksheetName){
      var table=$(tableSelector).clone();
       table.find(".notExportable").remove();
        var ctx={worksheet:worksheetName,table:table.html()},
        href=uri+base64(format(template,ctx));
      console.log(href)
      return href;
    }
  };
})


/**
 * Project: fleetWeb
 * Created by  NGI team.
 */

    angular.module("app.services").factory('dataSrv',function ($window,$rootScope,$timeout,$parse) {
    //the service Object
    var service = {};

    service.selectedAssets = [];

    return service;

});
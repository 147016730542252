/**
 * Created by Hamdi on 27/02/2017.
 */
angular.module("app").controller('alertsAdminCtrl',
  [
    '$scope',
    '$state',
    'alertConfigSrv',
    '$rootScope',
    '$translate','ngDialog','Notification',"$window",'enumsSrv','$stateParams',
    function ($scope,
              $state,
              alertConfigSrv,
              $rootScope,
              $translate,ngDialog,Notification ,$window,enumsSrv,$stateParams) {


      $( ".sidebar-right" ).removeClass( "sidebar-open" )

      $scope.editAlertCnfg=function(alerte){
        $scope.editMode=true;
        $scope.addMode=false;

        $rootScope.currentConfigId='';
        $rootScope.currentAlertConfig={};


        $rootScope.currentConfigId=alerte._id;
        $rootScope.currentAlertConfig=alerte;

       // $rootScope.$broadcast("editAdminMode:open",id);

        $state.go("app.temperature.admin.alerts.details",{id:alerte._id});


      }

      $scope.ajouterAlertCnfg=function(){
        $rootScope.currentConfigId=null;

        $scope.editMode=false;
        $scope.addMode=true;

       // $rootScope.$broadcast("addAdminMode:open");

        $state.go("app.temperature.admin.alerts.add");

      }


      enumsSrv.getEnums(function (data) {
        $scope.ENUMS = data.result

        $scope.CHECKERS=[];
        Object.keys($scope.ENUMS.ALERT_FUNCTION_MODEL).forEach(function(key){
          $scope.CHECKERS.push($scope.ENUMS.ALERT_FUNCTION_MODEL[key]);
        })

      }, function(err){
        console.error(err);
      })

     /* $scope.resetisChanging=function(module){
        setTimeout(function() {
          module.isChanging = false;
        })
      }*/

      $rootScope.loadAlertConfigs = function (tableState,ctrl) {
        if ( !$scope.stCtrl && ctrl )
        {
          $scope.stCtrl = ctrl;
        }

        if( !tableState && $scope.stCtrl ) {
          $scope.stCtrl.pipe();
          return;
        }
        var start = 0;     // This is NOT the page number, but the index of item in the list that you want to use to display the table.
        var number = $scope.itemsByPage;
        var query = {
          options:{populate:
            [
              {
                path: '_company_owner',
                select: 'name'
              },
              {
                path: '_assetId',
               // select: 'name'
              },

            ],
            sortBy: {name: 1}
          }
        };
        if (tableState.pagination) {
          var pagination = tableState.pagination;
          // start = pagination.start / $scope.itemsByPage + 1 || 1;     // This is NOT the page number, but the index of item in the list that you want to use to display the table.
          number =  pagination.number ||  $scope.itemsByPage;
          start = pagination.start / number + 1 || 1;


        }
        if (tableState.sort && tableState.sort.predicate) {
          query.options.sortBy = {};

          var predicate = tableState.sort.predicate;
          query.options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);


        }

        var requery = {};

        if (tableState.search && tableState.search.predicateObject) {
          if (tableState.search.predicateObject.name) {
            requery["name"] = {"$regex": tableState.search.predicateObject.name, "$options": "i"};

          }

          if (tableState.search.predicateObject.asset) {
            requery["_assetId"] = tableState.search.predicateObject.asset
          }
          if (tableState.search.predicateObject.company) {
            requery["_company_owner"] =tableState.search.predicateObject.company
          }

        }
        query.query=requery;

        alertConfigSrv.getAlertConfigsList(query,number, start,function (data) {

            if (data && data.success && data.result) {
            // console.log(data.result)

              $scope.AlertCFGLIST = data.result;

              $scope.count = data.total_count
              tableState.pagination.numberOfPages =Math.ceil(data.total_count/ number);
              tableState.pagination.totalItemCount = data.total_count;
            } else {
              console.log(data.error)

            }


          }, function (data) {
            console.log(data)

          })
      }


      $scope.clearFieldFilters=function(){
        $scope.alertName=null;
        $scope.company=null;
        $scope.asset=null;

        var tableState = $scope.stCtrl.tableState()
        tableState.search.predicateObject = {}
        tableState.pagination.start = 0
        $scope.stCtrl.pipe();
      }






      $scope.deleteAlertCnfg = function (alert) {
        ngDialog.openConfirm({
          template: 'app/commons/dialogs/confirm.dlg.html',
          overlay: false,
          showClose: false,
          controller: ['$scope', function ($scope) {
            // controller logic
            if (alert.name != null) {

              $scope.title = "Suppression d'alerte" //$translate.instant("ALERT_DELETE_CONFIRM_TITLE", {name: alert._alertConfig.name})
              $scope.message ="Êtes-vous sure de supprimé l'alerte " +alert.name+" ?"; //$translate.instant("ALERT_DELETE_CONFIRM_MESSAGE", {


            }
            else{

              $scope.title = "Suppression d'alerte" //$translate.instant("ALERT_DELETE_CONFIRM_TITLE", {name: alert._alertConfig.name})
              $scope.message ="Êtes-vous sure de supprimé l'alerte du " +alert._assetId.name+"  ?"; //$translate.instant("ALERT_DELETE_CONFIRM_MESSAGE", {




            }
          }]
        }).then(function () {
          alertConfigSrv.deleteAlertConfigById(alert._id, function (data) {
            //  Notification($translate.instant("ALERT_ALERT_DELETED", {com:alert._company_owner.name, car: alert._assetId.name}));
            $rootScope.loadAlertConfigs()   ;
          }, function (err) {
          })
        }, function (reason) {
        });
      }





      $scope.select2Options1 = {
        allowClear: true,
        id: function (e) {
          return e
        },
        ajax: {
          transport: function (params) {
            params.beforeSend = function (request) {
              request.setRequestHeader("Authorization", 'Bearer ' + $window.sessionStorage.token);
            };
            return $.ajax(params);
          },
          url: $rootScope.config.API_URI + '/asset/query?limit=' + 10 + '&page=' + 1,
          dataType: "json",
          type: "POST",
          quietMillis: 500,

          data: function (term, page) {
            return {
              query: {
                $or: [
                  {name: {"$regex": term, "$options": "i"}},
                  {model: {"$regex": term, "$options": "i"}},
                  {family: {"$regex": term, "$options": "i"}},
                  {type: {"$regex": term, "$options": "i"}},
                  {_dev: {"$regex": term, "$options": "i"}}
                ]
              }
            };

          },
          results: function (data, page) {

            return {results: data.result};
          }

        },
        formatResult: formatResult,
        formatSelection: formatSelection
      };
      function formatResult(item) {
        // console.log(movie)
        return '<i class="fa fa-building-o"></i>   ' + item.name;
      }

      function formatSelection(item) {


        return '<i class="fa fa-building"></i>    ' + item.name;
      }


      $scope.ClientSelect2Options = {
        allowClear: true,
        id: function (e) {
          return e
        },
        ajax: {
          transport: function (params) {
            params.beforeSend = function (request) {
              request.setRequestHeader("Authorization", 'Bearer ' + $window.sessionStorage.token);
            };
            return $.ajax(params);
          },
          url: $rootScope.config.API_URI + '/company/query?limit=' + 10 + '&page=' + 1,
          dataType: "json",
          type: "POST",
          quietMillis: 500,

          data: function (term, page) {
            return {
              query: {
                name: {"$regex": term, "$options": "i"}
              }
            };
          },
          results: function (data, page) {

            return {results: data.result};
          }
        },
        formatResult: formatResult2,
        formatSelection: formatSelection2
      };
      function formatResult2(item) {
        //console.log(movie)
        return '<i class="fa fa-university"></i>   ' + item.name;
      }

      function formatSelection2(item) {

        return '<i class="fa fa-university"></i>    ' + item.name;
      }

    }]);


/**
 * Project: fleetWeb
 * Created by  NGI team.
 */

angular.module("app.services").factory('loginSrv', function ($http, $rootScope, $window, jwtHelper, dataSrv, socket,CONFIG) {

    var service = {};
    var loggedIn = false;

    var connectionNotificationListner = function () {
    socket.connect()


        socket.on('server:connected', function (data) {
           //console.log("USEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEER COOOOOOOOOOOOOOOOOOOOOOONNECTED",JSON.parse($window.sessionStorage.SESSION).user );
            socket.emit('user:connected',{user:JSON.parse($window.sessionStorage.SESSION).user});

        });



    }

    service.login = function (user, success, error) {


        $http.post(CONFIG.API_URI + '/login', user).then(function (data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available


            if (data &&  data.data &&  data.data.success) {

                $window.sessionStorage.token = data.data.result.token;


                $rootScope.SESSION = data.data.result;
                $window.sessionStorage.SESSION= JSON.stringify(data.data.result)
                /*  if( $rootScope.USER.role='superadmin'){
                 dataSrv.setCompanys(data.result.companys)
                 $window.sessionStorage.companys =JSON.stringify(data.result.companys) ;

                 }*/
              connectionNotificationListner()
                success(data);

                //success(data);
            }
            else {

                delete $window.sessionStorage.token;
                delete  $window.sessionStorage.SESSION;
                loggedIn = false;

                error(data);
            }


        },function (data, status, headers, config) {
            error(data)
            delete $window.sessionStorage.token;


            loggedIn = false;
        });
    }


    service.logout = function (success) {
        delete $window.sessionStorage.token;
        delete $window.sessionStorage.companys;
        loggedIn = false;
        socket.closeListner("newMsg",function(){
           // console.log('close notification newMSg');
        });


        if (success) success()
    }


    service.setLoggedInStatus = function (logged) {
        loggedIn = logged;


    }
    service.isLoggedIn = function () {
        return loggedIn;
    }

    /*
     Reset password
     */
    service.forgetPassword = function (userData, success, error) {
        $http.post(CONFIG.API_URI + '/forget', userData).success(function (data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available

            if (data && data.success) {
                success(data);
            }

        }).error(function (data, status, headers, config) {
            error(data)
        });
    }
    return service;

});

/**
 * Project: ngitemperature
 * Created by Haythem Horbit on 31/01/2017.
 */
angular
    .module('app').run(function($rootScope,$transitions,CONFIG,$state,$location,$window, loginSrv,socket){

    $rootScope.config=CONFIG

    moment.locale("fr");
    Highcharts.setOptions(
    {
      lang: {
        months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin',
          'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
          weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi',
          'Jeudi', 'Vendredi', 'Samedi'],
          shortMonths: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil',
          'Aout', 'Sept', 'Oct', 'Nov', 'Déc'],
          decimalPoint: ',',
          downloadPNG: 'Télécharger en image PNG',
          downloadJPEG: 'Télécharger en image JPEG',
          downloadPDF: 'Télécharger en document PDF',
          downloadSVG: 'Télécharger en document Vectoriel',
          exportButtonTitle: 'Export du graphique',
          loading: 'Chargement en cours...',
          printButtonTitle: 'Imprimer le graphique',
          resetZoom: 'Réinitialiser le zoom',
          resetZoomTitle: 'Réinitialiser le zoom au niveau 1:1',
          thousandsSep: ' ',
          decimalPoint: ','
      } ,
        global: {
          useUTC: false

        }

    });

    if ($window.sessionStorage.token) {
        $rootScope.SESSION =JSON.parse($window.sessionStorage.SESSION);

      socket.connect();


    }else{
      $location.path('/login')
    }



    }).config(['$qProvider', function ($qProvider) {
        $qProvider.errorOnUnhandledRejections(false);
    }]).config([
        'ngDialogProvider',
        function(ngDialogProvider) {
            ngDialogProvider.setDefaults({
                className: 'ngdialog-theme-default',
                ariaAuto: false,
                ariaRole: false
            });
        }
    ])

/**
 * Created by Mohamed Saad on 27/02/2017.
 */

angular.module("app").controller('usersCtrl', ['$scope', '$state', 'userSrv','countrySrv','ngDialog','Notification',"$stateParams","$translate","$rootScope","$window",
  function ($scope, $state, userSrv,countrySrv,ngDialog,Notification,$stateParams,$translate,$rootScope,$window) {
    $( ".sidebar-right" ).removeClass( "sidebar-open" )
    $scope._ADMIN_USER = {};
    $scope._ADMIN_USER.countryList = countrySrv.getCountryList();
    $scope._ADMIN_USER.currentPage = 1;
    $scope._ADMIN_USER.limit = 5;
    $scope.itemsByPage=5;

    $scope._ADMIN_USER.UserSearch = "";
    /*clear search field of user*/



    $scope.clearFieldFilters=function(){
      $scope.company=null;
      $scope._ADMIN_USER.countryList=null;
      $scope._ADMIN_USER.UserSearch = "";
      var tableState = $scope.stCtrl.tableState();
      tableState.search.predicateObject = {};
      tableState.pagination.start = 0;
      $scope.stCtrl.pipe();
      $scope.loadUsers();
    };


    /*load user list*/
    $rootScope.loadUsers = function (tableState,ctrl) {

      if (!$scope.stCtrl && ctrl) {
        $scope.stCtrl = ctrl;
      }
      if (!tableState && $scope.stCtrl) {
        $scope.stCtrl.pipe();
        return;
      }
      var start = 0;     // This is NOT the page number, but the index of item in the list that you want to use to display the table.
      var number = $scope.itemsByPage;
      var query = {
        options: {
          populate: [
            {
              path: '_company_owner',
              select: 'name'
            }, {
              path: '_ctry',
              select: 'name'
            }
          ]
        }
      };
      if (tableState.pagination) {
        var pagination = tableState.pagination;
        number = pagination.number || $scope.itemsByPage;
        start = pagination.start / number + 1 || 1;
      }
      if (tableState.sort && tableState.sort.predicate) {
        query.options.sortBy = {};
        var predicate = tableState.sort.predicate;
        query.options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
      }

      var requery = {};
      if($scope.user && $scope.user.roleFilter && $scope.user.roleFilter.length>0){
        requery["_role"] = {'$regex': $scope.user.roleFilter, $options: '-i'}
      }
      if (tableState.search && tableState.search.predicateObject) {
        if (tableState.search.predicateObject.last_name) {
          requery["last_name"] = {"$regex": tableState.search.predicateObject.last_name, "$options": "i"};
        }
        if (tableState.search.predicateObject.first_name) {
          requery["first_name"] = {'$regex': tableState.search.predicateObject.first_name, $options: '-i'}
        }
        if (tableState.search.predicateObject.login) {
          requery["login"] = {'$regex': tableState.search.predicateObject.login, $options: '-i'}
        }
        if (tableState.search.predicateObject._role) {
          requery["_role"] = {'$regex': tableState.search.predicateObject._role, $options: '-i'}
        }
        if (tableState.search.predicateObject._company_owner && tableState.search.predicateObject._company_owner._id ) {
          requery["_company_owner"] = tableState.search.predicateObject._company_owner._id;
        }
        if (tableState.search.predicateObject._ctry) {
          requery["_ctry"] = tableState.search.predicateObject._ctry;
        }
        query.query=requery;
      }
      query.query=requery;

      userSrv.getUsersList(query,number,start,function (data) {
        if (data && data.success) {
          $scope._ADMIN_USER.userList = data.result;
          $scope._ADMIN_USER.count = data.total_count;
          tableState.pagination.numberOfPages = Math.ceil(data.total_count / number);
          tableState.pagination.totalItemCount = data.total_count;
        } else {
          console.error(data.error)
        }
      }, function (data) {
        console.error(data)
      });
    };

    $scope.ClientSelect2Options = {
      allowClear: true,
      id: function (e) {
        return e
      },
      ajax: {
        transport: function (params) {
          params.beforeSend = function (request) {
            request.setRequestHeader("Authorization", 'Bearer ' + $window.sessionStorage.token);
          };
          return $.ajax(params);
        },
        url: $rootScope.config.API_URI + '/company/query?limit=' + 10 + '&page=' + 1,
        dataType: "json",
        type: "POST",
        quietMillis: 500,

        data: function (term, page) {
          return {
            query: {
              name: {"$regex": term, "$options": "i"}
            }
          };
        },
        results: function (data, page) {

          return {results: data.result};
        }
      },
      formatResult: formatResult1,
      formatSelection: formatSelection1
    };
    function formatResult1(item) {
      return '<i class="fa fa-building-o"></i>   ' + item.name;
    }

    function formatSelection1(item) {

      return '<i class="fa fa-building"></i>    ' + item.name;
    }

    $scope.selectPage = function () {
      $scope.loadUsers()
    };

    $scope.userDetails=function(user){
      $state.go("app.temperature.admin.users.details",{id:user._id});
    };

    $scope.addUser=function(){
      $state.go("app.temperature.admin.users.add");
    };


    /*delete user*/
    $scope.deleteUser=function(user){
      ngDialog.openConfirm({ template: 'app/commons/dialogs/confirm.dlg.html',overlay:false ,showClose:false,controller: ['$scope', function($scope) {
        // controller logic
        $scope.title=$translate.instant("USER_DELETE_CONFIRM_TITLE");
        $scope.message=$translate.instant("USER_DELETE_CONFIRM_MESSAGE", {firstName:user.first_name,lastName:user.last_name});
      }] }).then(function (value) {
        userSrv.deleteUserById(user._id,function(data){
          Notification($translate.instant("USER_DELETED", {firstName:user.first_name,lastName:user.last_name}));
          $rootScope.loadUsers();
        },function(err){
        })
      }, function (reason) {
      });
    };


  }])

/**
 * Created by Kais CHAIBI on 02/02/2017.
 */
/**
 * Created by Kais CHAIBI on 02/02/2017.
 */
angular.module("app").controller('assetListController', function ($scope, $interval, $rootScope,CONFIG,assetSrv,$window,fleetSrv,socket, $state,$stateParams,companySrv) {


  $scope.ClientSelect2Options = function(allowClear) {
    return {
      allowClear: allowClear,
      id: function (e) {
        return e
      },
      ajax: {
        transport: function (params) {
          params.beforeSend = function (request) {
            request.setRequestHeader("Authorization", 'Bearer ' + $window.sessionStorage.token);
          };
          return $.ajax(params);
        },
        url: $rootScope.config.API_URI + '/company/query?limit=' + 10 + '&page=' + 1,
        dataType: "json",
        type: "POST",
        quietMillis: 500,

        data: function (term, page) {
          return {
            query: {
              name: {"$regex": term, "$options": "i"}
            }
          };
        },
        results: function (data, page) {

          return {results: data.result};
        }
      },
      initSelection: function (element, callback) {
        var id = $(element).select2('val');
       // console.log(id);
        if (id && id.length > 0) {
          return companySrv.getCompanyById(id, function (res) {

            if (res && res.success) {
              callback(res.result);
            } else {
              callback(null);
            }
          }, function (err) {
            callback(null);
          })
        }
      },
      formatResult: function (item) {
        return '<i class="fa fa-building-o"></i>   ' + item.name;
      },
      formatSelection: function (item) {


        return '<i class="fa fa-building"></i>    ' + item.name;
      }

    }
  }
  $scope.fleetSelect2Options =function(allowClear,multiple) {
    return {
      allowClear: allowClear,
      multiple:multiple,
      id: function (e) {
        return e
      },
      ajax: {
        transport: function (params) {
          params.beforeSend = function (request) {
            request.setRequestHeader("Authorization", 'Bearer ' + $window.sessionStorage.token);

          };
          return $.ajax(params);
        },
        url: $rootScope.config.API_URI + '/fleet/query?limit=' + 10 + '&page=' + 1,
        dataType: "json",
        type: "POST",
        quietMillis: 500,

        data: function (term, page) {
          return {
            query: {
              _company_owner:$scope.appData.selectedCompany,
              name: {"$regex": term, "$options": "i"}


            }
          };
        },
        results: function (data, page) {

          return {results: data.result};
        }
      },
      initSelection: function (element, callback) {
        var id = $(element).select2('val');
      //  console.log(id);
        if (id && id.length > 0) {
          return fleetSrv.getFleetById(id, function (res) {

            if (res && res.success) {
              callback(res.result);
            } else {
              callback(null);
            }
          }, function (err) {
            callback(null);
          })
        }
      },
      formatResult: function (item) {
        return '<i class="fa fa-building"></i>   ' + item.name
      },
      formatSelection: function (item) {


        return '<i class="fa fa-building"></i>    ' + item.name
      }
    };
  }



  $scope.loadAssets=function (id){
    if(id){
      var query={
        query: {
          _id:id
        },
        options:{populate:["_assets"]}


      }


      fleetSrv.getFleetsList(query,100,0,function(res){

        $scope.appData.assets=res.result;


        if(res && res.result && res.result.length>0 ) {
         var assets=res.result[0]._assets

          $scope.appData.assets =assets;
          $rootScope.$emit('assets:updated', res.result[0]._assets);
          $scope.saveAppDataToLocalStorage($scope.appData);

        }
      },function(err){
        console.error(err)
      })
    }/*else{

      assetSrv.getAssetsList({},1000,0,function(res){

        //console.log("ASSEEEEEEEEEEEETS", res )
        $scope.appData.assets=res.result;
      },function(err){
        console.error(res)
      })
    }*/
  }


   if($rootScope. SESSION && $rootScope.SESSION.user && $rootScope.SESSION.user._role && $rootScope.SESSION.user._role._id){
     var role =$rootScope.SESSION.user._role._id;
      if(role !='SUPERADMIN' && role !='RESELLER'){
       $scope.loadAssets()
     }
   }
  //socket.connect();
  $rootScope.$on('newMsg' ,function(event,data){

    if($scope.appData  && $scope.appData.assets && $scope.appData.assets.length>0){


    for(var i=0;i<$scope.appData.assets.length;i++){

      if($scope.appData.assets[i]._id==data._id){
        //console.log("NOTIFICATION", data.rt);

        $scope.appData.assets[i].rt=data.rt;
        try{
          $scope.$apply()

        }catch(e){
          console.error(e);
        }




      }
    }
    }})


  $scope.openAlertCFG=function(asset, state){


    $rootScope.$broadcast("alertConfigMenu:open",asset)
  //  console.log($stateParams)


    $( ".sidebar-right" ).addClass( "sidebar-open" );
  }

  //$scope.loadAssets();


  if(!$scope.appData.assets  ||  $scope.appData.assets.length<=0){
    $scope.loadAssets($scope.appData.selectedFleet)
  }

  $scope.CompanyChangeSelection=function(company){
    $scope.appData.selectedFleet=null;
    $scope.appData.assets=[];
    $rootScope.$emit('assets:updated', []);
    $scope.saveAppDataToLocalStorage($scope.appData);
  }
  $scope.saveAppDataToLocalStorage=function(appData){
    $window.sessionStorage.appData=JSON.stringify(appData);
  }
});

/**
 * Project: iaxeed
 * Created by  CHAIBI Kais  on 19/01/2017.
 */

angular.module("app.services").factory('countryApiSrv',['$http','$rootScope', function ($http,$rootScope) {

    var service={};

    service.getCountryList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;

        $http.post($rootScope.config.API_URI+'/country/query?limit='+l+'&page='+p,searchQuery).success(function(data, status, headers, config) {
            if (data && data.success){
                success(data);
            }
            else{
                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }
    service.getCountryAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/country/agg?limit='+l+'&page='+p,agg).success(function(data, status, headers, config) {
            if (data && data.success){
                success(data);
            }
            else{
                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }

    return service;

}]);

/**
 * Created by Hamdi on 29/12/2016.
 */

angular.module("app").controller('mapDlgCtrl',
    [
        '$scope',
        '$state',
        'dataSrv',
        '$stateParams',
        '$rootScope',
        '$timeout',
        'leafletData',
        'leafletBoundsHelpers',
        '$q',
        '$translate',
        'marker',
        function ($scope,
                  $state,
                  dataSrv,
                  $stateParams,
                  $rootScope,
                  $timeout,
                  leafletData,
                  leafletBoundsHelpers,
                  $q,
                  $translate,marker) {
            //console.log("marker :",marker)

angular.extend($scope, {
    events: {
        map: {
            enable: ['click', 'drag', 'blur', 'touchstart'],
            logic: 'emit'
        }
    },
    defaults: {
        layerControl: true,
        map: {

        }

    },

    center: {
        lat: 35.043602,
        lng: 10.206953,
        zoom: 5

    },
    markers: {

    },

    paths: {},
    controls: {},
    layers: {
        baselayers: {
           /* xyz: {
                name: 'OpenStreetMap (XYZ)',
                url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                type: 'xyz'
            },*/
            NGI: {
                name: 'NGI',
                url: $rootScope.config.LBS_WMS,
                type: 'wms',
                options: {
                    attribution: '@ Powered by NGI Fleet'
                }
            }

        }
    },

    tiles: {
        url: "http://sig.weenee-fleet.com/bgis/wms",
        options: {
            attribution: '@ Powered by NGI'
        }
    }

});
                $scope.infos=false;
                $scope.infosPOLY = false;
                $scope.infos3 = false;

            if(marker != null) {
                $scope.title = marker.title;
                var markers = {
                    lng: marker.coordinates[0],
                    lat: marker.coordinates[1],
                    message: marker.message,
                    focus: true
                }

                $scope.center = {
                    lng: marker.coordinates[0],
                    lat: marker.coordinates[1],
                    zoom: 17
                };
                $scope.markers.position = markers;


            var checkers=[];

            if(marker.alertCondition){

                for(var i=0;i< marker.alertCondition.length;i++){
                    checkers.push( marker.alertCondition[i].type)

                }
                marker.checkers=checkers;
                   }

               if (marker.checkers && marker.alertCondition) {

                   $scope.alertCondition=marker.alertCondition;

                  for(var i=0;i<marker.checkers.length;i++) {

                     if(marker.alertCondition[i].data.coordinates!= null) {
                         if (marker.checkers[i] == 'OUT_POLYGON_CHECK' || marker.checkers[i] == 'IN_POLYGON_CHECK') {
                             var polygonPoints = marker.alertCondition[i].data.coordinates.map(function (val) {
                                 return {
                                     lng: val[0],
                                     lat: val[1]
                                 }
                             });

                             $scope.paths["alertpolygon"] = {

                                 type: "polygon",
                                 latlngs: polygonPoints,
                                 color: '#2880ca',
                                 weight: 2

                             };
                             //polygon.bindPopup($scope.zoneName).openPopup();

                             var boundsCoordinates = angular.copy(polygonPoints);
                             boundsCoordinates.push({
                                 lng: marker.coordinates[0],
                                 lat: marker.coordinates[1]
                             });
                             var bounds = new L.LatLngBounds(boundsCoordinates);
                             $scope.bounds = bounds;

                             leafletData.getMap('mapAlert').then(function (map) {
                                 map.fitBounds(bounds);
                                 $timeout(function(){
                                     map.invalidateSize();
                                 })
                             });

                             $scope.infosPOLY = true;

                         }
                     }
                         if (marker.checkers[i] == 'LESS_CHECK' || marker.checkers[i] == 'STRICT_LESS_CHECK' ||
                            marker.checkers[i] == 'GREATER_CHECK' || marker.checkers[i] == 'STRICT_GREATER_CHECK') {
                             var type=marker.alertCondition[i].type;
                             console.log("2 type :",type)
                         }
                        if( marker.checkers[i] == 'IN_RANGE_CHECK' || marker.checkers[i] == 'OUT_RANGE_CHECK'){
                             type=marker.alertCondition[i].type;
                            console.log("3 type :",type)
                          }
                    }

                }

                if (marker.startDate && marker.assetName) {
                    $scope.infos = true;
                    $scope.st = marker.startDate;
                    $scope.asset = marker.assetName;
                    $scope.message = marker.message;
                    $scope.status = marker.status;
                    if (marker.endDate) {
                        $scope.end = marker.endDate;
                    }




                }


}


        }]);



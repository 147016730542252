/**
 * Created by Hamdi on 29/12/2016.
 */

angular.module("app").controller('alertDetails',
    [
        '$scope',
        '$state',
        'dataSrv',
        '$stateParams',
        '$rootScope',
        '$timeout',
      "alertSrv",
        '$q',
        '$translate',

        function ($scope,
                  $state,
                  dataSrv,
                  $stateParams,
                  $rootScope,
                  $timeout,
                  alertSrv,
                  $q,
                  $translate) {

          $scope.moment=moment
          $scope.loadAlertDetails=function(){
            var query={query:{_id:$stateParams.id},options: {
              populate: ['_company_owner', '_assetId', '_alertConfig']

            }}
            alertSrv.getAlertsList(query,1000 , 0 , function(result){
             // console.log(result)
              $scope.alert=result.result[0];
              //  console.log(result);

            }, function(err){
              if(err && err.status==-1){
                Notification.error("Problème de connexion Internet");
              }
              console.error(err)
            })
          }

          $scope.loadAlertDetails();
          if( !$( ".sidebar-right" ).hasClass( "sidebar-open" ))
            $( ".sidebar-right" ).addClass( "sidebar-open" )
          $scope.cancel=function(){
            if($state.current.name=="app.temperature.alerts.details"){
              $( ".sidebar-right" ).removeClass( "sidebar-open" )

              setTimeout(function(){
                $state.go("app.temperature.alerts",{id:$stateParams.id});
              },100)


            }else  {
              $( ".sidebar-right" ).removeClass( "sidebar-open" )
              setTimeout(function(){
                $state.go("app.temperature.assetGroup",{id:$stateParams.id});
              },100)
            }
          }

        }]);



/**
 * Project: fleetWeb
 * Created by  NGI team.
 */

angular.module("app.services").factory('alertConfigSrv',['$http','$rootScope','dataSrv','$window', function ($http,$rootScope,dataSrv,$window) {

    var service={};

    service.getAlertConfigsList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/alertconfig/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

          if (response && response.data && response.data.success){

                success(response.data);
            }else if(response && response.data && data.success==false){
            error(response.data)
          }
            else{

                error(response);
            }



        },function(err) {
            error(err)

        });
    }
    service.getAlertConfigsAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/alertconfig/agg?limit='+l+'&page='+p,agg).success(function(data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available
            if (data && data.success){

                success(data);
            }
            else{

                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }


    service.getAlertConfigById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/alertconfig/'+id).success(function(data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available

            if (data && data.success){

                success(data);
            }
            else{

                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }
    service.deleteAlertConfigById=function(id,success,error){
        $http.delete($rootScope.config.API_URI+'/alertconfig/'+id).then(function(response, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }else if(response && response.data && response.data.success==false){
              error(response.data);
            }
            else{

                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }

    service.updateAlertConfigById=function(id,alertconfig,success,error){
        $http.put($rootScope.config.API_URI+'/alertconfig/'+id,alertconfig).then(function(response ) {
            // this callback will be called asynchronously
            // when the response is available


            if (response && response.data && response.data.success){

                success(response.data);
            }else if (response && response.data && response.data.success==false){


                error(response.data);

            }
            else{

                error(response);
            }



        },function(err) {
            error(err)

        });
    }
    service.add=function(alertconfig,success,error){
        $http.post($rootScope.config.API_URI+'/alertconfig/',alertconfig).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){
              console.log('response ',response  )
                success(response.data);
            }else if(response && response.data && response.data.success==false){
            error(response.data);
          }
            else{
            error(response);

            }



        },function(data, status, headers, config) {
            error(data)

        });
    }
    return service;

}]);

/**
 * Created by Hamdi on 28/02/2017.
 */

angular.module("app").controller('alertsAdminDetailsCtrl',
  [
    '$scope',
    '$state',
    'alertConfigSrv',
    '$rootScope',
    '$translate','ngDialog','Notification',"$window",'enumsSrv','$stateParams','$filter','assetSrv','$timeout',
    function ($scope,
              $state,
              alertConfigSrv,
              $rootScope,
              $translate,ngDialog,Notification ,$window,enumsSrv,$stateParams,$filter,assetSrv,$timeout) {



         $scope.updateSensorCheckbox = function(config){


           if (config.data && config.data.type=='sensor'){
               config.data.type =undefined;
               delete config.data.sensorId;


             }

              else {
               if (!config.data)
                 config.data={};
               else
               delete config.data.field;

           //  if($scope.valueType
           //  config.data.value=[];
             config.data.type = 'sensor'

             }
         }



      var defaultfields=[
        {
          name: $translate.instant("io.dls_temp1"),
          src: 'io.dls_temp1'  ,
          value: 10,
          value_type: "number"
        },
        {
          name: $translate.instant("io.dls_temp2"),
          src: 'io.dls_temp2'  ,
          value: 10,
          value_type: "number"
        },
        {
          name: $translate.instant("io.dls_temp3"),
          src: 'io.dls_temp3'  ,
          value: 10,
          value_type: "number"
        }

      ]

      $scope.addAlertCfg=function() {
        $scope.alertConfg = {

        //  _assetId:$scope.asset._id,
        //  _company_owner:$scope.asset._company_owner,

          enabled: true, config: [], actions: [{
            "type": "SOCKET_IO",
            "update": true,
            enabled:true,
            "add": true

          },
            {
              "type": "SMS",
              "add": true,
              enabled:false,
              "update": true,
              "data": {
                "phone": ""
              }
            },
            {
              "type": "EMAIL",
              "add": true,
              "update": true,
              enabled:false,
              "data": {
                "email": ""
              }


            },
            {
              "type": "SIMPLE_HTTP_POST",
              "add": true,
              "update": true,
              enabled:false,
              "data": {
                "url": ""
              }


            }

          ]
        };
      //  $scope.editMode=true;
      }

      enumsSrv.getEnums(function (data) {
        $scope.ENUMS = data

        $scope.CHECKERS=[];
        Object.keys($scope.ENUMS.ALERT_FUNCTION_MODEL).forEach(function(key){
          $scope.CHECKERS.push($scope.ENUMS.ALERT_FUNCTION_MODEL[key]);
        })
        log("CHEEEEEEEEEEEECkers ", $scope.CHECKERS);

      }, function(err){
        if(err && err.status==-1){
          Notification.error("Problème de connexion Internet");
        }
        console.error(err);
      })


      $scope.updateChecker=function(config){

if(config.data.field)
          var filtredField = $filter('filter')($scope.CHECKERS, $scope.filterCheckerByFiledType(config.data.field), true);
else if(!config.data.field)
         var filtredField = $filter('filter')($scope.CHECKERS, $scope.filterCheckerByFiledType(config.data.type), true);


//console.log('filtered fielsd',filtredField)
          $timeout(function () {
            //console.log("filtredField  ", config.field , filtredField)
            if (filtredField && filtredField.length > 0) {
              if (filtredField.indexOf(config.checker) <= 0) {
                config.checker = filtredField[0];

                if(config.data.field)
                config.data = {field: config.data.field};

              } else {

              }
              // console.log("upfate chaecker after filter  ", config ,filtredField[0] )
            }


            $scope.$apply();
          })
      }

      $scope.addCondition=function() {
        $scope.alertConfg.config.push({})
      };

      $scope.prepareFields = function (item) {
        if(!item)
          return ;

       // console.log('asseeeeet', item);
        $scope.fields = []

        if (item.rt) {


          Object.keys(item.rt).forEach(function (key) {
            if (key == 'loc') {
              $scope.fields.push({
                name:$translate.instant(key),
                src: '' + key,
                value: {lat: item.rt.loc.coordinates[1], lng: item.rt.loc.coordinates[0], zoom: 19},
                value_type: "position",
                asset: item._id,
                asset_name: item.name,
                gps_dt: item.rt.gps_dt,
                srv_dt: item.rt.srv_dt
              })
            } else if (item.rt[key].coordinates && item.rt[key].coordinates.length > 0) {
              $scope.fields.push({
                name: $translate.instant("Location"),
                src: '' + key,
                value: {lat: item.rt.loc.coordinates[1], lng: item.rt.loc.coordinates[0], zoom: 19},
                value_type: "position"
              })
            }

            else if (key == 'odo') {
              $scope.fields.push({
                name: $translate.instant("odo"),
                src: '' + key,
                value: item.rt.odo,
                value_type: 'number',
                asset: item._id,
                asset_name: item.name,
                gps_dt: item.rt.gps_dt,
                srv_dt: item.rt.srv_dt
              })
            }

            else if (key == 'io') {
              Object.keys(item.rt.io).forEach(function (io_key) {
                if (io_key != 'odo') {
                  var value = item.rt.io[io_key]
                  var value_type = typeof(value)
                  $scope.fields.push({
                    name: $translate.instant('io.' + io_key) ,
                    src: 'io.' + io_key,
                    value: value,
                    value_type: value_type,
                    asset: item._id,
                    asset_name: item.name,
                    gps_dt: item.rt.gps_dt,
                    srv_dt: item.rt.srv_dt
                  })
                }

              })
            }
            else {

              var value = item.rt[key]
              var value_type = typeof(value)
              //  console.log(value_type)
              if (typeof (value.getMonth) === 'function') {
                value_type = "date"
              }


              $scope.fields.push({
                name: $translate.instant(key),
                src: '' + key,
                value: value,
                value_type: value_type,
                asset: item._id,
                asset_name: item.name,
                gps_dt: item.rt.gps_dt,
                srv_dt: item.rt.srv_dt
              })

            }
          })


          // console.dir($scope.widget)
        }

      }

      $scope. deleteConditionCfg=function(index){
        if(index!=undefined && index>=0 && index<$scope.alertConfg.config.length){

          ngDialog.openConfirm({ template: 'app/commons/dialogs/confirm.dlg.html',overlay:false ,showClose:false,controller: ['$scope', function($scope) {
            // controller logic
            $scope.title="Suppression de condition d'alerte";
            $scope.message="Êtes vous sure de vouloir supprimer la condition d'alerte";
          }] }).then(function (value) {

            $scope.alertConfg.config.splice(index, 1);
          }, function (reason) {

          });

        }
      }

      $scope.filterCheckerByFiledType = function (field) {

//console.log('field',field)
        return function (item) {
          if(field=='sensor')
            return (item.indexOf("GREATER") >= 0 || item.indexOf("LESS") >= 0 || item.indexOf("RANGE") >= 0)

         else {

            $scope.valueType = $filter('filter')($scope.fields, {src: field}, true)[0].value_type;

          if ($scope.valueType == 'number') {

            return (item.indexOf("GREATER") >= 0 || item.indexOf("LESS") >= 0 || item.indexOf("RANGE") >= 0)
          } else if ($scope.valueType == 'position') {

            //    console.log("field type position",field,valueType,item.indexOf("POLYGON") >= 0,item)
            return (item.indexOf("POLYGON") >= 0)

          } else {
            console.log("UNKNOWN TYPE FOR ALERT CHECKER ", $scope.valueType);
            return true;
          }
        }
      }

   }


      $scope.loadAlertCnfgDetails=function() {
       var query = {
          query: {_id:$stateParams.id}, options: {
          //  populate: ['_company_owner', '_assetId']
             populate: [ '_assetId']

          }
        };

        alertConfigSrv.getAlertConfigsList(query, 1, 0, function (data) {
          if(data && data.result && data.result.length>0) {
            $scope.editAlertConfig(data.result[0]);
            $scope.initFields(data.result[0]._assetId);


          }

        }, function (err) {
          if (err && err.status == -1) {
            Notification.error("Problème de connexion Internet");
          }
          console.error(err)
        });

      }




      $scope.editAlertConfig=function(alertCfg){


       if(alertCfg && alertCfg._id){


          if(alertCfg.actions && alertCfg.actions.length>=0 ){
            var socketIoExist=false;
            var emailExist=false;
            var smsExist=false;
            var postHttpExist=false;
            var actions=alertCfg.actions.map(function(val){
              if(val.type=='SMS'){
                smsExist=true;
                val.enabled=true;
                return val
              }else if(val.type=='EMAIL'){
                emailExist=true;
                val.enabled=true;
                return val
              }else if(val.type=='SOCKET_IO'){
                socketIoExist=true;
                val.enabled=true;
                return val
              }
              else if(val.type=='SIMPLE_HTTP_POST'){
                postHttpExist=true;
                val.enabled=true;
                return val
              }
            })
            //   console.log("Actionssssssss", socketIoExist,emailExist,smsExist);
            if(!socketIoExist){
              actions.push({
                "type": "SOCKET_IO",
                "update": true,
                enabled:true,
                "add": true

              })
            }
            if(!emailExist) {
              actions.push(
                {
                  "type": "EMAIL",
                  "add": true,
                  "update": true,
                  enabled: false,
                  "data": {
                    "email": ""
                  }
                })
            }
            if(!smsExist){

              actions.push(   {
                "type": "SMS",
                "add": true,
                enabled:false,
                "update": true,
                "data": {
                  "phone": ""
                }
              })

            }
            if(!postHttpExist){

              actions.push(   {
                "type": "SIMPLE_HTTP_POST",
                "add": true,
                enabled:false,
                "update": false,
                "data": {
                  "url": ""
                }
              })

            }

          }else{
            actions=[{
              "type": "SOCKET_IO",
              "update": true,
              enabled:true,
              "add": true,
              //"_id": ObjectId("586bb792621d8214a0237989")
            },
              {
                "type": "SMS",
                "add": true,
                enabled:false,
                "update": true,
                "data": {
                  "phone": ""
                }
              },
              {
                "type": "EMAIL",
                "add": true,
                "update": true,
                enabled:false,
                "data": {
                  "email": ""
                }


              },
              {
                "type": "SIMPLE_HTTP_POST",
                "add": true,
                "update": false,
                enabled:false,
                "data": {
                  "url": ""
                }


              }

            ]
          }


          alertCfg.actions=actions;
          $scope.alertConfg=alertCfg;

        }
      }

/////  update /////////
      $scope.UpdateAlert=function(){

        $scope.alertConfg.actions=$filter('filter')($scope.alertConfg.actions,{enabled:true},true);


        alertConfigSrv.updateAlertConfigById($rootScope.currentConfigId,$scope.alertConfg,function(data){
          Notification('Alerte modifié');

          $scope.cancel();

          $rootScope.loadAlertConfigs();  //       setTimeout(function (){$state.reload();});


        },function(error){
          if (error){
            console.log(error);
            $scope.cancel();


          }
        })
      }
////////add //////////
     $scope.addAlert=function() {

       $scope.alertConfg.actions=$filter('filter')($scope.alertConfg.actions,{enabled:true},true);

       alertConfigSrv.add($scope.alertConfg, function (data) {
         if (data.success) {
           Notification('Alerte ajouté');

           $scope.cancel();
           $rootScope.loadAlertConfigs({})        }
       }, function (err) {

       })

     }


      $scope.cancel=function(){


  if ($state.current.name=="app.temperature.admin.alerts.details" || $state.current.name=="app.temperature.admin.alerts.add") {
          $( ".sidebar-right" ).removeClass( "sidebar-open" )
          setTimeout(function(){
            $state.go("app.temperature.admin.alerts");
          },100)

        }else  {
          $( ".sidebar-right" ).removeClass( "sidebar-open" )
          setTimeout(function(){
            $state.go("app.temperature.admin.alerts");
          },100)
        }
      }

      $scope.initFields=function(item) {
        if(item)
        $scope.asset=item;

        if ($scope.asset) {
          $scope.prepareFields($scope.asset);
          //console.log('init fields');
          if (!$scope.asset.rt || !$scope.asset.rt.io || !$scope.asset.rt.io.dls_temp1) {

            $scope.fields.push(defaultfields[0]);
          }
          if (!$scope.asset.rt || !$scope.asset.rt.io || !$scope.asset.rt.io.dls_temp2) {

            $scope.fields.push(defaultfields[1]);
          }
          if (!$scope.asset.rt || !$scope.asset.rt.io || !$scope.asset.rt.io.dls_temp3) {

            $scope.fields.push(defaultfields[2]);
          }


          $scope.fields = $filter('filter')($scope.fields, {value_type: 'number'}, true);
          ///  console.log($scope.fields);
        }
      }



      $scope.select2Options = {
        allowClear : true,
        id: function (e) {
          return e
        },
        ajax : {
          transport: function(params){
            params.beforeSend = function(request){
              request.setRequestHeader("Authorization", 'Bearer ' + $window.sessionStorage.token);
            };
            return $.ajax(params);
          },
          url: $rootScope.config.API_URI+'/asset/query?limit='+10+'&page='+1,
          dataType: "json",
          type: "POST",
          quietMillis: 500,

          data: function(term, page) {
            return {
              query: {
                $or:
                  [
                    {name: {"$regex": term, "$options": "i"}},
                    {model: {"$regex": term, "$options": "i"}},
                    {family: {"$regex": term, "$options": "i"}},
                    {type: {"$regex": term, "$options": "i"}},
                    {_dev: {"$regex": term, "$options": "i"}}

                  ]
              }

            };


          },
          results: function(data, page) {

            return {results: data.result};
          }

        },
        initSelection: function (element, callback) {


          var id = $(element).select2('val');
         // console.log('id',id);

          if (id && id.length > 0) {
            return assetSrv.getAssetById(id, function (res) {

              if (res && res.success) {
                callback(res.result);
              } else {
                callback(null);
              }
            }, function (err) {
              callback(null);
            })
          }else{callback(null)}
        },
        formatResult: formatResult,
        formatSelection: formatSelection
      };
      function formatResult(item) {
        return '<i class="fa fa-building"></i>   '+item.name;
      }
      function formatSelection(item) {

        $scope.initFields(item);

         $scope.alertConfg._assetId=item._id;
         $scope.alertConfg._company_owner=item._company_owner;

        return '<i class="fa fa-building-o"></i>    '+item.name;
      }
     // console.log('param id', $stateParams.id)
      if ($stateParams.id){
          console.log('edit'  )
          $scope.editMode = true  ;
          $scope.addMode=false;

        if($rootScope.currentAlertConfig && $rootScope.currentAlertConfig._assetId) {
         // console.log($rootScope.currentAlertConfig)
          $scope.editAlertConfig($rootScope.currentAlertConfig);
          $scope.initFields($rootScope.currentAlertConfig._assetId);

             }else{
                console.log('load from db edit')

             $scope.loadAlertCnfgDetails();


              }

        } else {

          console.log('ajout')
          $scope.editMode = false;
          $scope.addMode=true;
          $scope.addAlertCfg();

      }



// default open right menu
      if( !$( ".sidebar-right" ).hasClass( "sidebar-open" ))
        $( ".sidebar-right" ).addClass( "sidebar-open" )



        }]);










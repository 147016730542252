/**
 * Project: fleetWeb
 * Created by  NGI team.
 */

angular.module("app.services").factory('alertModelSrv',['$http','$rootScope','dataSrv','$window', function ($http,$rootScope,dataSrv,$window) {

    var service={};

    service.getAlertModelsList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/alertmodel/query?limit='+l+'&page='+p,searchQuery).success(function(data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available
            if (data && data.success){

                success(data);
            }
            else{

                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }
    service.getAlertModelsAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/alertmodel/agg?limit='+l+'&page='+p,agg).success(function(data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available
            if (data && data.success){

                success(data);
            }
            else{

                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }


    service.getAlertModelById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/alertmodel/'+id).success(function(data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available

            if (data && data.success){

                success(data);
            }
            else{

                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }
    service.deleteAlertModelById=function(id,success,error){
        $http["delete"]($rootScope.config.API_URI+'/alertmodel/'+id).success(function(data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available

            if (data && data.success){

                success(data);
            }
            else{

                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }

    service.updateAlertModelById=function(id,alertModel,success,error){
        $http.put($rootScope.config.API_URI+'/alertmodel/'+id,alertModel).success(function(data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available

            if (data && data.success){

                success(data);
            }
            else{

                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }
    service.add=function(alertModel,success,error){
        $http.post($rootScope.config.API_URI+'/alertmodel/',alertModel).success(function(data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available

            if (data && data.success){

                success(data);
            }
            else{

                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }
    return service;

}]);

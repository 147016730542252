/**
 * Created by Mohamed Saad on 23/03/2017.
 */

angular.module("app").controller('reportsCtrl', ['$scope','$state', 'assetSrv','ngDialog','Notification',"$stateParams","$translate","$rootScope","$window",
  function ($scope,$state,assetSrv,ngDialog,Notification,$stateParams,$translate,$rootScope,$window) {




  }])

/**
 * Created by Kais CHAIBI on 06/02/2017.
 */

angular.module("app").controller('dashboardCtrl', function ($scope, $interval, $rootScope,CONFIG,assetSrv,alertSrv, fleetSrv,deviceMessageMetricSrv,ngDialog,$state ,Notification,$filter) {
  $('.sidebar-right').removeClass('sidebar-open');
$scope.itemsByPage=5;
$scope.moment=moment;
  if(!$scope.appData.datePicker){
    $scope.appData.datePicker={date : {startDate: moment().startOf('day'), endDate: moment().endOf('day')}};
  }



$scope.$watch("appData.datePicker.date",function(dates){
 // console.log("peeeeeeeeeeeerod",dates);
  if($scope.stCtrl && $scope.stCtrl.pipe){
    $scope.stCtrl.pipe();
  }else{
    $scope.loadAlerts({});
  }

  $scope.updateStats();
})
/*$scope.updateStats=function(){
 if($scope.assets && $scope.assets.length>0){
 var ids=$scope.assets.map(function(val){
 return  val._id;
 })

 var startDate=moment().set({hour:0,minute:0,second:0,millisecond:0});
 deviceMsgSrv.getDeviceMsgList({query:{_asset:{$in:ids},gps_dt:{$gte:$scope.datePicker.date.startDate,$lte:$scope.datePicker.date.endDate}}},1000,0,function(res){

 console.log(res);
 if(res && res.result && res.result.length>0 ){
 var max=undefined; var min=undefined;var count=1;var total=0;
 res.result.forEach(function(value){
 console.log("dalllas",max,min,value.io.dls_temp1);
 if(value && value.io && typeof value.io.dls_temp1 === 'number'){
 count++;
 total+=value.io.dls_temp1;
 if(typeof max!== 'number'){
 max=value.io.dls_temp1;
 min=value.io.dls_temp1;
 }else{
 if(value.io.dls_temp1>max){
 max=value.io.dls_temp1;
 }
 if(value.io.dls_temp1<min){
 min=value.io.dls_temp1;
 }
 }
 }
 })

 /!* if(max && max.io && max.io.dls_temp1){
 max= max.io.dls_temp1;
 }else{
 max=0;
 }
 if(min && min.io && min.io.dls_temp1){
 min= min.io.dls_temp1;
 }else{
 min=0;
 }*!/
 console.log("maaaaaaaaaaaax",max,min);
 $scope.stats={
 min:min,
 max:max,
 avg:total/count

 }
 }

 },function(err){
 console.log(err);
 })
 }
 }*/

  $scope.deleteAlert = function (alert) {
    ngDialog.openConfirm({
      template: 'app/commons/dialogs/confirm.dlg.html',
      overlay: false,
      showClose: false,
      controller: ['$scope', function ($scope) {
        // controller logic
        if (alert._alertConfig != null) {

          $scope.title = "Suppression d'alerte" //$translate.instant("ALERT_DELETE_CONFIRM_TITLE", {name: alert._alertConfig.name})
          $scope.message ="Êtes-vous sure de supprimé l'alerte " +alert._alertConfig.name+" ?"; //$translate.instant("ALERT_DELETE_CONFIRM_MESSAGE", {


        }
        else{

          $scope.title = "Suppression d'alerte" //$translate.instant("ALERT_DELETE_CONFIRM_TITLE", {name: alert._alertConfig.name})
          $scope.message ="Êtes-vous sure de supprimé l'alerte du " +alert._assetId.name+"  ?"; //$translate.instant("ALERT_DELETE_CONFIRM_MESSAGE", {




        }
      }]
    }).then(function () {
      alertSrv.deleteAlertById(alert._id, function (data) {
        //Notification($translate.instant("ALERT_ALERT_DELETED", {com:alert._company_owner.name, car: alert._assetId.name}));
        $scope.loadAlerts();
      }, function (err) {
      })
    }, function (reason) {
    });
  }
$scope.updateStats=function() {
  console.log("updateStats")
  $scope.stats= {
    avg: undefined,
    max: undefined,
    min: undefined
  }
  if ($scope.appData.assets && $scope.appData.assets.length > 0) {
    var group={_id:null}
    var project={avg:{$avg:[ ] },
      max:{$max:[]},
      min:{$min:[]}
    }
   var  ids=[];
    for(var i=0;i<$scope.appData.assets.length;i++) {
      if ($scope.appData.assets[i].sensors && $scope.appData.assets[i].sensors.length > 0) {
        var  assetHasTempSensor=false;

      for (var j = 0; j < $scope.appData.assets[i].sensors.length;j++) {
        if($scope.appData.assets[i].sensors[j].type=='Temperature') {
          group["avg_as" + i + "s" + j] = {$avg: {$divide: ["$" + $scope.appData.assets[i].sensors[j].src + ".sum", "$" + $scope.appData.assets[i].sensors[j].src + ".count"]}}
          group["min_as" + i + "s" + j] = {$min: "$" + $scope.appData.assets[i].sensors[j].src + ".min"};
          group["max_as" + i + "s" + j] = {$max: "$" + $scope.appData.assets[i].sensors[j].src + ".max"};
          project.avg.$avg.push("$avg_as" + i + "s" + j);
          project.max.$max.push("$max_as" + i + "s" + j);
          project.min.$min.push("$min_as" + i + "s" + j);
          assetHasTempSensor=true;
        }
        if(assetHasTempSensor){
          ids.push(new ObjectId($scope.appData.assets[i]._id).toString());
        }
      }
    }
    }

    var aggQuery=[

      {
        $match: {
          _asset:{$in:ids},
          dt:{$gte:new ISODate($scope.appData.datePicker.date.startDate).toString(),$lte:new ISODate($scope.appData.datePicker.date.endDate).toString()}}},
      {
        $group:
          group
      },
      {
        $project:
       project
      }

    ]
    if(ids.length>0){


    deviceMessageMetricSrv.getDailyMetricAgg({aggregate:aggQuery},100000,0,function(res){
      if(res &&  res.success && res.result &&  res.result.length>0){
        $scope.hideGauges=false;
        $scope.stats=res.result[0]
      }else{
        $scope.hideGauges=true;
      }
    }, function(err){
      if(data && data.status==-1){
        Notification.error("Problème de connexion Internet");
      }
      console.error(err);
    })
    }else{
      Notification.warning("Aucun capteur température configuré")
    }

  }
}

$rootScope.$on("assets:updated",function(evt,args){

  console.log("assets:updated")
  $scope.updateStats();
  if($scope.stCtrl && $scope.stCtrl.pipe){
    $scope.stCtrl.pipe();
  }else{
    $scope.loadAlerts({});
  }

})
$scope.loadAlerts =function (tableState, ctrl) {


  if (!$scope.stCtrl && ctrl) {
    $scope.stCtrl = ctrl;
  }

  if (!tableState && $scope.stCtrl) {
    $scope.stCtrl.pipe();
    return;
  }
  //console.log('load alertessssssssssss',tableState)
  var page;
  var limit = 1;
  var number = $scope.itemsByPage;
  var start = 1;

  if (tableState.pagination) {
    var pagination = tableState.pagination;
    number = pagination.number || $scope.itemsByPage;
    start = pagination.start / number + 1 || 1;
    $scope.currentPage = start;

  }


  var query = {
    query: {start_dt:{$gte:$scope.appData.datePicker.date.startDate,$lte:$scope.appData.datePicker.date.endDate}},

    options: {
      populate: [{path:'_company_owner',select:"name"},{path:'_assetId',select:"name"}, {path:'_alertConfig',select:"name"}],
      sortBy: {start_dt: -1}
    }
  };
  if($scope.appData.assets && $scope.appData.assets.length>0) {
    var ids = $scope.appData.assets.map(function (val) {
      return val._id;
    })
    query.query._assetId={$in:ids}
  }




  alertSrv.getAlertsList(query,number,start,function success (data) {
    if (data) {
    //  console.log(data)
      $scope.alertList = data.result;

      tableState.pagination.numberOfPages = data.page_count;
      tableState.pagination.totalItemCount = data.total_count;
    } else {
      console.error(data.error)
    }
  }, function (data) {
    if(data && data.status==-1){
      Notification.error("Problème de connexion Internet");
    }
    console.error(data)
  });


};

  $scope.changeStatus = function (alert) {
    alertSrv.updateAlertById(alert._id, {status: '0'},function (data) {
      if (data && data.success) {

        alert.status = 0;
      }
    }, function (data) {
      if(data && data.status==-1){
        Notification.error("Problème de connexion Internet");
      }
      console.error(data.error)
    })
  };
  var  updateAlerttListener=$rootScope.$on("newAlert",function(evt,args){

  //  console.log("newAlert",args)
    Notification.error("nouvelle alerte ");
    args._alertConfig=args.alertConfig;
    if( $scope.alertList &&  $scope.alertList.length!=undefined) {
      $scope.alertList.splice(0, 0, args);
      $scope.alertList.splice($scope.alertList.length - 1, 1);
    }
    $scope.$apply();
  });
  var newAlerttListener =$rootScope.$on("updateAlert",function(evt,args){
    //console.log("updateAlert",args);
    Notification.warning("Alerte updated");
    args._alertConfig=args.alertConfig;
    var filtred =$filter('filter')($scope.alertList,{_id:args._id},true);

    if( filtred &&  filtred.length>0) {
      {
        filtred[0].status = 0;
        filtred[0].end_dt=args.end_dt

      }
    }
    $scope.$apply();
  });

  $scope.$on("$destroy",function() {

      if(newAlerttListener) {
        newAlerttListener();
      }
      if(updateAlerttListener) {
        updateAlerttListener();
      }
      if(rightMenuListener) {
        rightMenuListener();
      }


    }
  )
 var rightMenuListener= $rootScope.$on("alertConfigMenu:open", function(evt,asset){

    $state.go("app.temperature.assetGroup.alertcfg",{assetId:asset._id});
  })
})

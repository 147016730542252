/**
 * Created by Kais CHAIBI on 02/02/2017.
 */
angular
  .module('app.filters').filter('durationFormat', function () {
  return function (value) {
    var days = Math.floor(value/86400000);
    value = value%86400000;
    var hours = Math.floor(value/3600000);
    value = value%3600000;
    var minutes = Math.floor(value/60000);
    value = value%60000;
    var seconds = Math.floor(value/1000);
    return (days? days + ' Jour(s) ': '') + (hours? hours + ' heure(s) ': '') + (minutes? minutes + ' minute(s) ': '') + (seconds? seconds + ' seconde(s) ': '')
  }
})
angular
  .module('app.filters').filter('abs', function () {
  return function(val) {
    return Math.abs(val).toFixed(2);
  }
});

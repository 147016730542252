/**
 * Created by Kais CHAIBI on 06/02/2017.
 */
angular.module("app").controller('ReportingCtrl', function ($scope, $interval, $rootScope, CONFIG, assetSrv, $window, fleetSrv, $stateParams, deviceMsgSrv, heatChartSrv, alertSrv,$state,$filter,ngDialog,Notification,Excel,$timeout ) {
  $('.sidebar-right').removeClass('sidebar-open');
  if (!$scope.appData.datePicker) {
    $scope.appData.datePicker = {date: {startDate: moment().startOf('day'), endDate: moment().endOf('day')}};
  }


  $scope.stats = {
    min: 0,
    max: 0,
    avg: 0

  }

  $scope.itemsByPage = 10;
  $scope.moment = moment;


  $scope.$watch("appData.datePicker.date", function (dates) {
    //console.log("peeeeeeeeeeeerod", dates);

    $scope.updateStats();
  })

  $scope.getQuery=function(startDt,endDt) {

    var startDate = moment(startDt || $scope.appData.datePicker.date.startDate)
    var endDate = moment(endDt || $scope.appData.datePicker.date.endDate)


    var ids=[];
    var group={   "_id": "$_asset", "_asset":{"$first": "$_asset"},
      "count": {"$sum": 1}}
    var project= {"avg": {"$avg":[ ]},
      _asset:"$_assset",
      "max":{$max:[ ]},
      min:{$min:[ ]} ,
      count:'$count',
      "name": {$arrayElemAt: ["$_asset.name", 0]},
      "_assetId": {$arrayElemAt: ["$_asset._id", 0]}
    }

    for(var i=0;i<$scope.appData.assets.length;i++) {
      if ($scope.appData.assets[i].sensors && $scope.appData.assets[i].sensors.length > 0) {
        var  assetHasTempSensor=false;

        for (var j = 0; j < $scope.appData.assets[i].sensors.length;j++) {
          if($scope.appData.assets[i].sensors[j].type=='Temperature') {
            group["avg_as" + i + "s" + j] = {$avg:"$" + $scope.appData.assets[i].sensors[j].src };
            group["min_as" + i + "s" + j] = {$min: "$" + $scope.appData.assets[i].sensors[j].src };
            group["max_as" + i + "s" + j] = {$max: "$" + $scope.appData.assets[i].sensors[j].src };
            project.avg.$avg.push("$avg_as" + i + "s" + j);
            project.max.$max.push("$max_as" + i + "s" + j);
            project.min.$min.push("$min_as" + i + "s" + j);
            assetHasTempSensor=true;
          }
          if(assetHasTempSensor){
            ids.push(new ObjectId($scope.appData.assets[i]._id).toString());
          }
        }
      }
    }


if(!ids ||  ids.length<=0) return undefined;

    var query = [

      {
        "$match": {
          "_asset": ids?{$in:ids} :undefined,
          gps_dt: {
            $gte: new ISODate(startDate.toDate()).toString(),
            $lte:new ISODate(endDate.toDate()).toString()
          }
        }
      },

      {
        "$group":  group
      } ,
      {
        "$lookup": {
          "from": "asset",
          "localField": "_id",
          "foreignField": "_id",
          "as": "_asset"
        }
      },
      {
        "$project":  project
      }
        ,
      {"$sort": {"name": 1}}


    ]


    return {aggregate:query};
  }


  $scope.updateStats = function () {


    var query = $scope.getQuery();
    if (query) {
      deviceMsgSrv.getDeviceMsgAgg2(query
        ,
        10000, 0, function (res) {


          if (res && res.result.length > 0) {

            //$scope.reports=res.result
            $scope.noData=false;
            $scope.drawTable(res.result)


          }else{
            $scope.noData=true;
          }

        }, function (err) {

          if (err && err.status == -1) {
            Notification.error("Problème de connexion Internet");
          }
          console.error("Device message error ", err);
        }, true)


  }else{
    Notification.warning("Aucun capteur température configuré")
  }
  }
  /**
   * clear table contents
   * @param tableObject
   * @param fromIndex
   * @param toIndex
   */
  function clearTableRows(tableObject, fromIndex,toIndex){
    var rowCount = tableObject.rows.length;
   var  from =fromIndex?fromIndex:0;
    var to=toIndex && toIndex<rowCount ?toIndex:rowCount-1;

    for (var x=to; x>=from; x--) {

      tableObject.deleteRow(x);
    }
  }
$scope.drawTable=function(data){

  var table = document.getElementById("ReportTable");
  clearTableRows(table,2);
  var tableSize= table.rows.length;

  if(data &&  data.length>0) {
   for(var i=0;i<data.length;i++) {
     var row = table.insertRow(i+2);
     var cell1 = row.insertCell(0);
     var cell2 = row.insertCell(1);
     var cell3 = row.insertCell(2);
     var cell4 = row.insertCell(3);
     cell1.innerHTML = data[i].name  ;
     cell2.innerHTML = data[i].min && data[i].min.toFixed?data[i].min.toFixed(2):'-';
     cell3.innerHTML = data[i].avg && data[i].avg.toFixed?data[i].avg.toFixed(2):'-';
     cell4.innerHTML = data[i].max && data[i].max.toFixed?data[i].max.toFixed(2):'-';
   }
  }else {
    var row = table.insertRow(4);
    var cell1 = row.insertCell(0);
    cell1.colspan='4'
    cell1.innerHTML = "Pas de données";
  }
}

  $scope.exportAsExcel=function(){


    var exportHref=Excel.tableToExcel("#ReportTable",($scope.appData.selectedFleet && $scope.appData.selectedFleet.name || 'Tous'));
    $timeout(function(){
      var link = document.createElement('a');
      link.download = "Rapport "+($scope.appData.selectedFleet && $scope.appData.selectedFleet.name || 'Tous')+"-"+(moment().format('YYYY-MM-DD'))+'.xls';

      link.download+=""
      link.href = exportHref;
      link.click();
      //location.href=exportHref;

    },100);
  }
  $scope.$on("$destroy",function() {



    }
  )

  $rootScope.$on("assets:updated",function(evt,args){

    $scope.updateStats();


  })

})

/**
 * Created by Mohamed Saad on 01/03/2017.
 */

angular.module("app").controller('fleetsDetailsCtrl', ['$scope', '$state', 'Notification', '$stateParams', '$rootScope', '$timeout', "fleetSrv", '$q', '$translate','ngDialog','$window','assetSrv','enumsSrv',
  function ($scope, $state, Notification, $stateParams, $rootScope, $timeout, fleetSrv, $q, $translate,ngDialog,$window,assetSrv,enumsSrv) {

    $scope._ADMIN_EDIT_ADD = {};
    $scope.moment = moment;

    /* assetSrv.getAssetsList({}, 2500, 0,function (data) {
     $scope.ASSETS = data.result;
     }, function(err){
     console.error('Asset not found',err);
     });*/

    $scope.loadFleetDetails = function () {
      var query = {
        query: {_id: $stateParams.id}, options: {
          populate: ['_company_owner','_assets']
        }
      };
      fleetSrv.getFleetsList(query, 1000, 0, function (result) {

        $scope.fleet = result.result[0];
       /// $scope.fleet._assets=[{_id:1,name:12}]

      }, function (err) {
        if (err && err.status == -1) {
          Notification.error("Problème de connexion Internet");
        }
        console.error(err)
      })
    };


    $scope.ClientSelect2Options = {
      allowClear: true,
      id: function (e) {
        return e
      },
      ajax: {
        transport: function (params) {
          params.beforeSend = function (request) {
            request.setRequestHeader("Authorization", 'Bearer ' + $window.sessionStorage.token);
          };
          return $.ajax(params);
        },
        url: $rootScope.config.API_URI + '/company/query?limit=' + 10 + '&page=' + 1,
        dataType: "json",
        type: "POST",
        quietMillis: 500,

        data: function (term, page) {
          return {
            query: {
              name: {"$regex": term, "$options": "i"}
            }
          };
        },
        results: function (data, page) {

          return {results: data.result};
        }
      },
      formatResult: function formatResult1(item) {
        return '<i class="fa fa-building-o"></i>   ' + item.name;
      },
      formatSelection: function formatSelection1(item) {
        return '<i class="fa fa-building"></i>    ' + item.name;
      }
    };

    $scope.ClientAsset2Options = {
      allowClear: true,
       multiple:true,
      id: function (e) {
        return e._id
      },
      ajax: {
        transport: function (params) {
          params.beforeSend = function (request) {
            request.setRequestHeader("Authorization", 'Bearer ' + $window.sessionStorage.token);
          };
          return $.ajax(params);
        },
        url: $rootScope.config.API_URI + '/asset/query?limit=' + 10 + '&page=' + 1,
        dataType: "json",
        type: "POST",
        quietMillis: 500,

        data: function (term, page) {

          return {
            query: {
              name: {"$regex": term, "$options": "i"}
            }
          };
        },
        results: function (data, page) {



          return {results: data.result};
        }
      }
      ,
      initSelection: function (element, callback) {
        console.log("initSelection", element);
      },
      formatResult: function formatResult1(item) {


        return '<i class="fa fa-building-o"></i>   ' + item.name;
      },
      formatSelection: function formatSelection1(item) {
        return '<i class="fa fa-building"></i>    ' + item.name;
      }

    };


   // $scope.loadFleetDetails();

    if( !$( ".sidebar-right" ).hasClass( "sidebar-open" ))
      $( ".sidebar-right" ).addClass( "sidebar-open" )
    $scope.cancel=function(){
      if($state.current.name=="app.temperature.admin.fleets.details"){
        $( ".sidebar-right" ).removeClass( "sidebar-open" )

        setTimeout(function(){
          $state.go("app.temperature.admin.fleets",{id:$stateParams.id});
        },100)


      }else  {
        $( ".sidebar-right" ).removeClass( "sidebar-open" )
        setTimeout(function(){
          $state.go("app.temperature.admin.fleets",{id:$stateParams.id});
        },100)
      }
    };

    enumsSrv.getEnums(function (data) {
      $scope.ENUMS = data
    }, function(err){
      console.error(err);
    });

    /*add fleet*/
    $scope.addFleet = function () {
      fleetSrv.add($scope.fleet, function (data) {
        if (data.success) {
          $scope.cancel();
          $rootScope.loadFleets();
          Notification($translate.instant("ALERT_ASSET_ADDED",{name:$scope.fleet.name}));
        }
      }, function (error) {
        console.log(error);
      })
    };

    /*update Fleet*/
    $scope.updateFleet = function (fleet,test) {
      console.log($scope.fleet)

      fleetSrv.updateFleetById($stateParams.id, $scope.fleet, function (data) {
        if (data.success) {
          $scope.cancel();
          $rootScope.loadFleets();
          Notification($translate.instant("FLEET_UPDATED", {name: $scope.fleet.name}));
        }
      }, function (error) {
        if (error) {
          console.log(error);
        }
      })
    }
    $scope.loadFleetDetails();

  }]);

/**
 * Project: fleetWeb
 * Created by  NGI team.
 */

angular.module("app.services").factory('roleSrv',['$http','$rootScope','dataSrv','$window', function ($http,$rootScope,dataSrv,$window) {

    var service={};

    service.getRolesList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/role/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){

            success(response.data);
          }
          else if(response && response.data && response.data.success===false) {

            error(response.data.error);
          }else{
            error(response);
          }


        },function(data, status, headers, config) {
          error(data)

        });
    }
    service.getRolesAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/role/agg?limit='+l+'&page='+p,agg).then(function(response) {
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){

            success(response.data);
          }
          else if(response && response.data && response.data.success===false) {

            error(response.data.error);
          }else{
            error(response);
          }


        },function(data, status, headers, config) {
          error(data)

        });
    }

    service.getRoleById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/role/'+id).then(function(response) {
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){

            success(response.data);
          }
          else if(response && response.data && response.data.success===false) {

            error(response.data.error);
          }else{
            error(response);
          }


        },function(data, status, headers, config) {
          error(data)

        });
    }
    service.deleteRoleById=function(id,success,error){
        $http["delete"]($rootScope.config.API_URI+'/role/'+id).then(function(response) {
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){

            success(response.data);
          }
          else if(response && response.data && response.data.success===false) {

            error(response.data.error);
          }else{
            error(response);
          }


        },function(data, status, headers, config) {
          error(data)

        });
    }

    service.updateRoleById=function(id,company,success,error){
        $http.put($rootScope.config.API_URI+'/role/'+id,company).then(function(response) {
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){

            success(response.data);
          }
          else if(response && response.data && response.data.success===false) {

            error(response.data.error);
          }else{
            error(response);
          }


        },function(data, status, headers, config) {
          error(data)

        });
    }
    service.add=function(company,success,error){
        $http.post($rootScope.config.API_URI+'/Role/',company).then(function(response) {
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){

            success(response.data);
          }
          else if(response && response.data && response.data.success===false) {

            error(response.data.error);
          }else{
            error(response);
          }


        },function(data, status, headers, config) {
          error(data)

        });;
    }
    return service;

}]);

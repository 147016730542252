angular
    .module('app')
    .config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
   //$locationProvider.html5Mode(true).hashPrefix('!');
    $urlRouterProvider.otherwise('/login');

    $stateProvider
        .state('login', {
            url: '/login',
            templateUrl: 'app/login/login.html',
            controller:'loginCtrl'
        })
        .state('app', {
            url: '/app',
            templateUrl: 'app/main/main.html',
            abstract:true,
        controller: "mainCtrl"
        }).state('app.temperature', {
            url: '/temperature',
            controller:"temperatureController",

        views: {
          "left@app": {
            templateUrl: 'app/main/temperature/assetGroup/tpl/left.tpl.html',
            controller:'assetListController'
          }
        }



        }).state('app.temperature.assetGroup', {
            url: '/dashboard',
            views: {


                "main@app": {
                  templateUrl: 'app/main/temperature/assetGroup/tpl/main.tpl.html',
                  controller: "dashboardCtrl"
                }

            }


        }).state('app.temperature.details', {
        url: '/details/:id',
        views: {


          "main@app": {
            templateUrl: 'app/main/temperature/assetDashboard/tpl/main.tpl.html',
            controller: "tempsdetailsCtrl"
          }

        }


      }) .state('app.temperature.assetGroup.alertcfg', {
        url: '/alertcfg/:assetId',
        views: {


          "right@app": {
            templateUrl: 'app/main/temperature/alertsCfg/alert.right.html',
            controller: "alertConfigCtrl"
          }

        }


      }).state('app.temperature.details.alertcfg', {
        url: '/alertcfg/:assetId',
        views: {


          "right@app": {
            templateUrl: 'app/main/temperature/alertsCfg/alert.right.html',
            controller: "alertConfigCtrl"
          }

        }


      }).state('app.temperature.alerts', {
        url: '/alerts',
        views: {


          "left@app":{

              templateUrl: 'app/main/temperature/alerts/left.tpl.html',
              controller: "alertStatsCtrl"

          },
          "main@app": {
            templateUrl: 'app/main/temperature/alerts/alerts.tpl.html',
            controller: "alertsCtrl"
          }

        }


      })
      .state('app.temperature.alerts.details', {
        url: '/details/:id',
        views: {



          "right@app": {
            templateUrl: 'app/main/temperature/alerts/alertDetails.html',
            controller: "alertDetails"
          }

        }


      }) .state('app.temperature.reporting', {
        url: '/reporting',
        views: {

          "left@app": {
            templateUrl: 'app/main/temperature/reporting/left.tpl.html',
            controller:'assetListController'
          },
          "main@app": {
            templateUrl: 'app/main/temperature/reporting/main.tpl.html',
            controller: "ReportingCtrl"
          }



        }


      }).state('app.temperature.reporting.details', {
        url: '/:id',
        views: {


          "main@app": {
            templateUrl: 'app/main/temperature/reporting/reportDetails.tpl.html',
            controller: "ReportingDetailsCtrl"
          }



        }


      }).state('app.temperature.admin', {
        url: '/admin',
        views: {


          "left@app": {
            templateUrl: 'app/main/temperature/admin/admin.left.tpl.html',
            controller: "adminCtrl"
          }


        }


      }).state('app.temperature.admin.depots', {
        url: '/depot',
        views: {


          "main@app": {
            templateUrl: 'app/main/temperature/admin/depots/admin.depots.tpl.html',
            controller: "depotsCtrl"
          }


        }


      }).state('app.temperature.admin.depots.details', {
        url: '/:id',
        views: {


          "right@app": {
            templateUrl: 'app/main/temperature/admin/depots/admin.depots.details.tpl.html',
            controller: "depotsDetailsCtrl"
          }


        }


      }).state('app.temperature.admin.depots.add', {
        url: '/add',
        views: {


          "right@app": {
            templateUrl: 'app/main/temperature/admin/depot/admin.depots.details.tpl.html',
            controller: "depotsDetailsCtrl"
          }


        }


      }).state("app.temperature.admin.depots.sensors", {
        url: '/sensorList/:id',
        views: {


          "right@app": {
            templateUrl: 'app/main/temperature/admin/depots/admin.depots.sensors.tpl.html',
            controller: "depotsSensorsCtrl"
          }


        }


      })



      .state('app.temperature.admin.users', {
        url: '/users',
        views: {


          "main@app": {
            templateUrl: 'app/main/temperature/admin/users/admin.users.tpl.html',
            controller: "usersCtrl"
          }


        }


      }).state('app.temperature.admin.users.details', {
        url: '/:id',
        views: {


          "right@app": {
            templateUrl: 'app/main/temperature/admin/users/admin.users.details.tpl.html',
            controller: "usersDetailsCtrl"
          }


        }


      }).state('app.temperature.admin.users.add', {
        url: '/add',
        views: {


          "right@app": {
            templateUrl: 'app/main/temperature/admin/users/admin.users.details.tpl.html',
            controller: "usersDetailsCtrl"
          }


        }


      }).state('app.temperature.admin.fleets', {
        url: '/fleets',
        views: {


          "main@app": {
            templateUrl: 'app/main/temperature/admin/fleets/admin.fleets.tpl.html',
            controller: "fleetsCtrl"
          }


        }


      }).state('app.temperature.admin.fleets.details', {
        url: '/:id',
        views: {


          "right@app": {
            templateUrl: 'app/main/temperature/admin/fleets/admin.fleets.details.tpl.html',
            controller: "fleetsDetailsCtrl"
          }


        }


      }).state('app.temperature.admin.fleets.add', {
        url: '/add',
        views: {


          "right@app": {
            templateUrl: 'app/main/temperature/admin/fleets/admin.fleets.details.tpl.html',
            controller: "fleetsDetailsCtrl"
          }


        }


      }).state('app.temperature.admin.alerts', {
        url: '/alerts',
        views: {


          "main@app": {
            templateUrl: 'app/main/temperature/admin/alerte/admin.alerts.tpl.html',
            controller: "alertsAdminCtrl"
          }

        }

      }).state('app.temperature.admin.alerts.details', {
        url: '/edit/:id',
        views: {


          "right@app": {
            templateUrl: 'app/main/temperature/admin/alerte/admin.alerts.details.tpl.html',
            controller: "alertsAdminDetailsCtrl"
          }
        }
      }).state('app.temperature.admin.alerts.add', {
        url: '/add',
        views: {


          "right@app": {
            templateUrl: 'app/main/temperature/admin/alerte/admin.alerts.details.tpl.html',
            controller: "alertsAdminDetailsCtrl"
          }


        }


      })

      .state('app.temperature.admin.device', {
        url: '/boitierDevice',
        views: {


          "main@app": {
            templateUrl: 'app/main/temperature/admin/boitierDevice/admin.device.tpl.html',
            controller: "deviceAdminCtrl"
          }

        }

      }).state('app.temperature.admin.device.details', {
        url: '/edit/:id',
        views: {


          "right@app": {
            templateUrl: 'app/main/temperature/admin/boitierDevice/admin.device.details.tpl.html',
            controller: "deviceAdminDetailCtrl"
          }
        }
      }).state('app.temperature.admin.device.add', {
        url: '/add',
        views: {


          "right@app": {
            templateUrl: 'app/main/temperature/admin/boitierDevice/admin.device.details.tpl.html',
            controller: "deviceAdminDetailCtrl"
          }


        }


      })




      .state('app.temperature.admin.rapports', {
        url: '/rapports',
        views: {


          "main@app": {
            templateUrl: 'app/main/temperature/admin/reports/admin.reports.tpl.html',
            controller: "reportsCtrl"
          }


        }


      })




}

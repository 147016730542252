/**
 * Created by Kais CHAIBI on 06/02/2017.
 */
angular.module("app").controller('ReportingDetailsCtrl',
  function ($scope, $interval, $rootScope, CONFIG, assetSrv, $window, fleetSrv, $stateParams, deviceMsgSrv, heatChartSrv, alertSrv,$state,$filter,ngDialog,Notification,Excel,$timeout) {
  $('.sidebar-right').removeClass('sidebar-open');

  $window.sessionStorage.appData=JSON.stringify($scope.appData);



    $scope.groupingOptions=[
    {
      code:'monthly',
      name:"Par mois",
      dateFormat:'MMMM YYYY',
      dateQuery:{
        "y": {"$year":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
        "m":{ $month: {"$add":["$gps_dt",moment().utcOffset()*60*1000]}}
      }

    }, {
      code:'weekly',
      name:"Par Semaine",
      dateFormat:'[semaine ]WW - YYYY',
      dateQuery:{
        "y": {"$year": {"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
        "w":{ $week: {"$add":["$gps_dt",moment().utcOffset()*60*1000]}}
      }

    },{
      code:'daily',
      name:"Par jour",
      dateFormat:'YYYY-MM-DD',
      dateQuery:{
      "y": {"$year": {"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
      "m": {"$month": {"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
      "d": {"$dayOfMonth": {"$add":["$gps_dt",moment().utcOffset()*60*1000]}}
      }
    },


    {
      name:"Par heure",
      code:'perHour',
      dateFormat:'YYYY-MM-DD HH[h]',
      dateQuery:{
        "y": {"$year": {"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
        "m":{ $month: {"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
        "d": {"$dayOfMonth": {"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
        "h":{ $hour: {"$add":["$gps_dt",moment().utcOffset()*60*1000]}}
      }

    },
    {
      name:"Par minute",
      code:'perMinute',
      dateFormat:'YYYY-MM-DD HH:mm',
      dateQuery:{
        "y": {"$year": {"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
        "m":{ $month: {"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
        "d": {"$dayOfMonth": {"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
        "h":{ $hour: {"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
        "mm":{ $minute:{"$add":["$gps_dt",moment().utcOffset()*60*1000]}}
      }

    }
    ,
    {
      name:"Par seconde",
      code:'perSecond',
      dateFormat:'YYYY-MM-DD HH:mm:ss',
      dateQuery:{
        "y": {"$year": {"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
        "m":{ $month: {"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
        "d": {"$dayOfMonth": {"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
        "h":{ $hour: {"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
        "mm":{ $minute: {"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
        "ss":{ $second: {"$add":["$gps_dt",moment().utcOffset()*60*1000]}}
      }

    }

  ]


  if(!$scope.appData.groupingOption) {
    $scope.appData.groupingOption = $filter ('filter')($scope.groupingOptions,{code:'perHour'},true)[0];
    //console.log("unfounded1 ",$scope.appData.groupingOption );
  }else{
    $scope.appData.groupingOption = $filter ('filter')($scope.groupingOptions,{code:$scope.appData.groupingOption.code},true)[0];
    //console.log("founded1",$scope.appData.groupingOption );
  }

  if (!$scope.appData.datePicker) {
    $scope.appData.datePicker = {date: {startDate: moment().startOf('day'), endDate: moment().endOf('day')}};
  }
  if ($stateParams.id) {

    $scope.asset = {_id: $stateParams.id}
    assetSrv.getAssetById($stateParams.id, function (res) {


      $scope.asset = res.result;
      $scope.sensors=$filter("filter")($scope.asset.sensors,{type:'Temperature'},true);
      $scope.updateStats();
      //console.log('assset',$scope.asset)
    }, function (err) {
      if(err && err.status==-1){
        Notification.error("Problème de connexion Internet");
      }
      console.error("error");
    })
  } else {

  }

  $scope.stats = {
    min: 0,
    max: 0,
    avg: 0,


  }

  $scope.itemsByPage = 10;
  $scope.moment = moment;



  $scope.$watch("appData.datePicker.date", function (dates) {
    //console.log("peeeeeeeeeeeerod", dates);

    if($scope.asset && $scope.asset.name)
    $scope.updateStats();
  })

  $scope.getQuery=function(startDt,endDt) {

    var startDate = moment(startDt || $scope.appData.datePicker.date.startDate)
    var endDate = moment(endDt || $scope.appData.datePicker.date.endDate)
    var project= {

      "date1": "$gps_dt",

        "date": $scope.appData.groupingOption.dateQuery

    }
    var  group ={
      "_id": "$date",


      "count": {"$sum": 1}


    }
    if($scope.sensors.length<=0){
      Notification.warning("Aucun capteur configuré ");
      return;
    }
    for(var i=0;i<$scope.sensors.length;i++){
      project["s"+ i]="$"+$scope.sensors[i].src
      group['s'+i+'_avg']={"$avg": "$s"+ i }
      group['s'+ i +'_max']={"$max": "$s"  + i }
      group['s'+ i +'_min']={"$min": "$s"+i}
    }





    var query = [

      {
        "$match": {
          "_asset":  $stateParams.id ,
          gps_dt: {
            $gte:  startDate.toDate() ,
            $lte: endDate.toDate()
          }
        }
      },
      {
        "$project": project
      },
      {
        "$group":  group
      }  ,
      {"$sort": {"_id": 1}}


    ]
    console.log()
    console.log("sensoooooooooooooorw" +query)
    return {query:query};
  }


  $scope.updateStats = function () {


    /*if(!$scope.appData.groupingOption) {
      $scope.appData.groupingOption = $filter ('filter')($scope.groupingOptions,{code:'perHour'},true);
      console.log("unfounded",$scope.appData.groupingOption );
    }else{
      $scope.appData.groupingOption = $filter ('filter')($scope.groupingOptions,{code:$scope.appData.groupingOption.code},true)[0];
      console.log("founded",$scope.appData.groupingOption );
    }
*/


    $scope.saveAppDataToLocalStorage($scope.appData);

    console.log($scope.asset.sensors,$scope.sensors)
      deviceMsgSrv.getDeviceMsgAgg($scope.getQuery()
        ,
        10000, 0, function (res) {


          console.log('res',res)

          if (res && res.length > 0) {
            $scope.NodData=false;
            //$scope.reports=res.result

           /// console.log(res.length)
            $scope.drawTable(res)


          }else{
            var table = document.getElementById("ReportTable");
            clearTableRows(table, 4);
            $scope.NodData=true;

          }

        }, function (err) {

          if(err && err.status==-1){
            Notification.error("Problème de connexion Internet");
          }
          console.error("Device message error ", err);
        }, true)
    }


  /**
   * clear table contents
   * @param tableObject
   * @param fromIndex
   * @param toIndex
   */
  function clearTableRows(tableObject, fromIndex,toIndex){
    var rowCount = tableObject.rows.length;
   var  from =fromIndex?fromIndex:0;
    var to=toIndex && toIndex<rowCount ?toIndex:rowCount-1;

    for (var x=to; x>=from; x--) {

      tableObject.deleteRow(x);''
    }
  }

    $scope.drawTable = function (data) {
      //console.log('data table', data)


      var table = document.getElementById("ReportTable");
      clearTableRows(table, 3);
      var tableSize = table.rows.length;




      if (data && data.length > 0) {


        var insertIndex = 2;


          for (var i = 0; i < data.length; i++) {
            if(Object.keys(data[i]).length>2)
            {
            try {
              insertIndex++;
              //console.log("1" + $scope.sensors)
              var date = null;


              if (data[i]._id.w) {
                date = moment().day("Monday").year(data[i]._id.y).week(data[i]._id.w);
              } else {
                date = moment({
                  y: data[i]._id.y,
                  M: data[i]._id.m - 1,
                  d: data[i]._id.d,
                  h: data[i]._id.h,
                  m: data[i]._id.mm,
                  s: data[i]._id.ss,
                  week: data[i]._id.w
                })
              }

              var row = table.insertRow(insertIndex);

              //console.log("1" + $scope.sensors)
              var cell0 = row.insertCell(0);
              var cell1 = row.insertCell(1);

              cell0.outerHTML = "<th>" + (insertIndex - 2) + "</th>";
              //console.log("2" + $scope.sensors)
              cell1.innerHTML = date.format($scope.appData.groupingOption.dateFormat);
              //console.log("3" + $scope.sensors)
              $scope.sensors.forEach(function (val, index) {


                row.insertCell(index * 3 + 2).innerHTML = (data[i]["s" + index + "_min"] && data[i]["s" + index + "_min"].toFixed) ? data[i]["s" + index + "_min"].toFixed(2) : '-';
                row.insertCell(index * 3 + 3).innerHTML = (data[i]["s" + index + "_avg"] && data[i]["s" + index + "_avg"].toFixed  ) ? data[i]["s" + index + "_avg"].toFixed(2) : '-';
                row.insertCell(index * 3 + 4).innerHTML = (data[i]["s" + index + "_avg"] && data[i]["s" + index + "_avg"].toFixed  ) ? data[i]["s" + index + "_avg"].toFixed(2) : '-';


              })
            } catch (e) {
               console.log(e)
            }
            }else{
              console.log(data)
            }
          }

      } else {
        var row = table.insertRow(3);
        var cell1 = row.insertCell(0);
        cell1.colspan = '4'
        cell1.innerHTML = "Pas de données";
      }
    }

  $scope.exportAsExcel=function(){

   var exportHref=Excel.tableToExcel("#ReportTable",$scope.asset.name);
   $timeout(function(){
     var link = document.createElement('a');
     link.download = "Rapport "+$scope.appData.groupingOption.name +"-"+$scope.asset.name+"-"+(moment().format('YYYY-MM-DD'))+'.xls';

     link.download+=""
     link.href = exportHref;
     link.click();
     //location.href=exportHref;

   },100);
 }
  $scope.$on("$destroy",function() {

    }
  )
  $scope.saveAppDataToLocalStorage=function(appData){
   // delete appData.assets
    $window.sessionStorage.appData=JSON.stringify(appData);
  }



})

/**
 * Created by Kais CHAIBI on 06/02/2017.
 */
angular.module("app").controller('tempsdetailsCtrl', function ($scope, $interval, $rootScope, CONFIG, assetSrv, $window, fleetSrv, $stateParams, deviceMsgSrv, heatChartSrv, alertSrv,$state,$filter,ngDialog,Notification,deviceMsgQueryTools) {
  $('.sidebar-right').removeClass('sidebar-open');
  if (!$scope.appData.datePicker) {
    $scope.appData.datePicker = {date: {startDate: moment().startOf('day'), endDate: moment().endOf('day')}};
  }

  $scope.isFinite=isFinite;
  $scope.showChart = false;

  if ($stateParams.id) {

    $scope.asset = {_id: $stateParams.id}
    assetSrv.getAssetById($stateParams.id, function (res) {


      $scope.asset = res.result;
      $scope.updateStats()
    }, function (err) {
      if(err && err.status==-1){
        Notification.error("Problème de connexion Internet");
      }
      console.error("error");
    })
  } else {

  }
  $scope.itemsByPage = 10;
  $scope.moment = moment;


  $scope.$watch("appData.datePicker.date", function (dates) {
    $scope.stats = {};
    $scope.showChart = false;
    if($scope.asset.sensors && $scope.asset.sensors.length){
      for(var i = 0; i< $scope.asset.sensors.length; i++) {
        $scope.stats['s' + i] = {avg: 0, max: 0, min: 0}
      }
    }

    $scope.loadAlerts({});
    if($scope.stCtrl){
      $scope.stCtrl.pipe()
    }
    if($scope.asset.sensors){
      $scope.updateStats();
    }
  })
  $scope.getQuery=function(startDt,endDt){

     if($scope.asset.sensors.length<=0) {
       Notification.warning("Aucun capteur configuré");
       return
     }
    var startDate=moment.utc(startDt || $scope.appData.datePicker.date.startDate)
    var endDate=moment.utc(endDt || $scope.appData.datePicker.date.endDate)

    var options={sources:[]};
      for(var j = 0; j < $scope.asset.sensors.length; j++){
        options.sources.push({src:$scope.asset.sensors[j].src,as:'s'+j});
      }

    var agg= deviceMsgQueryTools.BuildQueryByPeriod($scope.asset._id,startDate,endDate,options);

    return  {query:agg}
/*

    var days=endDate.diff(startDate, 'days')
    console.log('query end datee' ,  startDate,endDate);
    var query=[

      {
        "$match": {
          "_asset": $scope.asset._id,
          gps_dt: {
            $gte: startDate.toDate(),
            $lte: endDate.toDate()
          }
        }
      },

      {
        "$project": {
          "date1": "$gps_dt",
          "data": "$io.dls_temp1",
          "date": {
            "y": {"$year": "$gps_dt"},
            "m": {"$month": "$gps_dt"},
            "d": {"$dayOfMonth": "$gps_dt"},
            "h": {"$mod": [{"$multiply": [{"$hour": "$gps_dt"}, 1]}, 24]},//{"$hour": "$gps_dt"},
            "mm": {"$mod": [{"$multiply": [{"$minute": "$gps_dt"}, 1]}, 60]}
          }
        }
      },
      {
        "$group": {
          "_id": {
            "y": "$date.y",
            "m": "$date.m",
            "d": "$date.d",
            "h": "$date.h",
            "mm":"$date.mm"

          },

          "avg": {"$avg": "$data"},
          "max": {"$max": "$data"},
          "min": {"$min": "$data"},
          "count": {"$sum": 1}
        }
      },
      {"$sort": {"_id": 1}}


    ]
    if(days==0){
      //5min

        query = [

          {
            "$match": {
              "_asset": $scope.asset._id,
              gps_dt: {
                $gte: startDate.toDate(),
                $lte: endDate.toDate()
              }
            }
          },

          {
            "$project": {
              "date1": "$gps_dt",
              "data": "$io.dls_temp1",
              "date": {
                "y": {"$year": "$gps_dt"},
                "m": {"$month": "$gps_dt"},
                "d": {"$dayOfMonth": "$gps_dt"},
                "h": {"$hour": "$gps_dt"},

                "mm": {"$floor": {$divide:[{"$minute": "$gps_dt"},5]}}
              }
            }
          },
          {
            "$group": {
              "_id": {
                "y": "$date.y",
                "m": "$date.m",
                "d": "$date.d",
                "h": "$date.h",
                "mm": "$date.mm",

              },

              "avg1": {"$avg": "$data"},
              "max1": {"$max": "$data"},
              "min1": {"$min": "$data"},

              "count": {"$sum": 1}
            }
          },

          {
            "$project": {
              "_id": {
                "y": "$_id.y",
                "m": "$_id.m",
                "d": "$_id.d",
                "h": "$_id.h",

                "mm": {$add:[{"$multiply": ["$_id.mm", 5]},2]},
                 "ss":30
              },
              "avg": "$avg1",
              "max": "$max1",
              "min": "$min1",
              "count": "$count"
            }
          },
          {"$sort": {"_id": 1}}


        ]


    }else  if(days<=3){
      //hours
      query=[

        {
          "$match": {
            "_asset": $scope.asset._id,
            gps_dt: {
              $gte: startDate.toDate(),
              $lte: endDate.toDate()
            }
          }
        },

        {
          "$project": {
            "date1": "$gps_dt",
            "data": "$io.dls_temp1",
            "date": {
              "y": {"$year": "$gps_dt"},
              "m": {"$month": "$gps_dt"},
              "d": {"$dayOfMonth": "$gps_dt"},
              "h": {"$hour": "$gps_dt"}
            }
          }
        },
        {
          "$group": {
            "_id": {
              "y": "$date.y",
              "m": "$date.m",
              "d": "$date.d",
              "h": "$date.h"


            },

            "avg": {"$avg": "$data"},
            "max": {"$max": "$data"},
            "min": {"$min": "$data"},
            "count": {"$sum": 1}
          }
        },
        {"$sort": {"_id": 1}}


      ]
    }
    else  if(days<=7){
      //4hours
      query=[

        {
          "$match": {
            "_asset": $scope.asset._id,
            gps_dt: {
              $gte: startDate.toDate(),
              $lte: endDate.toDate()
            }
          }
        },

        {
          "$project": {
            "date1": "$gps_dt",
            "data": "$io.dls_temp1",
            "date": {
              "y": {"$year": "$gps_dt"},
              "m": {"$month": "$gps_dt"},
              "d": {"$dayOfMonth": "$gps_dt"},
              "h": {"$mod": [{"$multiply": [{"$hour": "$gps_dt"}, 4]}, 24]}
            }
          }
        },
        {
          "$group": {
            "_id": {
              "y": "$date.y",
              "m": "$date.m",
              "d": "$date.d",
              "h": "$date.h"
              // "mm":"$date.mm"

            },

            "avg": {"$avg": "$data"},
            "max": {"$max": "$data"},
            "min": {"$min": "$data"},
            "count": {"$sum": 1}
          }
        },
        {"$sort": {"_id": 1}}


      ]
    }else {
      //6hours
      query=[

        {
          "$match": {
            "_asset": $scope.asset._id,
            gps_dt: {
              $gte: startDate.toDate(),
              $lte: endDate.toDate()
            }
          }
        },

        {
          "$project": {
            "date1": "$gps_dt",
            "data": "$io.dls_temp1",
            "date": {
              "y": {"$year": "$gps_dt"},
              "m": {"$month": "$gps_dt"},
              "d": {"$dayOfMonth": "$gps_dt"}
            }
          }
        },
        {
          "$group": {
            "_id": {
              "y": "$date.y",
              "m": "$date.m",
              "d": "$date.d"


            },

            "avg": {"$avg": "$data"},
            "max": {"$max": "$data"},
            "min": {"$min": "$data"},
            "count": {"$sum": 1}
          }
        },
        {"$sort": {"_id": 1}}


      ]
    }





*/

    return {query:query};
  }


  /**
   * update chart on zoom/ selection
   * @param AxisMin
   * @param AxisMax
   * @param callback
   */
  $scope.chartZoomUpdate=function(AxisMin,AxisMax,callback){
    if(AxisMax && AxisMin) {
    if ($scope.asset) {

      var startDate = moment().set({hour: 0, minute: 0, second: 0, millisecond: 0});
    var query =$scope.getQuery(AxisMin?new Date(AxisMin):null,AxisMax? new Date(AxisMax):null);
     if(!query)
     return  ;
      deviceMsgSrv.getDeviceMsgAgg(query
        ,
        10000, 0, function (res) {

          if (res && res.length > 0) {
            var series= angular.copy($scope.series);
            /*var sensors_max = undefined;
            var sensors_min = undefined;
            var sensors_avg = undefined;*/
            var sensors_count = 0;
            var sensors_total = 0;
            var sensors_avgData = [];
            var sensors_minData = [];
            var sensors_maxData = [];
            var xaxis = [];

            res.forEach(function (value, index) {
              var _date = moment({
                y: value._id.y,
                M: value._id.m - 1,
                d: value._id.d,
                h: value._id.h,
                m: value._id.mm,
                s: value._id.ss
              });//.add(moment().utcOffset(),'minute');
              //  console.dir(_date)
              if($scope.asset.sensors && $scope.asset.sensors.length)
              for(var i = 0; i< $scope.asset.sensors.length; i++) {
               var  sensors_avg = 's'+i+'_avg';
              var   sensors_min = 's'+i+ '_min';
              var   sensors_max = 's'+i+'_max';

                if (value[sensors_avg] && value[sensors_min] && value[sensors_max]) {
                  if(!sensors_avgData[i])
                    sensors_avgData[i] = [];
                  sensors_avgData[i].push([_date.valueOf(), parseFloat(value[sensors_avg].toFixed(2))]);
                  if(!sensors_minData[i])
                    sensors_minData[i] = [];
                  sensors_minData[i].push([_date.valueOf(), parseFloat(value[sensors_min].toFixed(2)), parseFloat(value[sensors_max].toFixed(2))]);
                  if(!sensors_maxData[i])
                    sensors_maxData[i] = [];
                  sensors_maxData[i].push([_date.valueOf(), value[sensors_max]]);
                }
              }

              sensors_count++;
              sensors_total += value[sensors_avg];
            });

            /**
             * indexes min mag of zoomed area in series array
             * @type {null}
             */





              for(var i = 0; i<$scope.asset.sensors.length; i++){
                // found the index max and min in  series for range
                var sensors_minIndex=null;
                var sensors_maxIndex=null;

                // for reset zoom no merge arrays needed
                var sensors_minDataSeries=sensors_minData;
                var sensors_avgDataSeries=sensors_avgData;
                $scope.series[i*2].data.forEach(function(val,index){

                  if(sensors_minIndex==null &&  val[0]>=AxisMin){
                  //  console.log(val[0])
                    sensors_minIndex=index;
                  }
                  if(sensors_maxIndex==null &&  val[0]>=AxisMax){
                 //   console.log(val[0])
                    sensors_maxIndex=index;
                  }})


                // get the stored in scope series  minData and avgData


                  sensors_minDataSeries = $scope.series[2*i].data;
                  sensors_avgDataSeries = $scope.series[2*i+1].data;

                // remove the range from minDate --> max date selection
                sensors_minDataSeries.splice(sensors_minIndex, sensors_maxIndex - sensors_minIndex + 1);

                 // add new data in the removed range
                Array.prototype.splice.apply(sensors_minDataSeries, [sensors_minIndex, 0].concat(sensors_minData[i]));



                // remove the range from minDate --> max date selection
                sensors_avgDataSeries.splice(sensors_minIndex, sensors_maxIndex - sensors_minIndex + 1);
                // add new data in the removed range
                Array.prototype.splice.apply(sensors_avgDataSeries, [sensors_minIndex, 0].concat(sensors_avgData[i]));


                // sort data for unsorted error
                sensors_minDataSeries.sort(function(val1,val2){
                  return val1[0]<val2[0]? -1:1;
                });

                // sort data for unsorted error
                sensors_avgDataSeries.sort(function(val1,val2){
                  return val1[0]<val2[0] ? -1:1;
                });
                //sensors_avgDataSeries=sensors_avgDataSeries[i];
                series[2*i].data=sensors_minDataSeries;
                series[2*i+1].data=sensors_avgDataSeries;
              }  //End for

console.log(series);
                callback(series);





        }

        }, function (err) {

          if(err && err.status==-1){
            Notification.error("Impossible de contacter le serveur, Problème de connexion Internet ou le serveur est en maintenance");
          }
          console.error("Device message error ", err);
        }, true)
    }
    } else {

      // call callback to reset chart with  minData  and avgData


      console.log($scope.series)
      console.log('$scope.series[i].data',$scope.series)
        callback( $scope.series)

    }

  }
  $scope.updateStats = function () {
    if ($scope.asset) {
     console.log( moment($scope.appData.datePicker.date.startDate));

      var startDate = moment().set({hour: 0, minute: 0, second: 0, millisecond: 0});
      deviceMsgSrv.getDeviceMsgAgg($scope.getQuery(), 10000, 0, function (res) {

        $scope.getStats(res)

        }, function (err) {

          if(err && err.status==-1){
            Notification.error("Problème de connexion Internet");
          }
          console.error("Device message error ", err);
        }, true)
    }
  }


  $scope.loadAlerts = function (tableState, ctrl) {

    if (!$scope.stCtrl && ctrl) {
      $scope.stCtrl = ctrl;
    }

    if (!tableState && $scope.stCtrl) {
      $scope.stCtrl.pipe();
      return;
    }
    var page;
    var limit = 1;
    var number = $scope.itemsByPage;
    var start = 1;

    if (tableState.pagination) {
      var pagination = tableState.pagination;
      number = pagination.number || $scope.itemsByPage;
      start = pagination.start / number + 1 || 1;
      $scope.currentPage = start;

    }


    var query = {
      query: {
        _assetId: $scope.asset._id,
        start_dt: {$gte: $scope.appData.datePicker.date.startDate, $lte: $scope.appData.datePicker.date.endDate}
      },

      options: {
        populate: ['_company_owner', '_assetId', '_alertConfig'],
        sortBy: {start_dt: -1}
      }
    };


    alertSrv.getAlertsList(query, number, start, function success(data) {
      if (data) {
        $scope.alertList = data.result;
        tableState.pagination.numberOfPages = Math.ceil(data.total_count / number);
        tableState.pagination.totalItemCount = data.total_count;
      } else {

        console.error(data.error)
      }
    }, function (data) {
      if(data && data.status==-1){
        Notification.error("Problème de connexion Internet");
      }
      console.error(data)
    });


  };



var   newMsgListener = $rootScope.$on('newMsg', function (event, data) {

    if ($scope.asset) {


      if ($scope.asset._id == data._id) {
        // console.log("NOTIFICATION", data.rt);

        $scope.asset.rt = data.rt;
        try {
          $scope.$apply()

        } catch (e) {
          console.error(e);
        }


      }
    }
  })

  $scope.deleteAlert = function (alert) {
    ngDialog.openConfirm({
      template: 'app/commons/dialogs/confirm.dlg.html',
      overlay: false,
      showClose: false,
      controller: ['$scope', function ($scope) {
        // controller logic
        if (alert._alertConfig != null) {

          $scope.title = "Suppression d'alerte" //$translate.instant("ALERT_DELETE_CONFIRM_TITLE", {name: alert._alertConfig.name})
          $scope.message ="Êtes-vous sure de supprimé l'alerte " +alert._alertConfig.name+" ?"; //$translate.instant("ALERT_DELETE_CONFIRM_MESSAGE", {
        }
        else{

          $scope.title = "Suppression d'alerte" //$translate.instant("ALERT_DELETE_CONFIRM_TITLE", {name: alert._alertConfig.name})
          $scope.message ="Êtes-vous sure de supprimé l'alerte du " +alert._assetId.name+"  ?"; //$translate.instant("ALERT_DELETE_CONFIRM_MESSAGE", {

        }
      }]
    }).then(function () {
      alertSrv.deleteAlertById(alert._id, function (data) {
        //  Notification($translate.instant("ALERT_ALERT_DELETED", {com:alert._company_owner.name, car: alert._assetId.name}));
        $scope.loadAlert({});
      }, function (err) {
      })
    }, function (reason) {
    });
  }
  $scope.changeStatus = function (alert) {
    alertSrv.updateAlertById(alert._id, {status: '0'},function (data) {
      if (data && data.success) {

        alert.status = 0;
      }
    }, function (data) {
      if(data && data.status==-1){
        Notification.error("Problème de connexion internet ! ")
      }

    })
  };

  var  updateAlerttListener=$rootScope.$on("newAlert",function(evt,args){


    if(args._assetId==$stateParams.id){

      args._alertConfig=args.alertConfig;
      if( $scope.alertList &&  $scope.alertList.length!=undefined) {
        $scope.alertList.splice(0, 0, args);
        $scope.alertList.splice($scope.alertList.length - 1, 1);
      }
      $scope.$apply();
    }
  });
  var newAlerttListener =$rootScope.$on("updateAlert",function(evt,args){

    args._alertConfig=args.alertConfig;
    var filtred =$filter('filter')($scope.alertList,{_id:args._id},true);

    if( filtred &&  filtred.length>0) {
      {
        filtred[0].status = 0;
        filtred[0].end_dt=args.end_dt

      }
    }
    $scope.$apply();

  });
  var rightMenuListener =$rootScope.$on("alertConfigMenu:open", function(evt,asset){

    $state.go("app.temperature.details.alertcfg",{id:$stateParams.id,assetId:asset._id});
  })

  $scope.$on("$destroy",function() {

      if(newMsgListener) {
        newMsgListener();
      }
      if(newAlerttListener) {
        newAlerttListener();
      }
      if(updateAlerttListener) {
        updateAlerttListener();
      }
      if(rightMenuListener){
        rightMenuListener();
      }
    }
  )




  $scope.getStats = function(res){
    if (res && res.length > 0) {
      var sensors_max = undefined;
      var sensors_min = undefined;
      var sensors_avg = undefined;
      var sensors_count = 0;
      var sensors_total = 0;
      var sensors_avgData = [];
      var sensors_minData = [];
      var sensors_maxData = [];
      var xaxis = [];

      res.forEach(function (value, index) {
        var _date = moment({
          y: value._id.y,
          M: value._id.m - 1,
          d: value._id.d,
          h: value._id.h,
          m: value._id.mm,
          s: value._id.ss
        });//.add(moment().utcOffset(),'minute');
        //  console.dir(_date)
        $scope.series = [];

        for(var i = 0; i< $scope.asset.sensors.length; i++) {
          sensors_avg = 's'+i+'_avg';
          sensors_min = 's'+i+ '_min';
          sensors_max = 's'+i+'_max';


          if (value[sensors_avg] && value[sensors_min] && value[sensors_max]) {
            if(!sensors_avgData[i])
              sensors_avgData[i] = [];
              sensors_avgData[i].push([_date.valueOf(), parseFloat(value[sensors_avg].toFixed(2))]);
            if(!sensors_minData[i])
              sensors_minData[i] = [];
              sensors_minData[i].push([_date.valueOf(), parseFloat(value[sensors_min].toFixed(2)), parseFloat(value[sensors_max].toFixed(2))]);
            if(!sensors_maxData[i])
              sensors_maxData[i] = [];
              sensors_maxData[i].push([_date.valueOf(), value[sensors_max]]);
          }

        }

          sensors_count++;
          sensors_total += value[sensors_avg];
      });
      for(var i = 0; i< $scope.asset.sensors.length; i++) {
        $scope.series.push(
          {name: $scope.asset.sensors[i].name, data: sensors_minData[i], type: 'arearange'},{
          name: 'avg ' +$scope.asset.sensors[i].name ,
            data: sensors_avgData[i],
            type: 'line',
            linkedTo: ':previous'
        });
      }
      $scope.stats = {};

      for(var i = 0; i< $scope.asset.sensors.length; i++) {

        var tempAvg = sensors_avgData[i].map( function(val){
          if(val.length>0)
          return val[1]
        });
        var tempMin = sensors_minData[i].map( function(val){
          if(val.length>0)
          return val[1]
        });
        var tempMax = sensors_maxData[i].map( function(val){
          if(val.length>0)
          return val[1]
        });
        if($scope.series && $scope.series.length>0){
          $scope.stats['s' + i] = {avg: calculteAvg(tempAvg), max: calculteMax(tempMax), min: calculteMin(tempMin)}
        }else{
          $scope.stats['s' + i] = {avg: 0, max: 0, min: 0}
        }
      }
      if($scope.series && $scope.series.length>0){
        $scope.showChart = true;
        heatChartSrv.updateLineChart("tempcart",
          $scope.series,
          xaxis,
          "",
          "Température en °C",
          "<i class='fa fa-tachometer '></i>  Historique de Température",
          "Période de : " + (moment($scope.appData.datePicker.date.startDate).format("YYYY-MM-DD HH:mm:ss") + " <span class='fa fa-arrow-right'></span> " + (moment($scope.appData.datePicker.date.endDate).format("YYYY-MM-DD  HH:mm:ss")))
          , $scope.chartZoomUpdate
        )
      }else{
        $scope.showChart = false;
      }
    }
  };

  function calculteMin(arrayOfVal){
    return Math.min.apply(null,arrayOfVal);
  }
  function calculteMax(arrayOfVal){
    return Math.max.apply(null,arrayOfVal);
  }
  function calculteAvg(arrayOfVal){
    Array.prototype.sum = function(arr) {
      return this.reduce(function(a,b){return a+b;}) ;
    };
    return arrayOfVal.sum()/arrayOfVal.length;
  }


})

/**
 * Created by Kais CHAIBI on 21/02/2017.
 */
angular.module("app.services").factory('deviceMsgQueryTools',[function () {



  var service={};

  service.getDailyQuery=function(assetQuery,startDate,endDate,options){
    var query =[];
    query.push( {
      "$match": {
        "_asset": assetQuery,
        gps_dt: {
          $gte: startDate.toDate(),
          $lte: endDate.toDate()
        }
      }
    });

    var project={
      "$project": {
        "date1": "$gps_dt",
        "date": {
          "y": {"$year":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
          "m": {"$month":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
          "d": {"$dayOfMonth":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}}

        }
      }
    }
    var group ={
      "$group": {
        "_id": {
          "y": "$date.y",
          "m": "$date.m",
          "d": "$date.d"



        },

        "count": {"$sum": 1}
      }
    }
    if (options.sources){

      options.sources.forEach(function(val,index){
        project.$project[val.as]="$"+val.src;
        group.$group[val.as+'_max']={"$max": "$"+val.as},
          group.$group[val.as+'_min']={"$min": "$"+val.as}
        group.$group[val.as+'_avg']={"$avg": "$"+val.as}
      })

    }
    query.push(project)
    query.push(group);
    query.push({"$sort": {"_id": 1}})


    return query;
  }
  service.getHourlyQuery=function(NbHours,assetQuery,startDate,endDate,options){
    console.log("getHourlyQuery",NbHours,startDate.toDate().toUTCString(),endDate.toDate().toUTCString())
    if(!NbHours || NbHours==1) {
      var query =[];
      query.push( {
        "$match": {
          "_asset": assetQuery,
          gps_dt: {
            $gte: startDate.toDate(),
            $lte: endDate.toDate()
          }
        }
      });

      var project={
        "$project": {
          "date1": "$gps_dt",
          "date": {
            "y": {"$year":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
            "m": {"$month":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
            "d": {"$dayOfMonth":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
            "h": {"$hour":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}}
          }
        }
      }
      var group ={
        "$group": {
          "_id": {
            "y": "$date.y",
            "m": "$date.m",
            "d": "$date.d",
            "h": "$date.h"



          },

          "count": {"$sum": 1}
        }
      }
      if (options.sources){

        options.sources.forEach(function(val,index){
          project.$project[val.as]="$"+val.src;
          group.$group[val.as+'_max']={"$max": "$"+val.as},
            group.$group[val.as+'_min']={"$min": "$"+val.as}
          group.$group[val.as+'_avg']={"$avg": "$"+val.as}
        })

      }
      query.push(project)
      query.push(group);
      query.push({"$sort": {"_id": 1}})


      return query;

    }else{
      var query =[];
      query.push( {
        "$match": {
          "_asset": assetQuery,
          gps_dt: {
            $gte: startDate.toDate(),
            $lte: endDate.toDate()
          }
        }
      });

      var project={
        "$project": {
          "date1": "$gps_dt",
          "date": {
            "y": {"$year":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
            "m": {"$month":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
            "d": {"$dayOfMonth":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
            "h": {"$floor": {$divide:[{"$hour":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}} ,NbHours]}}

          }
        }
      }
      var group ={
        "$group": {
          "_id": {
            "y": "$date.y",
            "m": "$date.m",
            "d": "$date.d",
            "h": "$date.h"


          },

          "count": {"$sum": 1}
        }
      }
      if (options.sources){

        options.sources.forEach(function(val,index){
          project.$project[val.as]="$"+val.src;
          group.$group[val.as+'_max']={"$max": "$"+val.as},
            group.$group[val.as+'_min']={"$min": "$"+val.as}
          group.$group [val.as+'_avg']={"$avg": "$"+val.as}
        })

      }
      query.push(project)
      query.push(group);

      var project2= {
        "$project": {
          "_id": {
            "y": "$_id.y",
            "m": "$_id.m",
            "d": "$_id.d",
            "h": {$add: [{"$multiply": ["$_id.h", NbHours]}, Math.floor(NbHours / 2)]}, // to be middle period
            "mm": {$literal: Math.floor(((NbHours % 2 ) * 60) / 2)}  //  convert rest to minute
          }
        }
      }
      if (options.sources){

        options.sources.forEach(function(val,index){
          //project2.$project[val.as]="$"+val.src;
          project2.$project[val.as+'_max']="$"+val.as+'_max',
            project2.$project[val.as+'_min']='$'+val.as+'_min'
          project2.$project[val.as+'_avg']='$'+val.as+"_avg"
        })

      }

      query.push(project2);
      query.push({"$sort": {"_id": 1}})
      return query;

    }
  }
  service.getMinuteQuery=function(nbMinute,assetQuery,startDate,endDate,options){
    console.log("getMinuteQuery",startDate.toDate().toUTCString(),endDate.toDate().toUTCString())
    if(!nbMinute || nbMinute==1) {

      var query =[];
      query.push( {
        "$match": {
          "_asset": assetQuery,
          gps_dt: {
            $gte: startDate.toDate(),
            $lte: endDate.toDate()
          }
        }
      });

      var project={
        "$project": {
          "date1": "$gps_dt",
          "date": {
            "y": {"$year":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
            "m": {"$month":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
            "d": {"$dayOfMonth":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
            "h": {"$hour":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}}  ,
            "mm": {"$minute":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}}
          }
        }
      }
      var group ={
        "$group": {
          "_id": {
            "y": "$date.y",
            "m": "$date.m",
            "d": "$date.d",
            "h": "$date.h",
            "mm": "$date.mm"


          },

          "count": {"$sum": 1}
        }
      }
      if (options.sources){

        options.sources.forEach(function(val,index){
          project.$project[val.as]="$"+val.src;
          group.$group[val.as+'_max']={"$max": "$"+val.as},
            group.$group[val.as+'_min']={"$min": "$"+val.as}
          group.$group[val.as+'_avg']={"$avg": "$"+val.as}
        })

      }
      query.push(project)
      query.push(group);
      query.push({"$sort": {"_id": 1}})


      return query;

    }else{

      var query =[];
      query.push( {
        "$match": {
          "_asset": assetQuery,
          gps_dt: {
            $gte: startDate.toDate(),
            $lte: endDate.toDate()
          }
        }
      });

      var project={
        "$project": {
          "date1": "$gps_dt",
          "date": {
            "y": {"$year":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
            "m": {"$month":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
            "d": {"$dayOfMonth":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
            "h": {"$hour":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}}  ,
            "mm": {"$floor": {$divide:[{"$minute":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}},nbMinute]}}
          }
        }
      }
      var group ={
        "$group": {
          "_id": {
            "y": "$date.y",
            "m": "$date.m",
            "d": "$date.d",
            "h": "$date.h",
            "mm": "$date.mm"

          },

          "count": {"$sum": 1}
        }
      }
      if (options.sources){
        options.sources.forEach(function(val,index){
          project.$project[val.as]="$"+val.src;
          group.$group[val.as+'_max']={"$max": "$"+val.as},
          group.$group[val.as+'_min']={"$min": "$"+val.as}
          group.$group[val.as+'_avg']={"$avg": "$"+val.as}
        })

      }
      query.push(project)
      query.push(group);

      var project2= {
        "$project": {
          "_id": {
            "y": "$_id.y",
            "m": "$_id.m",
            "d": "$_id.d",
            "h": "$_id.h", // to be middle period
            "mm": {$add:[{"$multiply": ["$_id.mm", nbMinute]},Math.floor(nbMinute/2)]}, // to be middle period
            "ss":    {$literal: Math.floor(((nbMinute  % 2) * 60) / 2  )}//  convert rest to minute
          }
        }
      }
      if (options.sources){
        options.sources.forEach(function(val,index){
          //project2.$project[val.as]="$"+val.src;
          project2.$project[val.as+'_max']='$'+val.as+"_max"
          project2.$project[val.as+'_min']='$'+val.as+"_min"
          project2.$project[val.as+'_avg']='$'+val.as+"_avg"
        })
      }

      query.push(project2);
      query.push({"$sort": {"_id": 1}})
      return query;

    }
  }
  service.getSecondsQuery=function(assetQuery,startDate,endDate,options){


    var query=[

      {
        "$match": {
          "_asset": assetQuery,
          gps_dt: {
            $gte: startDate.toDate(),
            $lte: endDate.toDate()
          }
        }
      },

      {
        "$project": {
          "date1": "$gps_dt",

          "_id": {
            "y": {"$year":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
            "m": {"$month":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
            "d": {"$dayOfMonth":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
            "h": {"$hour":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}}  ,
            "mm":  {"$minute":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}},
            "ss":{"$second":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}}   ,
            "ms": {"$millisecond":{"$add":["$gps_dt",moment().utcOffset()*60*1000]}}
          }
        }
      },

      {"$sort": {"_id": 1}}


    ]
    if (options.sources){
      options.sources.forEach(function(val,index){

        query[1].$project[val.as+'_max']='$'+val.src;
        query[1].$project[val.as+'_min']='$'+val.src;
        query[1].$project[val.as+'_avg']='$'+val.src;
      })

    }

    return  query;

  }
  service.BuildQueryByPeriod=function(assetQuery,startDate,endDate,options){
    var startDate=moment(startDate)
    var endDate=moment(endDate)


    var days=endDate.diff(startDate, 'days');
    var hours =endDate.diff(startDate, 'hours');
    var minute =endDate.diff(startDate, 'minutes');
    var seconds =endDate.diff(startDate, 'seconds');
    if(days>7){
      return this.getDailyQuery(assetQuery,startDate,endDate,options);
    }
    if(days>3){
      return this.getHourlyQuery(6,assetQuery,startDate,endDate,options);
    }
    else if(hours>24*3){
      return this.getHourlyQuery(0,assetQuery,startDate,endDate,options);
    }
    else if(hours>=24){
      return this.getMinuteQuery(15,assetQuery,startDate,endDate,options);
    }
    else if(hours>12){
      return this.getMinuteQuery(5,assetQuery,startDate,endDate,options);
    }else  {
      return this.getSecondsQuery(assetQuery,startDate,endDate,options);
    }

  }
  return service;
}]);

/**
 * Project: fleetWeb
 * Created by  NGI team.
 */

angular.module("app.services").factory('userSrv',['$http','$rootScope','dataSrv','$window', function ($http,$rootScope,dataSrv,$window) {

    var service={};

    service.getUsersList=function(searchQuery,limit,page,success,error){
            var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/user/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){

            success(response.data);
          }
          else if(response && response.data && response.data.success===false) {

            error(response.data.error);
          }else{
            error(response);
          }


        },function(data, status, headers, config) {
          error(data)

        });
    }
    service.getUsersAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/user/agg?limit='+l+'&page='+p,agg).then(function(response) {
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){

            success(response.data);
          }
          else if(response && response.data && response.data.success===false) {

            error(response.data.error);
          }else{
            error(response);
          }


        },function(data, status, headers, config) {
          error(data)

        });
    }


    service.getUserById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/user/'+id).then(function(response) {
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){

            success(response.data);
          }
          else if(response && response.data && response.data.success===false) {

            error(response.data.error);
          }else{
            error(response);
          }


        },function(data, status, headers, config) {
          error(data)

        });
    }
    service.deleteUserById=function(id,success,error){
        $http.delete($rootScope.config.API_URI+'/user/'+id).then(function(response) {
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){

            success(response.data);
          }
          else if(response && response.data && response.data.success===false) {

            error(response.data.error);
          }else{
            error(response);
          }


        },function(data, status, headers, config) {
          error(data)

        });
    }

    service.updateUserById=function(id,user,success,error){
        $http.put($rootScope.config.API_URI+'/user/'+id,user).then(function(response) {
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){

            success(response.data);
          }
          else if(response && response.data && response.data.success===false) {

            error(response.data.error);
          }else{
            error(response);
          }


        },function(data, status, headers, config) {
          error(data)

        });
    }
    service.add=function(user,success,error){
        $http.post($rootScope.config.API_URI+'/user/',user).then(function(response) {
          console.log('response',response)
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){



            success(response.data);
          }
          else if(response && response.data && response.data.success===false) {

            error(response.data.error);
          }else{
            error(response);
          }


        },function(data, status, headers, config) {
          error(data)

        });
    }
    return service;

}]);

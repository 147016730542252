/**
 * Created by Hamdi on 28/12/2016.
 */

angular.module("app").controller('alertsCtrl',
    [
        '$scope',
        '$state',
        'dataSrv',
        '$stateParams',
        '$rootScope',
        'ngDialog',
        '$http',
        '$translate',
        '$timeout',


        '$q', 'socket', '$filter', 'Notification', '$timeout', "alertConfigSrv", "alertSrv", "companySrv", "fleetSrv", "assetSrv",
        "$window",
        function ($scope,
                  $state,
                  dataSrv,
                  $stateParams,
                  $rootScope,
                  ngDialog,
                  $http,
                  $translate,
                  $timeout,


                  $q, socket, $filter, Notification, $timeout, alertConfigSrv, alertSrv, companySrv, fleetSrv, assetSrv,
                  $window) {

          $('.sidebar-right').removeClass('sidebar-open');
            $scope.itemsByPage = 15;
            $scope.company = null;
            $scope.alertConfig = {};


            $scope.loadAlert = function (tableState, ctrl) {

                if (!$scope.stCtrl && ctrl) {
                    $scope.stCtrl = ctrl;
                }

                if (!tableState && $scope.stCtrl) {
                    $scope.stCtrl.pipe();
                    return;
                }
                var page;
                var limit = 1;
                var number = $scope.itemsByPage;

                var query = {
                    query: {},
                    options: {
                        populate: ['_company_owner', '_assetId', '_alertConfig'],
                        sortBy: {start_dt: -1}
                    }
                };

                if (tableState.pagination) {
                    var pagination = tableState.pagination;
                    number = pagination.number || $scope.itemsByPage;
                    start = pagination.start / number + 1 || 1;
                    $scope.currentPage = start;

                }

                if (tableState.sort && tableState.sort.predicate) {
                    query.options.sortBy = {};

                    var predicate = tableState.sort.predicate;
                    query.options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
                }

                var requery = {};

                if (tableState.search && tableState.search.predicateObject) {
                    if (tableState.search.predicateObject.company && Object.keys(tableState.search.predicateObject.company).length > 0) {
                        requery["_company_owner"] = tableState.search.predicateObject.company._id;
                    }
                    if (tableState.search.predicateObject.asset) {
                        requery["_assetId"] = tableState.search.predicateObject.asset;
                    }
                    if (tableState.search.predicateObject.typeAlert) {
                        requery["_alertConfig"] = tableState.search.predicateObject.typeAlert;
                    }
                    if (tableState.search.predicateObject.start_dt) {
                        requery["start_dt"] = {$gt: tableState.search.predicateObject.start_dt}
                    }
                    if (tableState.search.predicateObject.end_dt) {
                        requery["end_dt"] = {$lt: tableState.search.predicateObject.end_dt}
                    }
                    query.query = requery;
                }

                alertSrv.getAlertsList(query,number,start,function (data) {
                    if (data && data.success) {
                        $scope.alerts = data.result;
                        tableState.pagination.numberOfPages = Math.ceil(data.total_count / number);
                        tableState.pagination.totalItemCount = data.total_count;
                    } else {
                        console.error(data.error)
                    }
                }, function (data) {
                    console.error(data)
                });


            };




            $scope.changeStatus = function (alert) {
               alertSrv.updateAlertById(alert._id,{status:0},function (data) {
                    if (data && data.success) {
                        Notification("Alerte marqué comme vu");
                        alert.status = 0;
                    }
                }, function (data) {
                    console.error(data.error)
                })
            };

            $scope.select2TypeAlert = {
                allowClear: true,
                id: function (e) {
                    return e
                },
                ajax: {
                    transport: function (params) {
                        params.beforeSend = function (request) {
                            request.setRequestHeader("Authorization", 'Bearer ' + $window.sessionStorage.token);
                        };
                        return $.ajax(params);
                    },
                    url: $rootScope.config.API_URI + '/alertconfig/query?limit=' + 10 + '&page=' + 1,
                    dataType: "json",
                    type: "POST",
                    quietMillis: 500,

                    data: function (term, page) {
                        return {
                            query: {
                                $or: [
                                    {name: {"$regex": term, "$options": "i"}}
                                      ]
                            }
                        };

                    },
                    results: function (data, page) {

                        return {results: data.result};
                    }

                },
                formatResult: formatResult3,
                formatSelection: formatSelection3
            };
            function formatResult3(item) {
                return '<i class="fa fa-exclamation"></i>   ' + item.name;
            }

            function formatSelection3(item) {


                return '<i class="fa fa-exclamation-circle"></i>    ' + item.name;
            }



            $scope.select2Options = {
                allowClear: true,
                id: function (e) {
                    return e
                },
                ajax: {
                    transport: function (params) {
                        params.beforeSend = function (request) {
                            request.setRequestHeader("Authorization", 'Bearer ' + $window.sessionStorage.token);
                        };
                        return $.ajax(params);
                    },
                    url: $rootScope.config.API_URI + '/asset/query?limit=' + 10 + '&page=' + 1,
                    dataType: "json",
                    type: "POST",
                    quietMillis: 500,

                    data: function (term, page) {
                        return {
                            query: {
                                $or: [
                                    {name: {"$regex": term, "$options": "i"}},
                                    {model: {"$regex": term, "$options": "i"}},
                                    {family: {"$regex": term, "$options": "i"}},
                                    {type: {"$regex": term, "$options": "i"}},
                                    {_dev: {"$regex": term, "$options": "i"}}
                                ]
                            }
                        };

                    },
                    results: function (data, page) {

                       return {results: data.result};
                    }

                },
                formatResult: formatResult,
                formatSelection: formatSelection
            };
            function formatResult(item) {
                return '<i class="fa fa-car"></i>   ' + item.name;
            }

            function formatSelection(item) {


                return '<i class="fa fa-car"></i>    ' + item.name;
            }


            $scope.ClientSelect2Options = {
                allowClear: true,
                id: function (e) {
                    return e
                },
                ajax: {
                    transport: function (params) {
                        params.beforeSend = function (request) {
                            request.setRequestHeader("Authorization", 'Bearer ' + $window.sessionStorage.token);
                        };
                        return $.ajax(params);
                    },
                    url: $rootScope.config.API_URI + '/company/query?limit=' + 10 + '&page=' + 1,
                    dataType: "json",
                    type: "POST",
                    quietMillis: 500,

                    data: function (term, page) {
                        return {
                            query: {
                                name: {"$regex": term, "$options": "i"}
                            }
                        };
                    },
                    results: function (data, page) {

                        return {results: data.result};
                    }
                },
                formatResult: formatResult1,
                formatSelection: formatSelection1
            };
            function formatResult1(item) {
                return '<i class="fa fa-building-o"></i>   ' + item.name;
            }

            function formatSelection1(item) {

                return '<i class="fa fa-building"></i>    ' + item.name;
            }

            $scope.loadAlertConfig = function (assetID) {
                var query = {query: {'_assetId': assetID}};
                if (!assetID) {
                    return
                }
                alertConfigSrv.getAlertConfigsList(query, 100, 0, function (res) {
                    if (res.success && res.result)
                        $scope.alertConfgs = res.result;
                }, function (err) {
                })
            }

          $scope.alertDetails=function(alert){

          $state.go("app.temperature.alerts.details",{id:alert._id});
          }
            $scope.deleteAlert = function (alert) {
                ngDialog.openConfirm({
                    template: 'app/commons/dialogs/confirm.dlg.html',
                    overlay: false,
                    showClose: false,
                    controller: ['$scope', function ($scope) {
                        // controller logic
                        if (alert._alertConfig != null) {

                        $scope.title = "Suppression d'alerte ";
                        $scope.message = "Êtes-vous sure de vouloir supprimer l'alertes : "+alert._alertConfig.name+" ";
                    }
                        else{
                            $scope.title = "Suppression d'alerte"
                            $scope.message =  "Êtes-vous sure de vouloir supprimer l'alerte "

                        }
                    }]
                }).then(function () {
                    alertSrv.deleteAlertById(alert._id, function (data) {

                      Notification("Alert Supprimée");
                        $scope.stCtrl.pipe();
                    }, function (err) {
                      console.error(err);
                    })
                }, function (reason) {
                });
            }


            function updateAlertListener(data,event){

                var filterList = $filter('filter')($scope.alerts, {_id: data._id}, true);
                if (filterList.length > 0) {
                    filterList [0].status = data.status;
                    filterList[0].end_dt = data.end_dt;
                }



            }
            function newAlertListener(data,event){



                var query = {
                    query: {
                        _id: data._id
                    },
                    options: {
                        populate: ['_company_owner', '_assetId', '_alertConfig']
                    }
                };
                alertSrv.getAlertsList( query,1,0,function (data) {
                    if (data && data.success && data.result && data.result.length>0) {
                        var alert = data.result[0];
                         if ($scope.currentPage == 1) {

                            $scope.alerts.splice(0, 0, alert);
                            $scope.alerts.splice($scope.alerts.length - 1, 1);

                            Notification.error({
                                message: ' Nouvelle alert du vehicule ' + alert._assetId.name + '',
                                delay: 3000});


                        } else if ($scope.currentPage > 1) {

                            Notification.error({
                                message: ' Nouvelle alert du vehicule ' + alert._assetId.name + '',
                                templateUrl: "app/commons/dialogs/notification_alert.tpl.html",
                                delay: 20000,
                                scope: $scope});

                        }

                    } else {
                        console.log(data.error)
                    }

                }, function (data) {
                    console.log(data)
                });
            }
         var   newAlertCleanListenr=  $rootScope.$on("newAlert",newAlertListener);

          var updateAlertCleanListenr=  $rootScope.$on("updateAlert",  updateAlertListener);



            $scope.clearFieldFilters=function(){
                $scope.company=null;
                $scope.selectedAsset=null;
                $scope.typeAlert=null;
                $scope.end_dt=null;
                $scope.start_dt=null;
                var tableState = $scope.stCtrl.tableState()
                tableState.search.predicateObject = {}
                tableState.pagination.start = 0
                $scope.stCtrl.pipe();
            }


            $scope.$on("$destroy", function() {

              updateAlertCleanListenr();
              newAlertCleanListenr();


            })

        }]);

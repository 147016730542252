/**
 * Created by Mohamed Saad on 28/02/2017.
 */
angular.module("app").controller('usersDetailsCtrl', ['$scope', '$state', 'Notification', '$stateParams', '$rootScope', '$timeout', "userSrv", '$q', '$translate','ngDialog','roleSrv','countrySrv',
    function ($scope, $state, Notification, $stateParams, $rootScope, $timeout, userSrv, $q, $translate,ngDialog,roleSrv,countrySrv) {

      $scope._ADMIN_EDIT_ADD = {}
      $scope.countryList = countrySrv.getCountryList();
      $scope.moment=moment;

      $scope.loadUserDetails=function(){
        var query={query:{_id:$stateParams.id},options: {
          populate: ['_company_owner']
        }};
        userSrv.getUsersList(query,1000 , 0 , function(result){
          $scope.user=result.result[0];
          console.log('userrrrr',$scope.user)
        }, function(err){
          if(err && err.status==-1){
            Notification.error("Problème de connexion Internet");
          }
          console.error(err)
        })
      };


      $scope.loadUserDetails();
      if( !$( ".sidebar-right" ).hasClass( "sidebar-open" ))
        $( ".sidebar-right" ).addClass( "sidebar-open" )
      $scope.cancel=function(){
        if($state.current.name=="app.temperature.admin.users.details"){
          $( ".sidebar-right" ).removeClass( "sidebar-open" )

          setTimeout(function(){
            $state.go("app.temperature.admin.users",{id:$stateParams.id});
          },100)


        }else  {
          $( ".sidebar-right" ).removeClass( "sidebar-open" )
          setTimeout(function(){
            $state.go("app.temperature.admin.users",{id:$stateParams.id});
          },100)
        }
      };


      $scope.loadRole=function(roleId){
        if(roleId && roleId.length>0){
          roleSrv.getRoleById(roleId, function(res){

            $scope.userRole={};
            console.log(res);
            $scope.userRole.permissions= res.result.permissions.map(function(perm){
              var crud=   perm.substring(0, perm.indexOf("_"));
              var  res = perm.substring(perm.indexOf("_")+1, perm.length);
              var accessType= res.substring(0,res.indexOf("_"));
              res = perm.substring(perm.indexOf("_")+1, perm.length);
              var modelName = res.substring(res.indexOf("_")+1, res.length);
              return {
                model:modelName,
                r:(crud.indexOf("R")>=0),
                c:(crud.indexOf("C")>=0),
                u:(crud.indexOf("U")>=0),
                d:(crud.indexOf("D")>=0),
                accessType:accessType
              }
            });
            moduleModelPerm= $filter('filter')($scope.userRole.permissions,{model:'MODULE'},true);
            if(moduleModelPerm && moduleModelPerm.length>0 && (moduleModelPerm[0].accessType!='U' && moduleModelPerm[0].accessType!='X')){
              $scope.disableModuleMgt=true;
              $scope.COMPANY_OR_USER= 'activeToCompany';
            }else{
              $scope.disableModuleMgt=false;
              $scope.COMPANY_OR_USER= 'activeToUser';
            }
          }, function(err){


          })
        }
      };


      /*add user*/
      $scope.addUser = function () {
        if ($scope._ADMIN_EDIT_ADD.password) {
          $scope.user.password = $scope._ADMIN_EDIT_ADD.password
        }
        userSrv.add($scope.user, function (data) {
          console.log('data',data)
          if (data.success) {
            Notification($translate.instant("USER_ADDED_NOTIFICATION",{firstName:$scope.user.first_name,lastName:$scope.user.last_name}));
            $scope.cancel();
            $rootScope.loadUsers();
          }
        }, function (err) {

        })

      };

      /*update user*/
      $scope.updateUser = function () {
        if ($scope._ADMIN_EDIT_ADD.password && $scope._ADMIN_EDIT_ADD.password != $scope._ADMIN_EDIT_ADD.confirm_password) {
          ngDialog.openConfirm({
            template: 'scripts/commons/dialog/error.dlg.html',
            overlay: false,
            showClose: false,
            controller: ['$scope', function ($scope) {
              // controller logic
              $scope.title = $translate.instant("CHECK_YOUR_PASSWORD_TITLE");
              $scope.message = $translate.instant("CHECK_YOUR_PASSWORD_MESSAGE");
            }]
          });
          return;
        }


        if ($scope._ADMIN_EDIT_ADD.password) {
          $scope.user.password = $scope._ADMIN_EDIT_ADD.password
        }
        userSrv.updateUserById($stateParams.id, $scope.user, function (data) {
          if (data && data.success) {
            Notification($translate.instant("USER_UPDATE_NOTIFICATION",{firstName:$scope.user.first_name,lastName:$scope.user.last_name}));
            $scope.cancel();
            $rootScope.loadUsers();
          }

        }, function (error) {
          if (error) {
            console.log(error);
          }
        })
      }

    }]);

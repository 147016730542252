/**
 * Created by ch on 27/01/2017.
 */

angular.module("app.services").factory('heatChartSrv',['$http','$rootScope','dataSrv','$window', function ($http,$rootScope,dataSrv,$window) {

    var service={};

    service.updateGauge=function(id,data,label,min,max,unit){

        var gaugeOptions = {

            chart: {
                type: 'solidgauge'
            },

            title: null,

            pane: {
                center: ['50%', '85%'],
                size: '140%',
                startAngle: -90,
                endAngle: 90,
                background: {
                    backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc'
                }
            },

            tooltip: {
                enabled: false
            }, credits: {
                enabled: false
            },

            // the value axis
            yAxis: {
                stops: [
                    [0.1, '#55BF3B'], // green
                    [0.5, '#DDDF0D'], // yellow
                    [0.9, '#DF5353'] // red
                ],
                lineWidth: 0,
                minorTickInterval: null,
                tickAmount: 2,
                title: {
                    y: -70
                },
                labels: {
                    y: 16
                }
            },

            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: 5,
                        borderWidth: 0,
                        useHTML: true
                    }
                }
            }
        };


    Highcharts.chart(id, Highcharts.merge(gaugeOptions, {
        yAxis: {
            min: min,
            max: max,
            title: {
                text: label
            }
        },

        series: [{
            name: label,
            data: [data],
            dataLabels: {
                format: '<div style="text-align:center"><span style="font-size:25px;color:' +
                ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black') + '">{y:.1f}</span><br/>' +
                '<span style="font-size:12px;color:silver">'+unit+'</span></div>'
            },
            tooltip: {
                valueSuffix: 'revolutions/min'
            }
        }]

    }));
    };

    service.updateLineChart=function(id,data,xaxis,yaxisLabel,label,title,periodLabel,chartZoomUpdate){

      var  series=data;
         var charOptions={
            chart: {


                    zoomType: 'x',
              events: {
                selection: function(event){

                  var self = this;

                  if(event &&    event. xAxis && event. xAxis[0]) {
                   // console.log(event.xAxis[0].max, event.xAxis[0].min);



                    chartZoomUpdate(event.xAxis[0].min, event.xAxis[0].max, function (data) {


                      self.series.forEach(function(val){
                        data.forEach(function(dataVal){
                          if(val.name==dataVal.name){
                             val.setData(dataVal.data,true);
                          }
                        })
                      }) ;
                     // self.redraw();
                    })
                  }else{

                    chartZoomUpdate(null, null, function (data) {

                      console.log('reset',data)
                      self.series.forEach(function(val){
                        data.forEach(function(dataVal){
                          if(val.name==dataVal.name){
                            val.setData(dataVal.data,true);
                          }
                        })

                      }) ;
                     // self.redraw();
                    })
                  }



                }
              }

            },
            title: {
                text: title,
                useHTML:true
            },
            subtitle: {
                text: periodLabel,
                useHTML:true
            },
            xAxis: {
                //categories: xaxis
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    month: '%e. %b',
                    year: '%b'
                },
                title: {
                    text: 'Date'
                },


            },
             yAxis:{
                 title: {
                     text: yaxisLabel
                 }
             },
             tooltip: {
                 crosshairs: [true],
                 /*formatter: function() {
                     return  '<b>' + this.series.name +'</b><br/>' +
                        "Date :"+ moment(this.x).format("YYYY-MM-DD")+"<b> "+moment(this.x).format("HH:mm:ss")+'</b><br/>'+
                     "Température :" + ', '+"<b> " + this.y +'</b>'+ ' °C';
                 },*/
               shared: true,
                 valueSuffix: '°C'
             }
            ,
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: false
                    },
                    enableMouseTracking: true
                }
            },
             credits: {
                 enabled: false
             },
            series: data
        };
      //console.log(charOptions);

        Highcharts.chart(id,charOptions);

    }

return  service;
}])

/**
 * Created by Kais CHAIBI on 02/02/2017.
 */
angular.module("app").controller('mainCtrl', function ($scope, $interval, $rootScope,CONFIG,assetSrv,$window,$state,socket,Notification) {

  $scope.appData={};
  $scope.logout=function(){
   delete   $window.sessionStorage.token;
    delete   $window.sessionStorage.SESSION;
    $state.go("login");
    socket.closeListner();
  }
  $scope.dpOptions = {
    applyClass: 'btn-green',
    locale: {
      applyLabel: "valider",
      fromLabel: "A partir de",
      format: "YYYY-MM-DD", //will give you 2017-01-06
      //format: "D-MMM-YY", //will give you 6-Jan-17
      //format: "D-MMMM-YY", //will give you 6-January-17
      toLabel: "à",
      cancelLabel: 'Annuler',
      customRangeLabel: 'Personnalisé'
    },
    ranges: {
      "Aujourd'hui": [moment().startOf("day"), moment().endOf('day')],
      "Hier": [moment().subtract(1, 'days').startOf("day"), moment().subtract(1, 'days').endOf('day')],
      'Cette semaine': [moment().startOf('week').startOf("day"), moment().endOf('day')],
      'la semaine dernière': [moment().subtract(7, 'days').startOf('week').startOf("day"), moment().subtract(7, 'days').endOf('week').endOf('day')],
      'Ce mois': [moment().subtract(29, 'days').startOf("day"), moment().endOf('day')],
      'Mois dernier': [moment().startOf('month').startOf("day"), moment().endOf('month').endOf('day')],
      'Les trois mois dernier': [moment().subtract(3, 'month').startOf('month').startOf("day"), moment().endOf('day')]
    }
  }


  if($window.sessionStorage.appData){
    try{
     // console.log($window.sessionStorage.appData)
      $scope.appData=JSON.parse($window.sessionStorage.appData);
      delete $scope.appData.assets
     // console.log($scope.appData);
    }catch(e){
      console.log(e);
      //$scope.appData={}
    }

  }



   $rootScope.$on("sessionExpired", function(evt,error){

    // console.log(error);
     Notification.error({message:"Session expirée",replaceMessage: true});
      $state.go('login')


  })
});

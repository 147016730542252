/**
 * Project: fleetWeb
 * Created by  NGI team.
 */

angular.module("app.services").factory('assetSrv',['$http','$rootScope','dataSrv','$window', function ($http,$rootScope,dataSrv,$window) {

    var service={};

    service.getAssetsList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/asset/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else if(response && response.data && response.data.success===false) {

                error(response.data.error);
            }else{
              error(response);
            }



        } ,function(data, status, headers, config) {
            error(data)

        });
    }
    service.getAssetsAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/asset/agg?limit='+l+'&page='+p,agg).success(function(data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available
            if (data && data.success){

                success(data);
            }
            else{

                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }


    service.getAssetById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/asset/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available


            if (response &&  response.data &&  response.data.success){

                success( response.data);
            }
            else{

                error( response);
            }



        },function(data, status, headers, config) {
            error(data)

        });
    }
    service.deleteAssetById=function(id,success,error){
        $http["delete"]($rootScope.config.API_URI+'/asset/'+id).then(function(response) {
          console.log('response',response)
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){



            success(response.data);
          }
          else if(response && response.data && response.data.success===false) {

            error(response.data.error);
          }else{
            error(response);
          }


        },function(data, status, headers, config) {
          error(data)

        });
    }

    service.updateAssetById=function(id,asset,success,error){
      $http.put($rootScope.config.API_URI+'/asset/'+id,asset).then(function(response) {
        console.log('response',response)
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){



            success(response.data);
          }
          else if(response && response.data && response.data.success===false) {

            error(response.data.error);
          }else{
            error(response);
          }


        },function(data, status, headers, config) {
          error(data)

        });
    }
    service.add=function(asset,success,error){
        $http.post($rootScope.config.API_URI+'/asset/',asset).then(function(response) {
          console.log('response',response)
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){



            success(response.data);
          }
          else if(response && response.data && response.data.success===false) {

            error(response.data.error);
          }else{
            error(response);
          }


        },function(data, status, headers, config) {
          error(data)

        });
    }
    return service;

}]);

/**
 * Project: fleetWeb
 * Created by Mohamed Saad 21/12/2016.
 */
angular.module("app").directive('alertsDirective', ['$rootScope','$state','leafletData','$http','Notification','$translate','socket','$filter', function($rootScope,$state,leafletData,$http,Notification,$translate,socket,$filter)  {
    return {
        scope:{
            asset:'@'
        },
        templateUrl: 'scripts/commons/directives/alerts/alerts.tpl.html',
        controller:['$scope', function (scope) {
            scope.data={};
            scope.itemsByPage=5;

            scope.initLayout = function () {
                angular.element("#container").layout();
                angular.element(".ui-layout-toggler").on("click", function () {
                    leafletData.getMap('maprealtime').then(
                        function (map) {
                            setTimeout(function () {
                                map.invalidateSize()
                            }, 1000);
                        }
                    );
                });
            };


            $rootScope.$on('alertlist', function (event, asset) {
                if (scope.selectedAsset != asset) {
                    scope.selectedAsset = asset[0]._company_owner;
                    scope.loadAlert();
                }
            });

            scope.loadAlert = function (tableState,ctrl) {
                if ( !scope.stCtrl && ctrl )
                {
                    scope.stCtrl = ctrl;
                }

                if( !tableState && scope.stCtrl ) {
                    scope.stCtrl.pipe();
                    return;
                }
                var page=1;
                var limit=10;
                var query = {
                    query: {
                        _company_owner: scope.selectedAsset
                    },
                    options:{
                        populate:['_alertConfig','_company_owner','_assetId'],
                        sortBy:{start_dt:-1}
                    }
                };

                if (tableState.pagination) {
                    var pagination = tableState.pagination;
                    number =  pagination.number ||  scope.itemsByPage;
                    start = pagination.start / number + 1 || 1;
                }
                if (tableState.sort && tableState.sort.predicate) {
                    query.options.sortBy = {};

                    var predicate = tableState.sort.predicate;
                    query.options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
                }

                var requery = {};
                if (tableState.search && tableState.search.predicateObject) {
                    if (tableState.search.predicateObject.asset) {
                        requery["asset"] = {"$regex": tableState.search.predicateObject.asset, "$options": "-i"};
                    }
                    if (tableState.search.predicateObject.type) {
                        requery["type"] = {'$regex': tableState.search.predicateObject.type, $options: '-i'}
                    }
                    if (tableState.search.predicateObject.value) {
                        requery["value"] = {'$regex': tableState.search.predicateObject.value, $options: '-i'}
                    }
                    if (tableState.search.predicateObject.start_dt) {
                        requery["start_dt"] = {$gt:tableState.search.predicateObject.start_dt}
                    }
                    query.query=requery;
                }

                $http.post($rootScope.config.API_URI+'/alert/query?limit='+limit+'&page='+start,query).success(function(data,number) {
                    if (data && data.success) {
                        scope.alerts=data.result;
                        tableState.pagination.numberOfPages =Math.ceil(data.total_count/ number);
                        tableState.pagination.totalItemCount = data.total_count;
                    } else {
                        console.log(data.error)
                    }
                }, function (data) {
                    console.log(data)
                });
            };


            socket.on("newAlert",function(data){
               scope.loadAlert();
               Notification.   error({message: 'Nouvelle alert  '+data.alert_data.over_speed.value +'/'+data.alert_data.over_speed.threshold, delay: 800});
            });

            socket.on("updateAlert",function(data){
               var filterList =  $filter('filter')(scope.alerts, {_id:data._id },true);
                if(filterList.length >= 0){
                    filterList[0].status = data.status;
                    filterList[0].end_dt = data.end_dt;
                }
                Notification.   success({message: 'Update alert  '+data.alert_data.over_speed.value+"/"+data.alert_data.over_speed.threshold, delay: 800});
            });


            scope.showLocation = function (item) {
                if(item && item.loc){
                    scope.alert = item;
                }else{
                    console.log('error')
                }
                scope.$emit('showAlerts',scope.alert);
            };


            scope.changeStatus = function (alert) {
                    $http.put($rootScope.config.API_URI+'/alert/'+alert._id,{status:'0'}).success(function(data) {
                    if(data && data.success){
                        Notification($translate.instant("ALERT_SEEN"));
                        alert.status = 0;
                        scope.labelNew = false;
                    }
                }, function (data) {
                        console.log(data.error)
                })
            };

            }]
    };




    $scope.validateAlert=function(alertCfg){





    }
}])

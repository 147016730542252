/**
 * Project: iaxeed
 * Created by  CHAIBI Kais  on 19/01/2017.
 */

angular.module("app.services").factory('deviceMessageMetricSrv',['$http','$rootScope', function ($http,$rootScope) {

    var service={};

    service.getMonthlyMetricList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;

        $http.post($rootScope.config.API_URI+'/device_msg_monthly/query?limit='+l+'&page='+p,searchQuery).success(function(data, status, headers, config) {
            if (data && data.success){
                success(data);
            }
            else{
                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }
    service.getMonthlyMetricAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/device_msg_monthly/agg?limit='+l+'&page='+p,agg).success(function(data, status, headers, config) {
            if (data && data.success){
                success(data);
            }
            else{
                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }

    service.getDailyMetricList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;

        $http.post($rootScope.config.API_URI+'/device_msg_daily/query?limit='+l+'&page='+p,searchQuery).success(function(data, status, headers, config) {
            if (data && data.success){
                success(data);
            }
            else{
                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }
    service.getDailyMetricAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/device_msg_daily/agg?limit='+l+'&page='+p,agg).then(function(response) {
            if (response && response.data && response.data.success){
                success(response.data);
            }
            else{
                error(response);
            }



        },function(data, status, headers, config) {
            error(data)

        });
    }
    return service;

}]);

/**
 * Project: fleetWeb
 * Created by  NGI team.
 */

angular.module("app.services").factory('deviceSrv',['$http','$rootScope','dataSrv','$window', function ($http,$rootScope,dataSrv,$window) {

    var service={};

    service.getDevicesList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/device/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
          if (response && response.data && response.data.success){

            success(response.data);
          }else if(response && response.data && data.success==false){
            error(response.data)
          }
          else{

            error(response);
          }



        },function(err) {
          error(err)

        });
    }
    service.getDevicesAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/device/agg?limit='+l+'&page='+p,agg).then(function(response) {
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){

            success(response.data);
          }else if(response && response.data && data.success==false){
            error(response.data)
          }
          else{

            error(response);
          }



        },function(err) {
          error(err)

        });
    }


    service.getDeviceById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/device/'+id).then(function(response) {
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){

            success(response.data);
          }else if(response && response.data && data.success==false){
            error(response.data)
          }
          else{

            error(response);
          }



        },function(err) {
          error(err)

        });
    }
    service.deleteDeviceById=function(id,success,error){
        $http["delete"]($rootScope.config.API_URI+'/device/'+id).then(function(response) {
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){

            success(response.data);
          }else if(response && response.data && data.success==false){
            error(response.data)
          }
          else{

            error(response);
          }



        },function(err) {
          error(err)

        });
    }

    service.updateDeviceById=function(id,device,success,error){
        $http.put($rootScope.config.API_URI+'/device/'+id,device).then(function(response) {
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){

            success(response.data);
          }else if(response && response.data && data.success==false){
            error(response.data)
          }
          else{

            error(response);
          }



        },function(err) {
          error(err)

        });
    }
    service.add=function(device,success,error){
        $http.post($rootScope.config.API_URI+'/device/',device).then(function(response) {
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){

            success(response.data);
          }else if(response && response.data && data.success==false){
            error(response.data)
          }
          else{

            error(response);
          }



        },function(err) {
          error(err)

        });
    }
    return service;

}]);

/**
 * Project: fleetWeb
 * Created by  NGI team.
 */

angular.module("app.services").factory('actionLogSrv',['$http','$rootScope','dataSrv','$window', function ($http,$rootScope,dataSrv,$window) {

    var service={};

    service.getActionLogList=function(searchQuery,limit,page,success,error){
            var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/actionLog/query?limit='+l+'&page='+p,searchQuery).success(function(data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available

            if (data && data.success){

                success(data);
            }
            else{

                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }


    service.getActionLogById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/actionLog/'+id).success(function(data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available

            if (data && data.success){

                success(data);
            }
            else{

                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }
    service.deleteActionLogById=function(id,success,error){
        $http.delete($rootScope.config.API_URI+'/actionLog/'+id).success(function(data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available

            if (data && data.success){

                success(data);
            }
            else{

                error(data);
            }



        }).error(function(data, status, headers, config) {

            error(data)

        });
    }

    service.updateActionLogById=function(id,actionlog,success,error){
        $http.put($rootScope.config.API_URI+'/actionLog/'+id,actionlog).success(function(data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available

            if (data && data.success){

                success(data);
            }
            else{

                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }
    service.add=function(actionlog,success,error){
        $http.post($rootScope.config.API_URI+'/actionlog/',actionlog).success(function(data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available

            if (data && data.success){

                success(data);
            }
            else{

                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }

    return service;

}]);

/**
 * Created by Hamdi on 01/03/2017.
 */
angular.module("app").controller('deviceAdminCtrl',
  [
    '$scope',
    '$state',
    'deviceSrv',
    '$rootScope',
    '$translate','ngDialog','Notification',"$window",'$stateParams',
    function ($scope,
              $state,
              deviceSrv,
              $rootScope,
              $translate,ngDialog,Notification ,$window,$stateParams) {

      $( ".sidebar-right" ).removeClass( "sidebar-open" )


      $scope.device={};

      $scope.modifierDevice=function(device){
        $rootScope.selectedDevice=device;
        $scope.device._id=device._id;
       // $scope.editMode=true;

        $state.go("app.temperature.admin.device.details",{id:device._id});
      };

      $scope.ajouterDevice=function(){
        $scope.device._id=null;
       // $scope.editMode=false;
        $state.go("app.temperature.admin.device.add");
      };


      $scope._ADMIN_DEVICE= {};
      $scope._ADMIN_DEVICE.currentPage = 1;
      $scope._ADMIN_DEVICE.limit = 5;
      $scope.itemsByPage=5;
      //$scope.count = 0;

      $scope._ADMIN_DEVICE.DeviceSearch = ""

      $scope.clearFieldFilters=function(){
        $scope.company=null;


        var tableState = $scope.stCtrl.tableState()
        tableState.search.predicateObject = {}
        tableState.pagination.start = 0
        $scope.stCtrl.pipe();
      }



      $rootScope.loadDevices = function (tableState, ctrl) {



        if ( !$scope.stCtrl && ctrl )
        {
          $scope.stCtrl = ctrl;
        }

        if( !tableState && $scope.stCtrl ) {
          $scope.stCtrl.pipe();
          return;
        }
        var start = 0;     // This is NOT the page number, but the index of item in the list that you want to use to display the table.
        var number = $scope.itemsByPage;
        var query = {
          options:{populate:
           [
           {
           path: '_company_owner',
           select: 'name'
           }
           ]




          }
        };

        if (tableState.pagination) {
          var pagination = tableState.pagination;
          // start = pagination.start / $scope.itemsByPage + 1 || 1;     // This is NOT the page number, but the index of item in the list that you want to use to display the table.
          number =  pagination.number ||  $scope.itemsByPage;
          start = pagination.start / number + 1 || 1;

        }
        if (tableState.sort && tableState.sort.predicate) {
          query.options.sortBy = {};

          var predicate = tableState.sort.predicate;
          query.options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);


        }

        var requery = {};

        if($scope.device && $scope.device.modelFilter && $scope.device.modelFilter.length>0 ){
          requery["model"] ={'$regex': $scope.device.modelFilter, $options: '-i'}
        }
        if (tableState.search && tableState.search.predicateObject) {
          if (tableState.search.predicateObject._id) {

            requery["_id"] = {'$regex': tableState.search.predicateObject._id, $options: '-i'}

          }

          if (tableState.search.predicateObject.type) {
            requery["type"] = {'$regex': tableState.search.predicateObject.type, $options: '-i'}
          }
          if (tableState.search.predicateObject.imei) {
            requery["imei"] = {'$regex': tableState.search.predicateObject.imei, $options: '-i'}
          }
          if (tableState.search.predicateObject.sim) {
            requery["sim"] = {'$regex': tableState.search.predicateObject.sim, $options: '-i'}
          }
         /* if (tableState.search.predicateObject.serial) {
            requery["serial"] ={'$regex': tableState.search.predicateObject.serial, $options: '-i'}
          }*/
          if (tableState.search.predicateObject.company) {
            requery["_company_owner"] =tableState.search.predicateObject.company
          }
          if (tableState.search.predicateObject.model) {
            requery["model"] ={'$regex': tableState.search.predicateObject.model, $options: '-i'}
          }



        }
        query.query=requery;
        deviceSrv.getDevicesList(query,number, start,function (data) {

          if (data && data.success && data.result) {

          //  console.log(data.result)
            $scope._ADMIN_DEVICE.deviceList = data.result;

            $scope._ADMIN_DEVICE.count = data.total_count
            tableState.pagination.numberOfPages =Math.ceil(data.total_count/ number);
            tableState.pagination.totalItemCount = data.total_count;
          } else {
            console.error(data.error)
          }


        }, function (data) {
          console.log(data)

        })
      }

/*
      $scope.selectPage = function () {
        $scope.loadDevices()
      };
*/

      /*delete device*/
      $scope.deleteDevice=function(device){

        ngDialog.openConfirm({ template: 'app/commons/dialogs/confirm.dlg.html',overlay:false ,showClose:false,controller: ['$scope', function($scope) {
          // controller logic
          $scope.title="suppression d'un appareil";
          $scope.message="Êtes-vous sure de supprimé l'appareil " +device._id+"  ?";
        }] }).then(function (value) {

          deviceSrv.deleteDeviceById(device._id,function(data){
            Notification('appareil supprimé');
            $rootScope.loadDevices();
          },function(err){

          })
        }, function (reason) {

        });
      }


      $scope.ClientSelect2Options = {
        allowClear: true,
        id: function (e) {
          return e
        },
        ajax: {
          transport: function (params) {
            params.beforeSend = function (request) {
              request.setRequestHeader("Authorization", 'Bearer ' + $window.sessionStorage.token);
            };
            return $.ajax(params);
          },
          url: $rootScope.config.API_URI + '/company/query?limit=' + 10 + '&page=' + 1,
          dataType: "json",
          type: "POST",
          quietMillis: 500,

          data: function (term, page) {
            return {
              query: {
                name: {"$regex": term, "$options": "i"}
              }
            };
          },
          results: function (data, page) {

            return {results: data.result};
          }
        },
        formatResult: formatResult,
        formatSelection: formatSelection
      };
      function formatResult(item) {
        //console.log(movie)
        return '<i class="fa fa-building"></i>   ' + item.name;
      }

      function formatSelection(item) {

        return '<i class="fa fa-building-o"></i>    ' + item.name;
      }


    }]);

/**
 * Created by Hamdi on 13/03/2017.
 */


angular.module("app").controller('depotsSensorsCtrl', ['$scope', '$state', 'Notification', '$stateParams', '$rootScope', '$timeout', "assetSrv", '$q', '$translate','ngDialog',
  function ($scope, $state, Notification, $stateParams, $rootScope, $timeout, assetSrv, $q, $translate,ngDialog) {
    $scope.editMode=false;
    $scope.addMode=false;
    $scope.editButton=false;


    $scope.SRCS=[];

    $scope.prepareSRCs=function(item){
      $scope.SRCS=[]

      if (item.rt){

        Object.keys(item.rt).forEach(function(key){
          if (key=='io'){
            Object.keys(item.rt.io).forEach(function(io_key){

              $scope.SRCS.push({name:io_key,src:'io.'+io_key})

            })
          }
        })
      }
    }

   // $scope._ADMIN_EDIT_ADD = {};
    $scope.moment=moment;

    $scope.loadDepotDetails=function(item){
      $scope.sensors = [];

      if(item){
        $scope.asset=item;
        $scope.sensors = angular.copy($scope.asset.sensors);
        $scope.prepareSRCs(item)
      }else {
        var query = {
          query: {_id: $stateParams.id}, options: {
            populate: ['_company_owner']
          }
        };
        assetSrv.getAssetsList(query, 1, 0, function (result) {
          $scope.asset = result.result[0];
          $scope.sensors = angular.copy($scope.asset.sensors);
          $scope.prepareSRCs(result.result[0]);

          console.log('asset', $scope.asset)
        }, function (err) {
          if (err && err.status == -1) {
            Notification.error("Problème de connexion Internet");
          }
          console.error(err)
        })
      }
    };

if($stateParams.id){
  $scope.loadDepotDetails($rootScope.CurrentAsset);
}else{
  $scope.loadDepotDetails();

}

    if( !$( ".sidebar-right" ).hasClass( "sidebar-open" ))
      $( ".sidebar-right" ).addClass( "sidebar-open" )

    $scope.cancel=function(){

      if($state.current.name=="app.temperature.admin.depots.sensors"){
        $( ".sidebar-right" ).removeClass( "sidebar-open" )

        setTimeout(function(){
          $state.go("app.temperature.admin.depots",{id:$stateParams.id});
        },100)

      }else  {
        $( ".sidebar-right" ).removeClass( "sidebar-open" )
        setTimeout(function(){
          $state.go("app.temperature.admin.depots",{id:$stateParams.id});
        },100)
      }
    };


    /*Add asset*/
    $scope.addAsset = function () {

     // $scope.asset.sensors=[{sensorId:moment().valueOf()}]
      assetSrv.add($scope.asset, function (data) {
        if (data && data.success) {
          $scope.cancel();
          $rootScope.loadAssets();
          Notification($translate.instant("ALERT_ASSET_ADDED",{name:$scope.asset.name}));
        }
      }, function (err) {
      })
    };

    /*update asset*/
    $scope.updateAsset = function () {
      assetSrv.updateAssetById($stateParams.id, $scope.asset, function (data) {
        if (data && data.success) {
          $scope.cancel();

          $rootScope.loadAssets();
          Notification($translate.instant("ALERT_ASSET_UPDATED",{name:$scope.asset.name}));
        }
      }, function (error) {
        if (error) {
          console.log(error);
        }
      })
    }


    $scope.AjouterSensor=function(){
      $scope.sensors=[];

     // $scope.editMode=true;
      $scope.addMode=true;
      $scope.editButton=false;

    };

      $scope.addSensor=function(item){
       // var sensors=[];
        $scope.asset.sensors.push({  //sensorId:moment().valueOf(),
                                      name: item.name,
                                      type:'Temperature',
                                     src: item.src
        });

        assetSrv.updateAssetById($stateParams.id,{sensors:$scope.asset.sensors}, function (data) {

          //assetSrv.updateAssetById($stateParams.id,{sensors:sensors}, function (data) {
            if (data && data.success) {

             // $scope.sensors = angular.copy($scope.asset.sensors);
              Notification("Capteur ajouté");

              $timeout(function (){
                $state.reload();
                 });
            }else{
              Notification("Une erreur s'est produite");
            }
          }, function (err) {
          Notification("Une erreur s'est produite");

        })

      $scope.editMode=false;
        $scope.addMode=false;

    };

    $scope.editButtonSensor=function(item){
     // var sensors=[];
      $scope.asset.sensors[$scope.index]=item;

      assetSrv.updateAssetById($stateParams.id, {sensors:$scope.asset.sensors}, function (data) {

        if (data && data.success) {

          // $scope.sensors = angular.copy($scope.asset.sensors);
          Notification("Capteur modifié");

          $timeout(function (){
            $state.reload();
          });

        }else{
          Notification("Une erreur s'est produite");

        }
      }, function (err) {
        Notification("Une erreur s'est produite");

      })

      $scope.editMode=false;
      $scope.addMode=false;

    };




    $scope.cancelEdit=function(){
      $scope.editMode=false;
      $scope.addMode=false;


    }


    $scope.editSensor=function(item,index){
      $scope.index=index;
      $scope.sensors = item;

      $scope.editMode=true;


    }

    $scope.removeSensor=function(index) {
console.log('indx',index)
      if (index!=null || index!=undefined) {
        console.log('indx',index)

        ngDialog.openConfirm({
          template: 'app/commons/dialogs/confirm.dlg.html',
          overlay: false,
          showClose: false,
          controller: ['$scope', function ($scope) {
            // controller logic
            $scope.title = "Suppression d'un capteur";
            $scope.message = "Êtes vous sure de vouloir supprimer ce capteur";
          }]
        }).then(function (value) {

          $scope.asset.sensors.splice(index, 1);//=angular.copy($scope.sensors);
          assetSrv.updateAssetById($stateParams.id, {sensors:$scope.asset.sensors}, function (data) {
            if (data && data.success) {

             $scope.sensors = angular.copy($scope.asset.sensors);
              Notification("Capteur supprimé");


            }
          }, function (err) {

            ngDialog.openConfirm({
              template: 'app/commons/dialogs/error.dlg.html',
              overlay: false,
              showClose: false,
              controller: ['$scope', function ($scope) {
                // controller logic
                $scope.title = "Suppression d'un capteur du Depot";
                $scope.message = "Une erreur s'est produite  ";// + JSON.stringify(err);
              }]
            });

          })
        }, function (reason) {

        });


      }

    }



  }]);

/**
 * Project: iaxeed
 * Created by  Mohamed Saad on 30/12/2016.
 */

angular.module("app.services").factory('dbUtilsSrv',['$http','$rootScope','collectionSrv', function ($http,$rootScope,collectionSrv) {

    var service={};
    var listt=[];


    function getfields(data,prefix) {

        Object.keys(data).forEach(function (key) {

            if (moment(data[key], moment.ISO_8601, true).isValid()) {

                listt.push({
                    name: prefix?prefix+'.'+key:key,
                    type: 'date'
                });
            }
            else if (Array.isArray(data[key])) {
            }
            else if (typeof(data[key]) === 'object') {

                var oldkey=key;
                var newData = data[key];
                if (isNaN(key)) {

                    getfields(newData,prefix?prefix+'.'+oldkey:oldkey)
                }
            }
            else {
                listt.push({

                    name: prefix?prefix+'.'+key:key,
                    type: typeof(data[key])
                });
            }
        })

        return  listt;

    }
    service.getCollectionList=function(success,error){

        $http.get($rootScope.config.API_URI+'/collection').success(function(data, status, headers, config) {
            console.log("Srv " , data);
            if (data && data.success){
                success(data);
            }
            else{
                error(data);
            }

        }).error(function(data, status, headers, config) {
            error(data)

        });
    }
    service.getCollectionFields=function(collectionName,success,error){

        $http.get($rootScope.config.API_URI+'/collection/'+collectionName).success(function(data, status, headers, config) {
            if (data && data.success){
                success(data);
            }
            else{
                error(data);
            }

        }).error(function(data, status, headers, config) {
            error(data)

        });
    }


    service.getCollectionFieldsNames=function(collectionName,firstRow,callBack){

        var query={};
         listt=[];
        if(!firstRow) {
    var firstRow = {};
    var execFunction = collectionSrv.searchCollection(collectionName);
    if (!execFunction) {return}

    execFunction(query, 1, 0, function (data) {
        if (data.success && data.result && data.result.length>0 ) {
           var datas = data.result;
            firstRow = datas[0];
            console.log('first row: ', firstRow);
            var fieldsList =getfields(firstRow);
            return callBack(null,fieldsList);
        }else{
            return callBack('not data found');
        }
    },function(err){
        console.log(err);
        return callBack(err);
    })

        }
    else if(firstRow) {

    console.log('first row: ', firstRow);

    var fieldsList =getfields(firstRow);

            return callBack(null,fieldsList);
}
    };

    return service;

}]);

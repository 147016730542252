/**
 * Created by Kais CHAIBI on 06/02/2017.
 */

angular.module("app").controller('alertStatsCtrl', function ($scope, $interval, $rootScope,CONFIG,assetSrv,alertSrv, fleetSrv,deviceMessageMetricSrv,ngDialog,$state ,Notification) {

   $scope.loadCountStats=function(startDt,endDt){
     var startDate=moment(startDt)
     var endDate=moment(endDt)
     var query={aggregate:[

       {
         "$match": {

           start_dt: {
             $gte: new ISODate(startDate.toDate()).toString(),
             $lte: new ISODate(endDate.toDate()).toString()
           }
         }
       },

       {
         "$project": {
           "date1": "$start_dt",
           "date": {
             "y": {"$year": "$start_dt"},
             "m": {"$month": "$start_dt"},
             "d": {"$dayOfMonth": "$start_dt"}

           }
         }
       },
       {
         "$group": {
           "_id": {
             "y": "$date.y",
             "m": "$date.m",
             "d": "$date.d"

           },
           "count": {"$sum": 1},
           "date": {$first:"$date1"}
         }
       },
       {"$sort": {"date": 1}}


     ]}
     alertSrv.getAlertsAgg(query,10000,0,function(res){


         if(res && res.result){

           var seriesData=res.result.map(function(val){

             var dt=moment(val.date);
             //console.log(val)
         dt .set({hour:12,minute:0,second:0,millisecond:0})
            return   [dt.valueOf(), val.count]

           });
           //console.log(seriesData)
           $scope.renderChart(seriesData)
         }else{
           $scope.renderChart([0,0])

         }
     },
     function(err){
       console.error(err);
       $scope.renderChart([0,0])

     })



  }
  $scope.renderChart=function(data){

    var dailyChartOption= {
      chart: {
        zoomType: 'x'
      },
      title: {
        text: 'Evolution des alertes par jour '
      } ,subtitle: {
        text: 'Mois '+moment().format('MMMM YYYY','fr'),
        x: -20
      }
      ,
      xAxis: {

        //categories: xaxis
        type: 'datetime',
        dateTimeLabelFormats: { // don't display the dummy year
          month: '%e. %b',
          year: '%b'
        },
        title: {
          text: 'Date'
        },

        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Nombre des alertes'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.x:%Y-%m-%d}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f} Alerte(s)</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [{
        name: 'Alertes par jour',color: "rgba(217,229,239,0.8))",
        type: 'line', color: "#153252",
        data: data

      }]
      ,credits: {
        enabled: false
      }
    }
    Highcharts.chart("chart",dailyChartOption);
  }

  $scope.loadConfigCountStats=function(startDt,endDt){
    var startDate=moment(startDt)
    var endDate=moment(endDt)
    var query={aggregate:[

      {
        "$match": {

          start_dt: {
            $gte: new ISODate(startDate.toDate()).toString(),
            $lte: new ISODate(endDate.toDate()).toString()
          }
        }
      },
      { "$lookup": {
        "from": "alert_config",
        "localField": "_alertConfig",
        "foreignField": "_id",
        "as": "_alertConfig"
      }},
       {
        "$project": {
          "name": {$arrayElemAt: [ "$_alertConfig.name", 0 ]},
          "_alertConfigId":{$arrayElemAt: [ "$_alertConfig._id", 0 ]}


        }
      },

        {
        "$group": {
          "_id": "$_alertConfigId",
          "count": {"$sum": 1},
          "name": {$first:"$name"}
        }
      },


    ]}

    var Emptydata = [{name:'pas de données',
      y:0.01}];

    alertSrv.getAlertsAgg(query,10000,0,function(res){


        if(res && res.result){

          //console.log(' res.result', res.result)

          var seriesData=res.result.map(function(val){

            var dt=moment(val.date);
            //console.log(val)
            dt .set({hour:12,minute:0,second:0,millisecond:0})
            return   {name:val.name,y:val.count}

          });

       //   console.log(seriesData)
          $scope.renderGroupedByConfigChart(seriesData)
        }else{


          $scope.renderGroupedByConfigChart(Emptydata)

        }
      },
      function(err){
        console.error(err);
        $scope.renderGroupedByConfigChart(Emptydata)

      })



  }
  $scope.renderGroupedByConfigChart=function(data){

    var dailyChartOption= {
      chart: {

      },
      title: {
        text: 'Répartition des alertes selon leur type'
      },subtitle: {
        text: 'Mois '+moment().format('MMMM YYYY','fr'),
        x: -20
      }
      ,
      xAxis: {

        //categories: xaxis
        type: 'datetime',
        dateTimeLabelFormats: { // don't display the dummy year
          month: '%e. %b',
          year: '%b'
        },
        title: {
          text: 'Date'
        },

        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Nombre des alertes'
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}% </b><br> Nbre d\'alertes :<b> {point.y:.1f} alerte(s)</b>'
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [{
        name: "Répartition des alertes selon leur type",
        type: 'pie',
        data: data

      }]
      ,credits: {
        enabled: false
      }
    }
    Highcharts.chart("chart2",dailyChartOption);
  }

  $scope.loadCountStats(moment().startOf('month'),moment());
  $scope.loadConfigCountStats(moment().startOf('month'),moment());




})

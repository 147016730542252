/**
 * Project: ngitemperature
 * Created by Haythem Horbit on 30/01/2017.
 */
angular.module("app").controller('mainCtrl', function ($scope, $interval, $rootScope,CONFIG) {

    $scope.counts=0
    setInterval(function(){$scope.counts++;$scope.$digest()}, 1000);
    $scope.open=function(){
        $( "#sidebar-right" ).addClass( "sidebar-open" );
        console.log("open")

    }

    $scope.close=function(){
        $( "#sidebar-right" ).removeClass( "sidebar-open" );
        console.log("close")

    }



})

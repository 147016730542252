/**
 * Project: fleetWeb
 * Created by  NGI team.
 */

angular.module("app").controller('loginCtrl', function ($rootScope,$scope, $state,loginSrv,ngDialog,$cookieStore,$translate,$window) {

    $scope.user={};
    $scope.rememberMe=  $cookieStore.get('rememberme');
    $scope.user.login=  $cookieStore.get('login');
    $scope.user.password=  $cookieStore.get('password');



    $scope.signIn=function() {

        if ($scope.rememberMe){

            $cookieStore.put('login',$scope.user.login);
            $cookieStore.put('password',$scope.user.password);
        }else{
            $cookieStore.remove('rememberme');
            $cookieStore.remove('login');
            $cookieStore.remove('password');
        }
        loginSrv.login( $scope.user,function(data){
           // console.debug("login success")



            $state.go("app.temperature.assetGroup")
        },function(data){

          var errorMsg="";
         var errorTitle=""
          if(data.status==-1){
            errorMsg= "Veuillez vérifier votre connexion internet ! "
              errorTitle="Service indisponible";
          }else if(data.status==200){

            errorTitle="Authentification échoué";

            if(data.data && data.data.success===false && data.data.error && data.data.error. errorCode=="error_authentication_unauthorized_error"){
              errorMsg= "Identifiant/mot de passe  invalide  ! "
            }else{
              errorTitle="Authentification échoué";

              errorMsg= "Une erreur s'est produite pendant l'authentification, veuillez contacter votre administrateur ! "
            }
          }else{
            errorTitle="Authentification échoué";

            errorMsg= "Une erreur inconnue s'est produite pendant l'authentification, veuillez contacter votre administrateur ! "
          }

            ngDialog.openConfirm({ template: 'app/commons/dialogs/error.dlg.html',overlay:false ,showClose:false,controller: ['$scope', function($scope) {
                // controller logic
              $scope.title=errorTitle;
              $scope.message=errorMsg

            }] });

        })
    }


    $scope.remember=function(){
        if ($scope.rememberMe){
            $cookieStore.put('rememberme',true);
            $cookieStore.put('login',$scope.user.login);
            $cookieStore.put('password',$scope.user.password);
        }else{
            $cookieStore.remove('rememberme');
            $cookieStore.remove('login');
            $cookieStore.remove('password');
        }
    }
    /*Open dialog window for reset password*/
    $scope.recoverPassword = function () {
        ngDialog.open({
            template: 'scripts/login/forget.password.tpl.html',
            controller: 'forgetPasswordCtrl',
            scope: $scope
        })
    }

})

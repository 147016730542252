/**
 * Created by ch on 27/01/2017.
 */
angular
  .module('app.directives').directive('heatAssetDash', function(alertSrv,deviceMsgSrv) {
    return {
        restrict: 'EA',
        templateUrl:"app/commons/directives/heat/heatAssetDash.tpl.html",
        scope: {
            asset: '='

        },
        link: function (scope, element, attributes) {



        },
        controller:function($scope,moment,$rootScope,$stateParams,chartSrv,assetSrv){
            if($stateParams.id){

                $scope.asset={_id:$stateParams.id}
                assetSrv.getAssetById($stateParams.id,function(res){
                    $scope.asset=res.result;
                },function(err){
                    console.log("error");
                })
            }else{

            }
            $scope.itemsByPage=10;
            $scope.moment=moment;
            $scope.datePicker={date : {startDate: moment().startOf('day'), endDate: moment().endOf('day')}};
            $scope.dpOptions = {
                applyClass: 'btn-green',
                locale: {
                    applyLabel: "valider",
                    fromLabel: "A partir de",
                    format: "YYYY-MM-DD", //will give you 2017-01-06
                    //format: "D-MMM-YY", //will give you 6-Jan-17
                    //format: "D-MMMM-YY", //will give you 6-January-17
                    toLabel: "à",
                    cancelLabel: 'Annuler',
                    customRangeLabel: ''
                },
                ranges:  {
                    "Aujourd'hui": [moment().startOf("day"), moment().endOf('day')],
                    "Hier": [moment().subtract(1, 'days').startOf("day"), moment().subtract(1, 'days').endOf('day')],
                    'Cette semaine': [moment().startOf('week').startOf("day"), moment().endOf('day')],
                    'la semaine dernière': [moment().subtract(7, 'days').startOf('week').startOf("day"), moment().subtract(7, 'days').endOf('week').endOf('day')],
                    'Ce mois': [moment().subtract(29, 'days').startOf("day"), moment().endOf('day')],
                    'Mois dernier': [moment().startOf('month').startOf("day"), moment().endOf('month').endOf('day')],
                    'les trois mois dernier': [moment().subtract(3, 'month').startOf('month').startOf("day"), moment().endOf('day')]
            }
            }
            $scope.$watch("datePicker.date",function(dates){
                console.log("peeeeeeeeeeeerod",dates);
                // $scope.loadAlerts({});
                $scope.updateStats();
            })
            $scope.updateStats=function(){
                if($scope.asset){


                    var startDate=moment().set({hour:0,minute:0,second:0,millisecond:0});
                    deviceMsgSrv.getDeviceMsgList({query:{_asset:$scope.asset._id,gps_dt:{$gte:$scope.datePicker.date.startDate,$lte:$scope.datePicker.date.endDate}}},1000,0,function(res){

                        console.log(res);
                        if(res && res.result && res.result.length>0 ){
                            var max=undefined; var min=undefined;var count=1;var total=0;
                            var chartData=[];
                            var xaxis=[];
                             var currentDate=null;
                            res.result.forEach(function(value,index){
                                console.log("dalllas",max,min,value.io.dls_temp1);
                                if(value && value.io && typeof value.io.dls_temp1 === 'number'){
                                    chartData.push([moment(value.gps_dt).valueOf(),value.io.dls_temp1]);

                                    xaxis.push(moment(value.gps_dt).format("YYYY-MM-DD HH:mm:ss"))
                                    count++;
                                    total+=value.io.dls_temp1;
                                    if(typeof max!== 'number'){
                                        max=value.io.dls_temp1;
                                        min=value.io.dls_temp1;
                                    }else{
                                        if(value.io.dls_temp1>max){
                                            max=value.io.dls_temp1;
                                        }
                                        if(value.io.dls_temp1<min){
                                            min=value.io.dls_temp1;
                                        }
                                    }
                                }

                            })

                           /* if(max && max.io && max.io.dls_temp1){
                                max= max.io.dls_temp1;
                            }else{
                                max=0;
                            }
                            if(min && min.io && min.io.dls_temp1){
                                min= min.io.dls_temp1;
                            }else{
                                min=0;
                            }*/
                            console.log("maaaaaaaaaaaax",xaxis,min);

                            chartSrv.updateLineChart("tempcart",[{name:"Température en °C",data:chartData}],xaxis,"","Température en °C","<i class='fa fa-tachometer '></i>  Historique de Température","Période de : "+(moment($scope.datePicker.date.startDate).format("YYYY-MM-DD HH:mm:ss")+" <span class='fa fa-arrow-right'></span> "+(moment($scope.datePicker.date.endDate).format("YYYY-MM-DD  HH:mm:ss"))))
                            $scope.stats={
                                min:min,
                                max:max,
                                avg:total/count

                            }
                        }

                    },function(err){
                        console.log(err);
                    })
                }
            }


        }
    }
})

/**
 * Project: fleetWeb
 * Created by Haythem Horbit on 29/09/15.
 */


angular.module("app.services").factory('socket', function ($rootScope,$timeout,CONFIG,$window,Notification) {
    var service = {};
    service.isConnected = false;
    var eventListener={}



    service.connect = function (clb) {
        // console.log("connexion")
        if (typeof io === "undefined") {
            service.on = function (eventName, callback) {
                console.error("Can't listnen to event " + eventName + ', No RT Server');
            }
            service.emit = function (eventName, callback) {
                console.error("Can't emit event " + eventName + ', No RT Server');
            }
            service.isConnected = false;
           /* service.closeListner=function (eventName, callback) {


            }*/


            if(clb) clb(false)
        } else {
           ///console.log($rootScope.RT_CONNECTED &&  $rootScope.isConnected);
         if( $rootScope.RT_CONNECTED &&  $rootScope.isConnected){
           console.log("already connected")
           return ;
         }
            var socket = io.connect(CONFIG.EVENT_URI);



            service.on = function (eventName, callback) {



                eventListener[eventName+'_'+callback.name]=function () {

                    var args = arguments;
                    $rootScope.$emit(eventName,args)
                    $rootScope.$apply(function () {

                        callback.apply(socket, args);
                    });
                };

                socket.on(eventName, eventListener[eventName+'_'+callback.name]);
                eventListener[eventName]=true;
            }
            service.emit = function (eventName, data, callback) {
                socket.emit(eventName, data, function () {
                    var args = arguments;
                    $rootScope.$apply(function () {
                        if (callback) {
                            callback.apply(socket, args);
                        }
                    });
                })
            }

            socket.on('newMsg', function (data) {

                $rootScope.$emit('newMsg',data)

            })
            socket.on('newAlert', function (data) {

              console.log("newAlert",data);
                $rootScope.$emit('newAlert',data)

            })
            socket.on('updateAlert', function (data) {


                $rootScope.$emit('updateAlert',data)

            })
          socket.on('server:connected', function (data) {
            //console.log("USEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEER COOOOOOOOOOOOOOOOOOOOOOONNECTED",JSON.parse($window.sessionStorage.SESSION).user );
            socket.emit('user:connected',{user:JSON.parse($window.sessionStorage.SESSION).user});

          });
            service.closeListner=function (eventName, callback) {
                socket.disconnect()
                socket.removeAllListeners(eventName, function() {
                    var args = arguments;
                    $timeout(function() {
                        $rootScope.$apply(function () {
                            callback.apply(socket, args);
                        });

                    })


                });

            }



            socket.on('connect', function (data) {
             /*  Notification({title: ' INFO ', message: 'Connected RT'});
                var _company_owner;
                // if ($rootScope.USER._company_owner) _company_owner = $rootScope.USER._company_owner._id
                /* socket.emit('user:connected', {
                 //   user: $rootScope.USER._id,
                 //   role: $rootScope.USER.role,
                 //   _company_owner:_company_owner
                 });*/
                $rootScope.RT_CONNECTED=true;
                service.isConnected = true;
            })
            socket.on('disconnect', function (data) {

                // Notification.error({title: ' ERROR ', message: 'Disconnected RT'});
                $rootScope.RT_CONNECTED=false;
                service.isConnected = false;


                $timeout(function() {
                    $rootScope.$apply(function () {

                    });
                })
                console.log("disconnect from RT")
            })
            socket.on('error', function (data) {
                //  Notification.error({title: ' ERROR ', message: 'Error RT'});
                console.log("connection error to RT")
            })
            service.off= function off(event, callback) {

                if (typeof callback == 'function') {
                    var listener =eventListener[event+"_"+callback.name];
                    socket.removeListener(event, listener);
                } else {
                    socket.removeAllListeners(event);
                }

            }

            if (clb) clb(true)
        }
    }


    return service;

});

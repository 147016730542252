/**
 * Project: fleetWeb
 * Created by  NGI team.
 */

angular.module("app.services").factory('moduleSrv',['$http','$rootScope','dataSrv','$window', function ($http,$rootScope,dataSrv,$window) {

    var service={};

    service.getModuleList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/module/query?limit='+l+'&page='+p,searchQuery).success(function(data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available
            if (data && data.success){

                success(data);
            }
            else{

                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }
    service.getModuleAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/module/agg?limit='+l+'&page='+p,agg).success(function(data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available
            if (data && data.success){

                success(data);
            }
            else{

                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }

    service.getModuleById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/module/'+id).success(function(data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available

            if (data && data.success){

                success(data);
            }
            else{

                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }
    service.deleteModuleById=function(id,success,error){
        $http["delete"]($rootScope.config.API_URI+'/module/'+id).success(function(data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available

            if (data && data.success){

                success(data);
            }
            else{

                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }

    service.updateModuleById=function(id,module,success,error){
        $http.put($rootScope.config.API_URI+'/module/'+id,module).success(function(data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available

            if (data && data.success){

                success(data);
            }
            else{

                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }
    service.add=function(module,success,error){
        $http.post($rootScope.config.API_URI+'/module/',module).success(function(data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available

            if (data && data.success){

                success(data);
            }
            else{

                error(data);
            }



        }).error(function(data, status, headers, config) {
            error(data)

        });
    }
    return service;

}]);

/**
 * Created by Hamdi on 27/02/2017.
 */


angular.module("app").controller('adminCtrl', ['$scope', '$state','$rootScope',"$filter","$window",
  function ($scope,$state,$rootScope,$filter,$window) {





}])

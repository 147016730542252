/**
 * Project: fleetWeb
 * Created by  NGI team.
 */

angular.module("app.services").factory('enumsSrv',['$http','$rootScope','dataSrv','$window', function ($http,$rootScope,dataSrv,$window) {

    var service={};

    service.getEnums=function(success,error){



        $http.get($rootScope.config.API_URI+'/enums').then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){

                success(response.data.result);
            }
            else if (response && response.data && response.data.success==false){

                error(response.data.error);
            }else{
              error(response);
            }



        },function(err, status, headers, config) {
            error(err)

        });
    }

    return service;

}]);

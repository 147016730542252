/**
 * Project: fleetWeb
 * Created by  NGI team.
 */
angular.module("app.services").factory('fleetSrv',['$http','$rootScope','dataSrv','$window', function ($http,$rootScope,dataSrv,$window) {

    var service={};

    service.getFleetsList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/fleet/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(response);
            }



        },function(data, status, headers, config) {
            error(data)

        });
    }
    service.getFleetsAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/fleet/agg?limit='+l+'&page='+p,agg).then(function(response) {
          console.log('response',response)
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){



            success(response.data);
          }
          else if(response && response.data && response.data.success===false) {

            error(response.data.error);
          }else{
            error(response);
          }


        },function(data, status, headers, config) {
          error(data)

        });
    }
    service.getFleetAssets=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/fleet/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
          console.log('response',response)
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){



            success(response.data);
          }
          else if(response && response.data && response.data.success===false) {

            error(response.data.error);
          }else{
            error(response);
          }


        },function(data, status, headers, config) {
          error(data)

        });
    }

    service.getFleetById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/fleet/'+id).then(function(response) {
          console.log('response',response)
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){



            success(response.data);
          }
          else if(response && response.data && response.data.success===false) {

            error(response.data.error);
          }else{
            error(response);
          }


        },function(data, status, headers, config) {
          error(data)

        });
    }
    service.deleteFleetById=function(id,success,error){
  $http.delete($rootScope.config.API_URI+'/fleet/'+id).then(function(response) {
    console.log('response',response)
    // this callback will be called asynchronously
    // when the response is available
    if (response && response.data && response.data.success){



      success(response.data);
    }
    else if(response && response.data && response.data.success===false) {

      error(response.data.error);
    }else{
      error(response);
    }


  },function(data, status, headers, config) {
    error(data)

  });
    }

    service.updateFleetById=function(id,fleet,success,error){
        $http.put($rootScope.config.API_URI+'/fleet/'+id,fleet).then(function(response) {
          console.log('response',response)
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){



            success(response.data);
          }
          else if(response && response.data && response.data.success===false) {

            error(response.data.error);
          }else{
            error(response);
          }


        },function(data, status, headers, config) {
          error(data)

        });
    }
    service.add=function(fleet,success,error){
        $http.post($rootScope.config.API_URI+'/fleet/',fleet).then(function(response) {
          console.log('response',response)
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){



            success(response.data);
          }
          else if(response && response.data && response.data.success===false) {

            error(response.data.error);
          }else{
            error(response);
          }


        },function(data, status, headers, config) {
          error(data)

        });
    }
    return service;

}]);

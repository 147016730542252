/**
 * Created by Kais CHAIBI on 05/05/2016.
 */

angular.module('smart-table') .
    filter('ranges', function () {
        return function (input, min, max) {

            min = parseInt(min); //Make string input int
            max = parseInt(max);
            for (var i = min; i < max; i++) {

                input.push(i);

            }

            return input;
        };
    });

angular.module('smart-table').run(['$templateCache', function ($templateCache) {

    $templateCache.put('template/smart-table/pagination.html',
        '<nav ng-if="numPages && pages.length >= 2"><ul class="pagination">' +
        '<li ng-class=""><a ng-click="selectPage(1)"><i class="fa fa-fw fa-angle-double-left"></i></a></li>' +
        '<li ng-class=""><a ng-click="selectPage(currentPage-1)"><i class="fa fa-fw fa-angle-left"></i></a></li>' +
        '<li ng-repeat="page in pages" ng-class="{active: page==currentPage}"><a ng-click="selectPage(page)">{{page}}</a></li>' +
        '<li ng-class=""><a ng-click="selectPage(currentPage+1)"><i class="fa fa-fw fa-angle-right"></i></li>' +
        '<li ng-class=""><a ng-click="selectPage(numPages)"><i class="fa fa-fw fa-angle-double-right"></i></a></a></li>' +
        '<li  style="padding-left: 5px;"></i> <select ng-model="selectedPage" class="   input-sm" ng-change="selectPage(selectedPage)"  ng-options="page  for page in   [] | ranges:1:numPages+1 "></select></li>' +
        '</ul></nav><div class="">' +
        '   <div class="panel-footer">  ' +
        '      <form class="form-inline pull-right"><div class="input-group">   ' +
        ' <div class="btn-group" >  ' +
        '  <label class="btn btn-default"   ng-model="limit" btn-radio="5" uncheckable ng-click="stItemsByPage=5;selectPage(1)">5</label>' +
        '    <label class="btn btn-default"  ng-model="limit" btn-radio="10" uncheckable ng-click="stItemsByPage=10;selectPage(1)">10</label>' +
        '    <label class="btn btn-default"   ng-model="limit" btn-radio="50" uncheckable ng-click="stItemsByPage=50;selectPage(1)">50</label>' +
        '    <label class="btn btn-default"   ng-model="limit" btn-radio="100" uncheckable ng-click="stItemsByPage=100;selectPage(1)">100</label>' +
        ' </div>    ' +
        '</div>' +
        '    </form>' +
        '<h6 class="text-muted "  style="text-align: left">Nombre total des éléments {{totalItemCount || 0}} éléments - Nombre total des pages  {{numPages || 0}} </h6> ');
}]);

angular.module('smart-table').directive('searchWatchModel',function(){
    return {
        require:'^stTable',
        scope:{
            searchWatchModel:'='
        },
        link:function(scope, ele, attr, ctrl){
            var table=ctrl;

            scope.$watch('searchWatchModel',function(val){
                ctrl.search(val);
            });

        }
    };
});

angular.module('smart-table').directive('stSelectionChange', function($window){
    return{
        require:'ngModel',
        link: function(scope, element, attrs, ngModel){

            scope.$watch(attrs.ngModel,function (recent,old){

                if(old=='') old=undefined;
                if(recent=='') recent=undefined;

                if( recent!=old ){

                        element.triggerHandler('selected');
                    }

            })
            /*if(scope[attrs.ngModel]!=undefined){
                setTimeout(function(){
                    element.triggerHandler('selected');
                })
            }*/

        }
    }
});

/**
 * Created by Hamdi on 16/01/2017.
 */

angular.module("app.services").factory('collectionSrv',["assetSrv",'userSrv','companySrv','roleSrv','moduleSrv','deviceSrv',
    'deviceMsgSrv','fleetSrv','alertModelSrv','alertConfigSrv','alertSrv','$filter',"countryApiSrv","deviceMessageMetricSrv","actionLogSrv",
    function (assetSrv,userSrv,companySrv,roleSrv,moduleSrv,deviceSrv,deviceMsgSrv,fleetSrv,alertModelSrv,
              alertConfigSrv,alertSrv,$filter,countryApiSrv,deviceMessageMetricSrv,actionLogSrv) {

    var service={};
        var collectionServices=[

            {"name":"asset", "exec": assetSrv.getAssetsList},
            {"name":"user", "exec": userSrv.getUsersList},
            {"name":"company", "exec": companySrv.getCompaniesList},
            {"name":"device_msg", "exec": deviceMsgSrv.getDeviceMsgList},
            {"name":"role", "exec": roleSrv.getRolesList},
            {"name":"module", "exec": moduleSrv.getModuleList},
            {"name":"device", "exec": deviceSrv.getDevicesList},
            {"name":"asset_groupe", "exec": fleetSrv.getFleetsList},
            {"name":"alert_model", "exec": alertModelSrv.getAlertModelsList},
            {"name":"alert_config", "exec": alertConfigSrv.getAlertConfigsList},
            {"name":"alert", "exec": alertSrv.getAlertsList},
            {"name":"metric_monthly",exec:deviceMessageMetricSrv.getMonthlyMetricList},
            {"name":"metric_daily",exec:deviceMessageMetricSrv.getDailyMetricList},
            {"name":"country",exec:countryApiSrv.getCountryList},
            {"name":"action_log",exec:actionLogSrv.getActionLogList},


        ];

        service.searchCollection=function(name){

          var data= $filter("filter")(collectionServices,{name:name},true)
           if(data.length>0)
               return data[0].exec;
           else
           return null;
        }

    service.getCollectionData=function(){

       return collectionServices;

    }
        /**
         * prepare fields for query builder
         * @param fields
         */
        service.qBPrepareFields=function(fields){
            var filterList=[];
            var availableTypes=['string',"integer","double",'date',"time",'datetime','boolean','number','objectid'];
            var specialMapping={
                'number':'double',
                "objectid":'string'
            }
            if(fields && fields.length>0){
                for (var i = 0; i < fields.length; i++) {


                    if(availableTypes.indexOf(fields[i].type.toLowerCase())>=0){
                        filterList.push({
                            id: fields[i].name,
                            type: specialMapping[fields[i].type.toLowerCase()] || fields[i].type.toLowerCase()
                        })
                    }else{
                        console.debug('Unsupported JB type' ,fields[i] );
                    }



                }

            }
            return  filterList;

        }

    return service;


}]);

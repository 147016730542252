/**
 * Project: ngitemperature
 * Created by Haythem Horbit on 30/01/2017.
 */
angular.module("app").controller('detailsCtrl', function ($scope,$state) {


    $scope.close=function(){
        $( ".sidebar-right" ).removeClass( "sidebar-open" );
        console.log("close")
        $state.go('^')
    }



})
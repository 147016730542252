/**
 * Project: fleetWeb
 * Created by  NGI team.
 */

angular.module("app.services").factory('historicSrv', ['$http', '$rootScope', 'dataSrv', '$window', function ($http, $rootScope, dataSrv, $window) {

    var service = {};

    service.getHistoric = function (searchQuery,success,error) {
        $http.post($rootScope.config.API_URI + '/device_msg/query', searchQuery).success(function (data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available
            if (data && data.success) {
                success(data);
            }else{
                error(data);
            }
        });
    }

    service.calculateTrajets=function(searchQuery,filter_drive,filter_stop,success,error){
        trajets=[];
        //init params
        var countDrive = 0,countStops = 0,duree= 0,trajet = {},filter=0;
        var i = 0, j = 0, index = 0, endTrajet = false, latlang = "";
        $http.post($rootScope.config.API_URI + '/device_msg/query', searchQuery).success(function (data, status, headers, config) {
            // this callback will be called asynchronously
            // when the response is available
            if (data.success) {

                if (data.result.length > 0) {
                    data=data.result;
                    index = 0;
                    while (i < data.length) { // first while
                        if (data[i].io && data[i].gps_dt && data[i].loc) {
                            //INIT trajet
                            trajet = {};
                            //Init Liste coordinates all(lat/lang)tarjet
                            trajet.coordinates = [];
                            //Order Trajet
                            trajet.index = index;
                            index++;
                            //create Start
                            trajet.Start = data[i];
                            latlang = {lat: trajet.Start.loc.coordinates[1], lng: trajet.Start.loc.coordinates[0]};
                            trajet.coordinates.push(latlang); // add lat/lang to cordiantes Liste

                            // check state trajet
                            if (data[i].io.con == 1) {
                                trajet.state = 'drive';
                                filter= filter_drive;
                                trajet.order = countDrive; //count number trajet drive
                                countDrive++;
                            }
                            else {
                                trajet.state = 'stop';
                                filter= filter_stop;
                                trajet.order = countStops; //count number stops
                                countStops++;
                            }
                            //init params to calculate paths trajet
                            j = i + 1;
                            endTrajet = false;
                            trajet.paths = [];

                            while (j < data.length && !endTrajet) { // while 2
                                if (data[j].io && data[j].gps_dt && data[j].loc) {

                                    if (j < data.length - 1){

                                        //--------------------------split trajet---------------------------------------
                                        //filter time
                                        duree = dateDiff(trajet.Start.gps_dt, data[j].gps_dt);
                                        if (duree.min > filter) {

                                            if ((data[j].io.con == trajet.Start.io.con)) {// add to path
                                                trajet.paths.push(data[j]);
                                                latlang = {
                                                    lat: data[j].loc.coordinates[1],
                                                    lng: data[j].loc.coordinates[0]
                                                };
                                                trajet.coordinates.push(latlang);
                                                j++;
                                            } else {//end trajet
                                                trajet.End = data[j - 1];
                                                endTrajet = true;
                                            }

                                        } else {// add to path
                                            trajet.paths.push(data[j]);
                                            latlang = {
                                                lat: data[j].loc.coordinates[1],
                                                lng: data[j].loc.coordinates[0]
                                            };
                                            trajet.coordinates.push(latlang);
                                            j++;
                                        }
                                        //---------------------------end split trajet----------------------------------
                                    }else{// end of Liste

                                        if(data[j].io.con== trajet.Start.io.con){
                                            trajet.paths.push(data[j]);
                                            latlang = {
                                                lat: data[j].loc.coordinates[1],
                                                lng: data[j].loc.coordinates[0]
                                            };
                                            trajet.coordinates.push(latlang);
                                            trajet.End = data[j];
                                            endTrajet = true;
                                            j++;
                                        }else{
                                            trajet.End = data[j - 1];
                                            endTrajet = true;
                                        }
                                    }
                                }else{
                                    if(j==data.length-1){//end trajet j-1

                                        trajet.End = data[j-1];
                                        latlang = {
                                            lat: trajet.End.loc.coordinates[1],
                                            lng: trajet.End.loc.coordinates[0]
                                        };
                                        trajet.coordinates.push(latlang);
                                        endTrajet = true;
                                    }else j++;
                                }
                            }//end while  2
                             //diff=dateDiff(trajet.Start.gps_dt,trajet.End.gps_dt);
                            if (trajet.Start && trajet.End) {
                                trajet.duree = dateDiff(trajet.Start.gps_dt, trajet.End.gps_dt);
                                if (trajet.state == 'drive')
                                    trajet.distance = calculDistanceTrajet(trajet);
                                else
                                    trajet.distance = 0;
                                trajets.push(trajet);
                              //  console.log(trajet);
                            }
                            i = j;

                        }else
                            i++;

                    }//end while 1

                }//end if length
                console.log()
                success(trajets);
            }else{
                error(data);
            }
        });



    }


    return service;

}]);

/**
 * Created by Kais CHAIBI on 02/01/2017.
 */
angular.module("app").directive('stDatePicker', ['$timeout', function ($timeout) {
    return {
        restrict: 'E',
        require: '^stTable',
        scope: {
            selectedDate: '='

        },
        templateUrl: 'app/commons/directives/stDatePicker.tpl.html',

        link: function (scope, element, attr, table) {

            var inputs = element.find('input');
           // console.log(inputs,element)
            var input = angular.element(inputs[0]);
            var predicateName = attr.predicate;
             scope.selectedDate=new Date();
            scope.timeOptions = attr.stTimeOptions
            .placeholder = attr.placeholder;
            scope.datetimePickerOptions=  attr.stDtPickerOptions

            scope.$watch("isOpen", function(val){
                if(val===false){
                   /* scope.$apply(function () {*/
                        table.search(scope.selectedDate, predicateName);
                   /* })*/
                }
            })




            function open(open) {
                return function ($event) {
                    $event.preventDefault();
                    $event.stopPropagation();

                    if (open) {
                        scope.isOpen = true;
                    }
                }
            }

            scope.openBefore = open(true);

        }

    }
}])

/**
 * Project: iaxeed
 * Created by  Mohamed Saad on 30/12/2016.
 */

angular.module("app.services").factory('deviceMsgSrv',['$http','$rootScope',"$q", function ($http,$rootScope,$q) {

    var service={};

    service.getDeviceMsgList=function(searchQuery,limit,page,success,error,cancelable){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;

      if(cancelable){
        if(this.canceler){
          this.canceler.resolve();
          this.canceler=null;
        }
        this.canceler =$q.defer();

        var options={timeout: this.canceler.promise};
      }

        $http.post($rootScope.config.API_URI+'/device_msg/query?limit='+l+'&page='+p,searchQuery,options).then(function(response) {
          this.canceler=null;
            if (response && response.data && response.data.success){
                success(response.data);
            }
            else{

              if(response.status==-1){
                // canceleed do not callBack function


              }else {
                error(response);
              }
            }



        } ,function(data, status, headers, config) {
          this.canceler=null;


            error(data)



        });
    }
    service.getDeviceMsgAgg=function(agg,limit,page,success,error,cancelAble){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;



        $http.post($rootScope.config.API_URI+'/device_msg/aggregate',agg).then(function(response) {
            if (response && response.data){
                success(response.data);
            }
            else{
                error(response);
            }



        },function(data, status, headers, config) {
            error(data)

        });
    }
  service.getDeviceMsgAgg2=function(agg,limit,page,success,error,cancelAble){
    var l=10;
    var p= 1;
    if (page) p=page;
    if (limit) l=limit;



    $http.post($rootScope.config.API_URI+'/device_msg/agg?limit='+l+'&page='+p,agg).then(function(response) {
      if (response && response.data){
        success(response.data);
      }
      else{
        error(response);
      }



    },function(data, status, headers, config) {
      error(data)

    });
  }
    return service;

}]);

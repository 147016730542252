/**
 * Project: ngitemperature
 * Created by Haythem Horbit on 31/01/2017.
 */
var config_dev={
    "APP_NAME": "IAXEED",
    "APP_VERSION": "v0.0.2",
    "API_URI": "http://10.20.160.120:5000/api/v1.0",
    "LBS_WMS":"http://demo.ngi-maghreb.com/bgis/wms",
    "EVENT_URI":"http://localhost:5000", // socket io
    "BND_URI":"http://demo.ngi-maghreb.com/bgis/bnd"

}
var delice={
  "APP_NAME": "IAXEED",
  "APP_VERSION": "v0.0.2",
  "API_URI": "http://10.14.0.137/api/v1.0",
  "LBS_WMS":"http://demo.ngi-maghreb.com/bgis/wms",
  "EVENT_URI":'ws://10.14.0.137/', //socket io
  "BND_URI":"http://demo.ngi-maghreb.com/bgis/bnd"



}
var config_prod={
    "APP_NAME": "IAXEED",
    "APP_VERSION": "v0.0.2",
    "API_URI": "http://api.iot.tn/api/v1.0",
    "LBS_WMS":"http://demo.ngi-maghreb.com/bgis/wms",
    "EVENT_URI":'http://37.59.29.22:5000/', //socket io
    "BND_URI":"http://demo.ngi-maghreb.com/bgis/bnd"



}
var config_prod_new={
    "APP_NAME": "IAXEED",
    "APP_VERSION": "v0.0.2",
    "API_URI": "http://app.iaxeed.com/api/v1.0",
    "LBS_WMS":"http://demo.ngi-maghreb.com/bgis/wms",
    "EVENT_URI":'ws://app.iaxeed.com/', //socket io
    "BND_URI":"http://demo.ngi-maghreb.com/bgis/bnd"



}



angular.module("app").constant("CONFIG",config_prod_new);


angular.module("app").factory('authInterceptor',['$rootScope', '$q', '$window', function ($rootScope, $q, $window) {
    return {
      request: function (config) {

        config.headers = config.headers || {};
        if ($window.sessionStorage.token  && !config.headers.Authorization) {
          config.headers.Authorization = 'Bearer ' + $window.sessionStorage.token;
        }
        return config;
      },
      response: function (response) {
     //   console.dir(response.status)

       /* if(response.status>=401 && response.status<=401) {
          $rootScope.$broadcast("sessionExpired", response);
        }*/
      //  $rootScope.$broadcast("responseError", response);

         //console.log(response)
        if(response.status>=401 && response.status<=401 ) {
          $rootScope.$broadcast("sessionExpired", response);
        }
        return response || $q["when"](response);
      },

      responseError:function(response){

     console.log(response.status)
        if(response.status>=401 && response.status<=401 ) {
          $rootScope.$broadcast("sessionExpired", response);
        }else{
          $rootScope.$broadcast("responseError", response);
        }
        return response;
      }
    }
  }]
)
  .config(["$httpProvider",function ($httpProvider) {
    $httpProvider.interceptors.push('authInterceptor');

    $httpProvider.interceptors.push('ajax-loader');
  }]).config(["$logProvider", "$provide","$translateProvider",function($logProvider, $provide,$translateProvider){

    $logProvider.debugEnabled(false);
    $provide.decorator("uiSelect2Directive", ['$delegate',function ($delegate) {
      var directive;
      directive = $delegate[0];
      directive.priority = 10;
      return $delegate;
    }])
    $translateProvider.useStaticFilesLoader({
      files: [{
        prefix: 'app/i18n/',
        suffix: '.json'
      } ]
    });
    $translateProvider.preferredLanguage('fr');


  }])

/**
 * Created by ch on 27/01/2017.
 */
angular
  .module('app.directives').directive('heatGroupDash', function(alertSrv,deviceMessageMetricSrv) {
    return {
        restrict: 'EA',
        templateUrl:"app/commons/directives/heat/heatGroup.tpl.html",
        scope: {
            assets: '='

        },
        link: function (scope, element, attributes) {



        },
        controller:function($scope,moment,$rootScope){
            $scope.itemsByPage=10;
            $scope.moment=moment;
            $scope.datePicker={date : {startDate: moment().startOf('day'), endDate: moment().endOf('day')}};
            $scope.dpOptions = {
                applyClass: 'btn-green',
                locale: {
                    applyLabel: "valider",
                    fromLabel: "A partir de",
                    format: "YYYY-MM-DD", //will give you 2017-01-06
                    //format: "D-MMM-YY", //will give you 6-Jan-17
                    //format: "D-MMMM-YY", //will give you 6-January-17
                    toLabel: "à",
                    cancelLabel: 'Annuler',
                    customRangeLabel: ''
                },
                ranges:  {
                    "Aujourd'hui": [moment().startOf("day"), moment().endOf('day')],
                    "Hier": [moment().subtract(1, 'days').startOf("day"), moment().subtract(1, 'days').endOf('day')],
                    'Cette semaine': [moment().startOf('week').startOf("day"), moment().endOf('day')],
                    'la semaine dernière': [moment().subtract(7, 'days').startOf('week').startOf("day"), moment().subtract(7, 'days').endOf('week').endOf('day')],
                    'Ce mois': [moment().subtract(29, 'days').startOf("day"), moment().endOf('day')],
                    'Mois dernier': [moment().startOf('month').startOf("day"), moment().endOf('month').endOf('day')],
                    'les trois mois dernier': [moment().subtract(3, 'month').startOf('month').startOf("day"), moment().endOf('day')]
            }
            }
            $scope.$watch("datePicker.date",function(dates){
                console.log("peeeeeeeeeeeerod",dates);
                 $scope.loadAlerts({});
                $scope.updateStats();
            })
            /*$scope.updateStats=function(){
                if($scope.assets && $scope.assets.length>0){
                    var ids=$scope.assets.map(function(val){
                        return  val._id;
                    })

                    var startDate=moment().set({hour:0,minute:0,second:0,millisecond:0});
                    deviceMsgSrv.getDeviceMsgList({query:{_asset:{$in:ids},gps_dt:{$gte:$scope.datePicker.date.startDate,$lte:$scope.datePicker.date.endDate}}},1000,0,function(res){

                        console.log(res);
                        if(res && res.result && res.result.length>0 ){
                            var max=undefined; var min=undefined;var count=1;var total=0;
                            res.result.forEach(function(value){
                                console.log("dalllas",max,min,value.io.dls_temp1);
                                if(value && value.io && typeof value.io.dls_temp1 === 'number'){
                                    count++;
                                    total+=value.io.dls_temp1;
                                    if(typeof max!== 'number'){
                                        max=value.io.dls_temp1;
                                        min=value.io.dls_temp1;
                                    }else{
                                        if(value.io.dls_temp1>max){
                                            max=value.io.dls_temp1;
                                        }
                                        if(value.io.dls_temp1<min){
                                            min=value.io.dls_temp1;
                                        }
                                    }
                                }
                            })

                           /!* if(max && max.io && max.io.dls_temp1){
                                max= max.io.dls_temp1;
                            }else{
                                max=0;
                            }
                            if(min && min.io && min.io.dls_temp1){
                                min= min.io.dls_temp1;
                            }else{
                                min=0;
                            }*!/
                            console.log("maaaaaaaaaaaax",max,min);
                            $scope.stats={
                                min:min,
                                max:max,
                                avg:total/count

                            }
                        }

                    },function(err){
                        console.log(err);
                    })
                }
            }*/


          $scope.updateStats=function() {
            if ($scope.assets && $scope.assets.length > 0) {
              var ids = $scope.assets.map(function (val) {
                return new ObjectId(val._id).toString();
              })
              ids.push(new ObjectId("570e15df3c5f273a4c2834d4").toString());
               var aggQuery=[

                 {
                   $match: {
                     _asset:{$in:ids},
                     dt:{$gte:new ISODate($scope.datePicker.date.startDate).toString(),$lte:new ISODate($scope.datePicker.date.endDate).toString()}}},
                 {
                 $group:
                 {
                   _id:null,
                   avg:{$avg:{ $divide: [ "$io.dls_temp1.sum", "$io.dls_temp1.count" ]} },
                   max:{$max:"$io.dls_temp1.max"},
                   min:{$min:"$io.dls_temp1.min"}
                 }
               }

               ]
              deviceMessageMetricSrv.getDailyMetricAgg({aggregate:aggQuery},100000,0,function(res){
               if(res &&  res.success && res.result &&  res.result.length>0){
                 $scope.stats=res.result[0]
               }
              }, function(err){
                console.log(err);
              })


            }
          }

              $rootScope.$on("assets:updated",function(evt,args){
               $scope.assets=args;
                $scope.updateStats();
                $scope.loadAlerts({});
            })
            $scope.loadAlerts =function (tableState, ctrl) {

              console.log('load alertessssssssssss')
              if (!$scope.stCtrl && ctrl) {
                $scope.stCtrl = ctrl;
              }

              if (!tableState && $scope.stCtrl) {
                $scope.stCtrl.pipe();
                return;
              }
              var page;
              var limit = 1;
              var number = $scope.itemsByPage;
              var start = 1;

              if (tableState.pagination) {
                var pagination = tableState.pagination;
                number = pagination.number || $scope.itemsByPage;
                start = pagination.start / number + 1 || 1;
                $scope.currentPage = start;

              } else {
                tableState = {pagination: {}}
              }


                var query = {
                    query: {start_dt:{$gte:$scope.datePicker.date.startDate,$lte:$scope.datePicker.date.endDate}},

                    options: {
                        populate: ['_company_owner', '_assetId', '_alertConfig'],
                        sortBy: {start_dt: -1}
                    }
                };
                if($scope.assets && $scope.assets.length>0) {
                    var ids = $scope.assets.map(function (val) {
                        return val._id;
                    })
                    query.query._assetId={$in:ids}
                }



                alertSrv.getAlertsList(query,number,start,function success (data) {
                    if (data) {
                        $scope.alertList = data;
                        tableState.pagination.numberOfPages = Math.ceil(data.total_count / number);
                        tableState.pagination.totalItemCount = data.total_count;
                    } else {
                        console.log(data.error)
                    }
                }, function (data) {
                    console.log(data)
                });


            };


        }
    }
})

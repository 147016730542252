/**
 * Project: fleetWeb
 * Created by  NGI team.
 */

angular.module("app.services").factory('deviceTypeSrv',['$http','$rootScope','dataSrv','$window', function ($http,$rootScope,dataSrv,$window) {

    var service={};

    service.getDeviceTypesList=function(searchQuery,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/devicetype/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){

            success(response.data);
          }else if(response && response.data && data.success==false){
            error(response.data)
          }
          else{

            error(response);
          }



        },function(err) {
          error(err)

        });
    }
    service.getDeviceTypesAgg=function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post($rootScope.config.API_URI+'/devicetype/agg?limit='+l+'&page='+p,agg).then(function(response) {
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){

            success(response.data);
          }else if(response && response.data && data.success==false){
            error(response.data)
          }
          else{

            error(response);
          }



        },function(err) {
          error(err)

        });
    }


    service.getDeviceTypeById=function(id,success,error){
        $http.get($rootScope.config.API_URI+'/devicetype/'+id).then(function(response) {
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){

            success(response.data);
          }else if(response && response.data && data.success==false){
            error(response.data)
          }
          else{

            error(response);
          }



        },function(err) {
          error(err)

        });
    }
    service.deleteDeviceTypeById=function(id,success,error){
        $http["delete"]($rootScope.config.API_URI+'/devicetype/'+id).then(function(response) {
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){

            success(response.data);
          }else if(response && response.data && data.success==false){
            error(response.data)
          }
          else{

            error(response);
          }



        },function(err) {
          error(err)

        });
    }

    service.updateDeviceTypeById=function(id,devicetype,success,error){
        $http.put($rootScope.config.API_URI+'/devicetype/'+id,devicetype).then(function(response) {
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){

            success(response.data);
          }else if(response && response.data && data.success==false){
            error(response.data)
          }
          else{

            error(response);
          }



        },function(err) {
          error(err)

        });
    }

    service.add=function(devicetype,success,error){
        $http.post($rootScope.config.API_URI+'/devicetype/',devicetype).then(function(response) {
          // this callback will be called asynchronously
          // when the response is available
          if (response && response.data && response.data.success){

            success(response.data);
          }else if(response && response.data && data.success==false){
            error(response.data)
          }
          else{

            error(response);
          }



        },function(err) {
          error(err)

        });
    }


    return service;

}]);

/**
 * Created by Kais CHAIBI on 02/02/2017.
 */
angular.module("app").controller('temperatureController', function ($scope, $interval, $rootScope,CONFIG,assetSrv, fleetSrv,$state,enumsSrv) {



  // $scope.loadAssets();


  $scope.loadAssets=function (id){
    if(id){
      var query={
        query: {
          _id:id
        },
        options:{populate:["_assets"]}


      }

    fleetSrv.getFleetsList({},100,0,function(res){

      $scope.appData.assets=res.result;

      if(res && res.result && res.result.length>0 ) {
        $scope.appData.assets = res.result[0]._assets
        $rootScope.$emit('assets:updated', res.result[0]._assets);
      //  console.log("Loading assets(s",res.result[0]._assets)
      }
    },function(err){
      console.error(err)
    })
    }else{
      fleetSrv.getAssetsList({},100,0,function(res){

        $scope.appData.assets=res.result;
      },function(err){
        console.error(res)
      })
    }
  }
});

/**
 * Created by Kais CHAIBI on 02/02/2017.
 */

function ObjectId(str){
    this.str=str;
}

ObjectId.prototype.toString=function(){
    if(this.str && this.str.length>=0)
        return   "#OBJID#"+this.str+"#OBJIDEND#";
    else
    return   undefined;

}
function ISODate(date){
  if(typeof date =='string')
  this.date=new Date(date);
  else
  this.date=date
}

ISODate.prototype.toString=function(){
  if(this.date)
    return   "#ISODATE#"+this.date.toISOString()+"#ISODATEEND#";
  else
    return   undefined;

}

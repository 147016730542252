/**
 * Created by Hamdi on 01/03/2017.
 */
angular.module("app").controller('deviceAdminDetailCtrl',
  [
    '$scope',
    '$state',
    'deviceSrv',
    '$rootScope',
    '$translate','ngDialog','Notification',"$window",'$stateParams','deviceTypeSrv','enumsSrv',
    function ($scope,
              $state,
              deviceSrv,
              $rootScope,
              $translate,ngDialog,Notification ,$window,$stateParams,deviceTypeSrv,enumsSrv) {

      $scope.device = {};
      $scope._ADMIN_DEVICE_ADD = {};

      $scope.editMode=false;




      deviceTypeSrv.getDeviceTypesList({},Number.MAX_SAFE_INTEGER,0,function(data){
       if(data && data.success){
       $scope.deviceTypesList=data.result;
       }else{
         Notification.error("Problème de connexion Internet");
       }

       },function(err){

       })


      enumsSrv.getEnums(function (data) {
       $scope.ENUMS= data
      }, function(err){
        console.error(err);
      })

      $scope.loadDeviceDetails=function(item){
        if(!item) {

          var query = {
            query: {_id: $stateParams.id}, options: {populate:
              [{
                  path: '_company_owner',
                  select: 'name'
                } ]
            }
          };
          deviceSrv.getDevicesList(query, 1, 0, function (result) {
            $scope.device = result.result[0];
            // console.log('Device',$scope.device)
          }, function (err) {
            if (err && err.status == -1) {
              Notification.error("Problème de connexion Internet");
            }
            console.error(err)
          })
        }
        else{
          $scope.device=item;
        }
      };

      if ($stateParams.id) {
        $scope.loadDeviceDetails($rootScope.selectedDevice);
        $scope.editMode = true;
        $scope.device._id = $stateParams.id;
      } else         $scope.editMode = false;



      ////////add device/////////

      $scope.addDevice = function () {

        deviceSrv.add($scope.device, function (data) {
          if (data.success) {
            Notification('Appareil ajouté');

            $scope.cancel();

            $rootScope.loadDevices({});
            //setTimeout(function (){$state.reload();});
          }
        }, function (err) {

        })

      };



      $scope.cancel=function(){
        if($state.current.name=="app.temperature.admin.device.details" || $state.current.name=="app.temperature.admin.device.add" ){
          $( ".sidebar-right" ).removeClass( "sidebar-open" )

          setTimeout(function(){
            $state.go("app.temperature.admin.device");
          },100)


        }else  {
          $( ".sidebar-right" ).removeClass( "sidebar-open" )
          setTimeout(function(){
            $state.go("app.temperature.admin.device");
          },100)
        }
      };


      /*update device*/

      $scope.DEVICEEDIT={}



      $scope.UpdateDevice=function(){
        deviceSrv.updateDeviceById($stateParams.id,$scope.device,function(data){
          Notification('appareil modifié');

          $scope.cancel();

          $rootScope.loadDevices();

        },function(error){
          if (error){
            console.log(error);
            $scope.cancel();


            $rootScope.loadDevices();

          }
        })
      }

      $scope.ClientSelect2Options = {
        allowClear: true,
        id: function (e) {
          return e
        },
        ajax: {
          transport: function (params) {
            params.beforeSend = function (request) {
              request.setRequestHeader("Authorization", 'Bearer ' + $window.sessionStorage.token);
            };
            return $.ajax(params);
          },
          url: $rootScope.config.API_URI + '/company/query?limit=' + 10 + '&page=' + 1,
          dataType: "json",
          type: "POST",
          quietMillis: 500,

          data: function (term, page) {
            return {
              query: {
                name: {"$regex": term, "$options": "i"}
              }
            };
          },
          results: function (data, page) {

            return {results: data.result};
          }
        },
        formatResult: formatResult,
        formatSelection: formatSelection
      };
      function formatResult(item) {
        return '<i class="fa fa-building"></i>   ' + item.name;
      }

      function formatSelection(item) {

        return '<i class="fa fa-building-o"></i>    ' + item.name;
      }





      if( !$( ".sidebar-right" ).hasClass( "sidebar-open" ))
        $( ".sidebar-right" ).addClass( "sidebar-open" )
    }]);

/**
 * Created by Mohamed Saad on 02/03/2017.
 */

/**
 * Project: fleetWeb
 * Created by  NGI team.
 */

angular.module("app").controller('fleetsCtrl', ['$scope', '$state', 'fleetSrv','ngDialog','Notification',"$translate","$rootScope","$window",
  function ($scope,$state,fleetSrv,ngDialog,Notification,$translate,$rootScope,$window) {

    $( ".sidebar-right" ).removeClass( "sidebar-open" )
    $scope._ADMIN_FLEET = {};
    $scope.itemsByPage=5;

    $scope.clearFieldFilters=function(){
      $scope.company=null;
      $scope._ADMIN_FLEET={};
      $scope._ADMIN_FLEET.AssetSearch = "";
      var tableState = $scope.stCtrl.tableState();
      tableState.search.predicateObject = {};
      tableState.pagination.start = 0;
      $scope.stCtrl.pipe();
    };


    $scope.ClientSelect2Options = {
      allowClear: true,
      id: function (e) {
        return e
      },
      ajax: {
        transport: function (params) {
          params.beforeSend = function (request) {
            request.setRequestHeader("Authorization", 'Bearer ' + $window.sessionStorage.token);
          };
          return $.ajax(params);
        },
        url: $rootScope.config.API_URI + '/company/query?limit=' + 10 + '&page=' + 1,
        dataType: "json",
        type: "POST",
        quietMillis: 500,

        data: function (term, page) {
          return {
            query: {
              name: {"$regex": term, "$options": "i"}
            }
          };
        },
        results: function (data, page) {

          return {results: data.result};
        }
      },
      formatResult: formatResult1,
      formatSelection: formatSelection1
    };
    function formatResult1(item) {
      return '<i class="fa fa-building-o"></i>   ' + item.name;
    }

    function formatSelection1(item) {

      return '<i class="fa fa-building"></i>    ' + item.name;
    }

    /*load fleets list*/
    $rootScope.loadFleets = function (tableState,ctrl) {
      if ( !$scope.stCtrl && ctrl )
      {
        $scope.stCtrl = ctrl;
      }
      if( !tableState && $scope.stCtrl ) {
        $scope.stCtrl.pipe();
        return;
      }
      var start = 0;
      var number = $scope.itemsByPage;
      var query = {
        options:{populate:
          [
            {
              path: '_company_owner',
              select: 'name'
            }
          ]

        }
      };
      if (tableState.pagination) {
        var pagination = tableState.pagination;
        number =  pagination.number ||  $scope.itemsByPage;
        start = pagination.start / number + 1 || 1;
      }
      if (tableState.sort && tableState.sort.predicate) {
        query.options.sortBy = {};
        var predicate = tableState.sort.predicate;
        query.options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
      }

      var requery = {};
      if (tableState.search && tableState.search.predicateObject) {
        if (tableState.search.predicateObject.name) {
          requery["name"] ={"$regex": tableState.search.predicateObject.name, "$options": "i"};
        }
        if (tableState.search.predicateObject.type) {
          requery["type"] = {'$regex': tableState.search.predicateObject.type, $options: '-i'}
        }
        if (tableState.search.predicateObject._company_owner && tableState.search.predicateObject._company_owner._id) {
          requery["_company_owner"] =tableState.search.predicateObject._company_owner._id;
        }
        query.query=requery;
      }
      fleetSrv.getFleetsList(query,
        number, start,
        function (data) {
          if (data.success) {
            $scope._ADMIN_FLEET.fleetList = data.result;
            $scope._ADMIN_FLEET.count = data.total_count
            tableState.pagination.numberOfPages =Math.ceil(data.total_count/ number);
            tableState.pagination.totalItemCount = data.total_count;
          } else {
            console.error(data.error)
          }
        }, function (data) {
          console.log(data)
        })
    };

    /*delete Fleet*/
    $scope.deleteFleet=function(fleet){
      ngDialog.openConfirm({ template: 'app/commons/dialogs/confirm.dlg.html',overlay:false ,showClose:false,controller: ['$scope', function($scope) {
        // controller logic
        $scope.title=$translate.instant("FLEET_DELETE_CONFIRM_TITLE",{name:fleet.name});
        $scope.message=$translate.instant("FLEET_DELETE_CONFIRM_MESSAGE",{name:fleet.name}); // "Are you sure to delete this fleet"
      }] }).then(function (value) {
        fleetSrv.deleteFleetById(fleet._id,function(data){
          $state.go("app.temperature.admin.fleets");
          $rootScope.loadFleets();
          Notification($translate.instant("FLEET_DELETED",{name:fleet.name}));
        },function(err){
        })
      }, function (reason) {

      });
    }
    $scope.fleetDetails=function(fleet){
      $state.go("app.temperature.admin.fleets.details",{id:fleet._id});
    };

    $scope.addFleet=function(){
      $state.go("app.temperature.admin.fleets.add");
    };


  }])

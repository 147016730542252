/**
 * Created by Mohamed Saad on 01/03/2017.
 */

angular.module("app").controller('depotsCtrl', ['$scope','$state', 'assetSrv','ngDialog','Notification',"$stateParams","$translate","$rootScope","$window",
  function ($scope,$state,assetSrv,ngDialog,Notification,$stateParams,$translate,$rootScope,$window) {
    $( ".sidebar-right" ).removeClass( "sidebar-open" )
    $scope._ADMIN_ASSET = {};
    $scope._ADMIN_ASSET.limit = 5;
    $scope.itemsByPage=5;

    $scope.clearFieldFilters=function(){
      $scope.company=null;
      $scope._ADMIN_ASSET={};
      $scope._ADMIN_ASSET.AssetSearch = "";
      var tableState = $scope.stCtrl.tableState();
      tableState.search.predicateObject = {};
      tableState.pagination.start = 0;
      $scope.stCtrl.pipe();
    };

    $scope.ClientSelect2Options = {
      allowClear: true,
      id: function (e) {
        return e
      },
      ajax: {
        transport: function (params) {
          params.beforeSend = function (request) {
            request.setRequestHeader("Authorization", 'Bearer ' + $window.sessionStorage.token);
          };
          return $.ajax(params);
        },
        url: $rootScope.config.API_URI + '/company/query?limit=' + 10 + '&page=' + 1,
        dataType: "json",
        type: "POST",
        quietMillis: 500,

        data: function (term, page) {
          return {
            query: {
              name: {"$regex": term, "$options": "i"}
            }
          };
        },
        results: function (data, page) {

          return {results: data.result};
        }
      },
      formatResult: formatResult1,
      formatSelection: formatSelection1
    };
    function formatResult1(item) {
      return '<i class="fa fa-building-o"></i>   ' + item.name;
    }

    function formatSelection1(item) {

      return '<i class="fa fa-building"></i>    ' + item.name;
    }

    /*load asset list*/
    $rootScope.loadAssets = function (tableState,ctrl) {
      if ( !$scope.stCtrl && ctrl )
      {
        $scope.stCtrl = ctrl;
      }

      if( !tableState && $scope.stCtrl ) {
        $scope.stCtrl.pipe();
        return;
      }
      var start = 0;     // This is NOT the page number, but the index of item in the list that you want to use to display the table.
      var number = $scope.itemsByPage;
      var query = {
        options:{populate:
          [
            {
              path: '_company_owner',
              select: 'name'
            },
            {
              path: '_dev',
              select: '_id'
            }
          ]
        }
      };
      if (tableState.pagination) {
        var pagination = tableState.pagination;
        number =  pagination.number ||  $scope.itemsByPage;
        start = pagination.start / number + 1 || 1;
      }
      if (tableState.sort && tableState.sort.predicate) {
        query.options.sortBy = {};
        var predicate = tableState.sort.predicate;
        query.options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
      }
      var requery = {};
      if($scope.asset && $scope.asset.typeFilter && $scope.asset.typeFilter.length>0 ){
        requery["type"] ={'$regex': $scope.asset.typeFilter, $options: '-i'}
      }
      if (tableState.search && tableState.search.predicateObject) {
        if (tableState.search.predicateObject.name) {
          requery["name"] = {"$regex": tableState.search.predicateObject.name, "$options": "i"};
        }
        if (tableState.search.predicateObject._dev) {
          requery["_dev"] = {"$regex": tableState.search.predicateObject._dev, "$options": "i"};
        }
        if (tableState.search.predicateObject.model) {
          requery["model"] = {'$regex': tableState.search.predicateObject.model, $options: '-i'}
        }
        if (tableState.search.predicateObject.family) {
          requery["family"] = {'$regex': tableState.search.predicateObject.family, $options: '-i'}
        }
        if (tableState.search.predicateObject.type) {
          requery["type"] = {'$regex': tableState.search.predicateObject.type, $options: '-i'}
        }
        if (tableState.search.predicateObject._company_owner && tableState.search.predicateObject._company_owner._id) {
          requery["_company_owner"] = tableState.search.predicateObject._company_owner._id;
        }
      }
      query.query=requery;
      assetSrv.getAssetsList(query,
        number, start,
        function (data) {
          if (data.success) {
            $scope._ADMIN_ASSET.assetList = data.result;
            $scope._ADMIN_ASSET.count = data.total_count
            tableState.pagination.numberOfPages =Math.ceil(data.total_count/ number);
            tableState.pagination.totalItemCount = data.total_count;
          } else {
            console.error(data.error)
          }
        }, function (data) {
          console.log(data)

        })
    };

    /*delete asset*/

    $scope.deleteAsset=function(asset){
      ngDialog.openConfirm({ template: 'app/commons/dialogs/confirm.dlg.html',overlay:false ,showClose:false,controller: ['$scope', function($scope) {
        // controller logic
        $scope.title=$translate.instant("ASSET_DELETE_CONFIRM_TITLE",{name:asset.name})
        $scope.message=$translate.instant("ASSET_DELETE_CONFIRM_MESSAGE",{name:asset.name})
      }] }).then(function (value) {
        assetSrv.deleteAssetById(asset._id,function(data){
          $state.go("app.temperature.admin.depots");
          $rootScope.loadAssets();
          Notification($translate.instant("ALERT_ASSET_DELETED",{name:asset.name}));
        },function(err){
        })
      }, function (reason) {

      });
    }

    $scope.assetDetails=function(user){
      $state.go("app.temperature.admin.depots.details",{id:user._id});
    };

    $scope.addAsset=function(){
      $state.go("app.temperature.admin.depots.add");
    };

    $scope.assetSensors=function(asset){
      $rootScope.CurrentAsset={};
      $rootScope.CurrentAsset=asset;
      $state.go("app.temperature.admin.depots.sensors",{id:asset._id});
    };



  }])

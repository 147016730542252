/**
 * Project: iaxeed
 * Created by Mohamed Saad on 05/01/2017.
 */


angular.module("app.services").factory('chartSrv', [function () {

    var service = {};

    service.loadChart = function (assetName, names, series, xaxis, date, chartObject, plotOptions) {

        Highcharts.chart('chart', {
            chart: chartObject,
            title: {
                text: assetName,
                x: -20 //center
            },
            subtitle: {
                text:'',
                x: -20
            },
            xAxis: {
              events: {
                afterSetExtremes: function(evt){
                  console.log('extreme changed ',evt);
                }
              },
                type: 'datetime',
                dateTimeLabelFormats: { // don't display the dummy year
                    month: '%e. %b',
                    year: '%b'
                },
                title: {
                    text: 'Date'
                }
            },
            yAxis: {
                title: {
                    text: names
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.x:%Y-%m-%d %H:%M:%S}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: plotOptions,
            series: series

        });
    };

    return service;
}]);

angular
  .module('app',
    [
      'ui.router',
      'ngAnimate',
      'ngSanitize',
      'ui.bootstrap',
        'ngCookies',

      'angular-jwt',
      'ngDialog',
      'pascalprecht.translate','angularSpinner',
      'app.dialogs',
      'app.services',
      'app.directives',
     "angularMoment",
      "ui.bootstrap.datetimepicker",
      "daterangepicker",
      "smart-table",
      'app.filters',
      "frapontillo.gage",
      'ui.select2',
      "ui.checkbox",
      'ui-notification',
    ])


angular.module("app").factory('ajax-loader',["$rootScope", "$q", "$window","usSpinnerService", function ($rootScope, $q, $window,usSpinnerService) {
      var requests = 0;

      function show() {
        if (!requests) {

          $rootScope.$broadcast("ajax-start");

          usSpinnerService.spin('spinner-1');
        }
        requests++;
      }

      function hide() {
        requests--;
        if (!requests) {

          $rootScope.$broadcast("ajax-stop");
          usSpinnerService.stop('spinner-1');

        }
      }

      return {
        'request': function (config) {


          // if(config.url.indexOf("NOLOAD") == -1)
          show();
          return  $q["when"](config);
        }, 'response': function (response) {
          hide();
          return  $q["when"](response);
        }, 'responseError': function (rejection) {
          hide();
          return  $q["when"](rejection);
        }
      };
    }]
).config(["$httpProvider",function ($httpProvider) {


      $httpProvider.interceptors.push('ajax-loader');
    }])

/**
 * Created by Kais CHAIBI on 01/06/2016.
 */
angular.module("app.services").factory('moduleLoaderSrv', ['$http', '$rootScope', '$window', 'jwtHelper', 'dataSrv', 'socket','$ocLazyLoad','moduleSrv', function ($http, $rootScope, $window, jwtHelper, dataSrv, socket,$ocLazyLoad,moduleSrv) {

    var service={};
    service.loadModule=function(err,next){
        var query={query:{enabled:true},options:{sortBy:{'index':1}}};
        moduleSrv.getModuleList(query,10000,0,function(data){
           if(data.success && data.result){
               next(data.result);
           }else{
               err()
           }

        },function(error){
            err(error);
        })

    }
    return service;
}]);

/**
 * Created by Mohamed Saad on 01/03/2017.
 */

angular.module("app").controller('depotsDetailsCtrl', ['$scope', '$state', 'Notification', '$stateParams', '$rootScope', '$timeout', "assetSrv", '$q', '$translate','ngDialog','$window','deviceSrv','enumsSrv',
  function ($scope, $state, Notification, $stateParams, $rootScope, $timeout, assetSrv, $q, $translate,ngDialog,$window,deviceSrv,enumsSrv) {
    $scope._ADMIN_EDIT_ADD = {};
    $scope.moment=moment;

    $scope.loadDepotDetails=function(){
      $scope.sensors=[];
      var query={query:{_id:$stateParams.id},options: {
        populate: ['_company_owner']
      }};
      assetSrv.getAssetsList(query,1000 , 0 , function(result){
        $scope.asset=result.result[0];
        $scope.sensors=$scope.asset.sensors;
        console.log('asset',$scope.asset)
      }, function(err){
        if(err && err.status==-1){
          Notification.error("Problème de connexion Internet");
        }
        console.error(err)
      })
    };


    $scope.loadDepotDetails();
    if( !$( ".sidebar-right" ).hasClass( "sidebar-open" ))
      $( ".sidebar-right" ).addClass( "sidebar-open" )

    $scope.cancel=function(){

      if($state.current.name=="app.temperature.admin.depots.details"){
        $( ".sidebar-right" ).removeClass( "sidebar-open" )

        setTimeout(function(){
          $state.go("app.temperature.admin.depots",{id:$stateParams.id});
        },100)


      }else  {
        $( ".sidebar-right" ).removeClass( "sidebar-open" )
        setTimeout(function(){
          $state.go("app.temperature.admin.depots",{id:$stateParams.id});
        },100)
      }
    };

    $scope.ClientSelect2Options = {
      allowClear: true,
      id: function (e) {
        return e
      },
      ajax: {
        transport: function (params) {
          params.beforeSend = function (request) {
            request.setRequestHeader("Authorization", 'Bearer ' + $window.sessionStorage.token);
          };
          return $.ajax(params);
        },
        url: $rootScope.config.API_URI + '/company/query?limit=' + 10 + '&page=' + 1,
        dataType: "json",
        type: "POST",
        quietMillis: 500,

        data: function (term, page) {
          return {
            query: {
              name: {"$regex": term, "$options": "i"}
            }
          };
        },
        results: function (data, page) {

          return {results: data.result};
        }
      },
      formatResult:   function formatResult1(item) {
        return '<i class="fa fa-building-o"></i>   ' + item.name;
      }
      ,
      formatSelection:  function formatSelection1(item) {

        return '<i class="fa fa-building"></i>    ' + item.name;
      }
    };




    $scope.DeviceSelect2Options = {
      allowClear: true,
      id: function (e) {
        return e
      },
      ajax: {
        transport: function (params) {
          params.beforeSend = function (request) {
            request.setRequestHeader("Authorization", 'Bearer ' + $window.sessionStorage.token);
          };
          return $.ajax(params);
        },
        url: $rootScope.config.API_URI + '/device/query?limit=' + 10 + '&page=' + 1,
        dataType: "json",
        type: "POST",
        quietMillis: 500,

        data: function (term, page) {
          return {
            query: {
              $or: [
                {_id: {"$regex": term, "$options": "i"}},
                {serial: {"$regex": term, "$options": "i"}},
                {imei: {"$regex": term, "$options": "i"}}

              ]
            }
          };
        },
        results: function (data, page) {

          return {results: data.result};
        }
      },
      initSelection: function (element, callback) {
        var id = $(element).select2('val');
        if (id && id.length > 0) {
          return deviceSrv.getDeviceById(id, function (res) {

            if (res && res.success) {
              callback(res.result);
            } else {
              callback(null);
            }
          }, function (err) {
            callback(null);
          })
        }
      },

      formatResult:  formatResult2,
      formatSelection:formatSelection2

    };

    formatResult:  function formatResult2(item) {
      return '<i class="fa fa-plug"></i>   ' + item._id + " [" + item.model + "]"
    }
    formatSelection:  function formatSelection2(item) {

      return '<i class="fa fa-plug"></i>    ' + item._id + " [" + item.model + "]";
    }


    enumsSrv.getEnums(function (data) {
      $scope.ENUMS = data
    }, function(err){
      console.error(err);
    });

    /*Add asset*/
    $scope.addAsset = function () {
      assetSrv.add($scope.asset, function (data) {
        if (data && data.success) {
          $scope.cancel();
          $rootScope.loadAssets();
          Notification($translate.instant("ALERT_ASSET_ADDED",{name:$scope.asset.name}));
        }
      }, function (err) {
      })
    };

    /*update asset*/
    $scope.updateAsset = function () {
      assetSrv.updateAssetById($stateParams.id, $scope.asset, function (data) {
        if (data && data.success) {
          $scope.cancel();

          $rootScope.loadAssets();
          Notification($translate.instant("ALERT_ASSET_UPDATED",{name:$scope.asset.name}));
        }
      }, function (error) {
        if (error) {
          console.log(error);
        }
      })
    }




  }]);

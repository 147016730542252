/**
 * Created by Kais CHAIBI on 28/12/2016.
 */
angular.module("app").directive('numericSelector', [ '$rootScope', '$state','$q', function ( $rootScope, $state,$q) {
    return {
        restrict:"EA",

            scope: {
            map: '@',
                config:'=',
                isInterval:"=",
                conditionIndex:"@"
        },
        templateUrl: 'app/commons/directives/alerts/cfg/tpl/numerciSelector.tpl.html',
        link:function (scope,element,attrs,ctrl) {




            scope.$watch('isInterval', function (val) {


                setTimeout(function(){



                if (val) {
                    if (scope.config.value && !scope.config.value[0]) {
                        scope.config.data.value = [];
                    } else if(!scope.config.value && scope.config.data.value.length==undefined) {
                         scope.config.data.value = [];
                    }
                } else {
                   if(!scope.config.data.value ||  scope.config.data.value.length) {
                       scope.config.data.value = 0;
                   }
                }
                    scope.$apply();
               // console.log('link change numeric',val ,scope.config.value);
            })

            })
        }
    }
}])
